import { Component, OnInit } from '@angular/core';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { ActivatedRoute } from '@angular/router';
import { Document } from 'src/app/models/document/document';
import { DocumentService } from 'src/app/services/document/document.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { trigger } from '@angular/animations';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})

export class DocumentComponent implements OnInit {
  loading: boolean;
  table = 'document';
  options: FormOptions = {
    title: {
      default: 'Nouveau document',
      prefix: 'Document',
      fields: 'name'
    },
    page: 'document',
    redirectTo: 'documents'
  };
  
  error: string;

  actions: FormAction[];
  sections: FormSection[] = [];
  record: Document;
  relatedLists: RelatedList[];

  constructor(private db: DocumentService,
    private router: ActivatedRoute,
    private documentService: DocumentService,
    private transactionService: TransactionService,
    private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.loading = true;

    this.workspaceService.current.subscribe(worksapce => {
      if(!worksapce){
        return;
      }

      this._loadSections(worksapce.id).then(() => {
        this.router.paramMap.subscribe(params => {
          const id = params.get('id');
          this.record = new Document();
  
          if (id !== '-1') {
            this.db.getDocument(id)
              .then(record => this.record = record)
              .then(() => {
                this.actions = [
                  {
                    name: 'pdf_editor',
                    label: 'Editer en PDF',
                    type: 'link',
                    primary: true,
                    link: `/document-pdf/${id}`,
                    isVisible: () => {
                      return this.record.category == 'rc' || this.record.category == 'r';
                    }
                  }
                ]
              })
              .then(() => this.loadRelatedLists(worksapce.id, this.record))
              .catch(error => this.error = error)
              .finally(() => this.loading = false)
          } else {
            this.loading = false;
          }
        })
      })
    })
  }

  private loadRelatedLists(workspaceID: string, document: Document) {
    const lists: RelatedList[] = [
      {
        title: 'Transactions',
        name: 'transactions',
        columns: [
          {
            label: 'Libellé',
            name: 'short_description',
            type: 'string',
          },
          {
            label: 'Montant',
            name: 'amount',
            type: 'amount',
          },
          {
            label: 'Date de l\'opération',
            name: 'operation_date',
            type: 'date',
          }
        ],
        records: [],
        page: '/transaction'
      },
      {
        title: 'Factures liés',
        name: 'invoices',
        columns: [
          {
            label: 'Nom du document',
            name: 'name',
            type: 'string',
          },
          {
            label: 'Catégorie',
            name: 'category',
            type: 'choice',
          }
        ],
        records: [],
        page: '/document',
      }
    ]

    if(document.category === 'q'){
      this.documentService.getInvoices(workspaceID).then(
        quotations => {
          const list = lists.find(list => list.name === 'invoices');
          if(list){
            list.records = quotations;
          }
        }
      )
    }

    if(document.category === 'b'){
      this.transactionService.getTransactionByDocument(workspaceID, this.record.id).then(
        transactions => {
          const list = lists.find(list => list.name === 'transactions');
          
          if(list){
            list.records = transactions;
          }
        }
      )

      this.documentService.getQuotations(document.invoice_number).then(
        invoices => {
          const list = lists.find(list => list.name === 'invoices');
          if(list){
            list.records = invoices;
          }
        }
      )
    }

    this.relatedLists = lists;
  }

  private _loadSections(workspaceID): Promise<FormSection[]> {
    return new Promise((resolve, reject) => {
      this.sections = [{
        columns: [
          {
            elements: [
              {
                field: 'name',
                label: 'Nom du document',
                type: 'string',
                isRequired: true,
              },
              {
                field: 'category',
                label: 'Categorie',
                type: 'choice',
                isRequired: true,
                choices: new ChoiceList().getChoices('document', 'category'),
                grouped: true
              },
              {
                field: 'company',
                label: 'Entreprise',
                type: 'reference',
                url: `workspace/${workspaceID}/company/`,
                reference: 'company',
                displayValue: 'name',
                page: 'entreprise',
                isVisible: () => {
                  return this.record.category === 'q' || this.record.category === 'b';
                }
              },
              {
                field: 'status',
                label: 'Status',
                type: 'choice',
                choices: new ChoiceList().getChoices('document', 'status'),
                isVisible: () => {
                  return this.record.category === 'q' || this.record.category === 'b';
                }
              },
              {
                field: 'memo',
                label: 'Mémo',
                type: 'string',
                isRequired: false,
              },
              {
                field: 'quotation_number',
                label: 'Nº devis',
                type: 'string',
                isRequired: (record: Document) => {
                  return record.category?.startsWith('q');
                },
                isVisible: (record: Document) => {
                  return record.category?.startsWith('q');
                }
              },
              {
                field: 'invoice_number',
                label: 'Nº facture',
                type: 'string',
                isRequired: (record: Document) => {
                  return record.category?.startsWith('b');
                },
                isVisible: (record: Document) => {
                  return record.category?.startsWith('b');
                }
              },
              {
                field: 'invoice_due_date',
                label: 'Date d\'échéance',
                type: 'date',
                isVisible: (record: Document) => {
                  return record.category === 'b';
                }
              },
            ]
          },
          {
            elements: [
              {
                field: 'realty_group',
                label: 'Immeuble',
                type: 'reference',
                url: `workspace/${workspaceID}/realtygroup/`,
                reference: 'realtygroup',
                displayValue: 'short_description',
                isVisible: (record: Document) => {
                  return record.category === 'q' || record.category === 'b' || record.category === 'd';
                }
              },
              {
                field: 'realty',
                label: 'Bien immobilier',
                type: 'reference',
                url: `workspace/${workspaceID}/realty/`,
                reference: 'realty',
                displayValue: 'short_description',
              },
              {
                field: 'tenant',
                label: 'Locataire',
                type: 'reference',
                url: `workspace/${workspaceID}/tenant/`,
                reference: 'tenant',
                displayValue: 'first_name,last_name',
                isVisible: (record: Document) => {
                  return ['rc', 'i', 'de', 'ir', 'r'].indexOf(record.category) > -1;
                }
              },
              {
                field: 'amount_excl_tax',
                label: 'Montant HT',
                type: 'amount',
                isVisible: (document: Document) => {
                  return document.category === 'b';
                }
              },
              {
                field: 'tax_rate',
                label: 'TVA (%)',
                type: 'percentage',
                isVisible: (document: Document) => {
                  return document.category === 'b';
                }
              },
              {
                field: 'amount_incl_tax',
                label: 'Montant TTC',
                type: 'amount',
                isVisible: (document: Document) => {
                  return document.category === 'b' || document.category === 'q';
                }
              },
              {
                field: 'deposit_percentage',
                label: 'Acompte (%)',
                type: 'percentage',
                onChange: (document: Document) => {
                  document.deposit_amount = document.amount_incl_tax * document.deposit_rate / 100;
                },
                isVisible: (document: Document) => {
                  return document.category === 'q';
                }
              },
              {
                field: 'deposit_amount',
                label: 'Acompte',
                type: 'amount',
                isRequired: (document: Document) => {
                  return document.category === 'q';
                },
                isVisible: (document: Document) => {
                  return document.category === 'q';
                }
              }
            ]
          }
        ],
      }
    ];

      resolve(this.sections);
    })
  }
}
