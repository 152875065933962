import { Component, OnInit } from '@angular/core';
import { Workspace } from 'src/app/@auth/models/workspace/workspace';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { Document } from 'src/app/models/document/document';
import { DocumentService } from 'src/app/services/document/document.service';

@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss']
})
export class QuotationsComponent implements OnInit {
  title = 'Factures';
  loading: boolean;
  keywords: string;
  columns: TableColumn[];

  records: Document[] = [];

  actions: FormAction[] = [
    {
      name: 'new', label: 'Nouveau devis', link: '/document/-1', type: 'link', primary: true,
      queryParams: {
        sysparm_default: 'category=q'
      }
    }
  ];

  constructor(private db: DocumentService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.columns = [
        {
          label: 'Nom du document',
          name: 'name',
          type: 'string',
        },
        {
          label: 'Nº devis',
          name: 'quotation_number',
          type: 'string'
        },
        {
          label: 'Memo',
          name: 'memo',
          type: 'string'
        },
        {
          label: 'Montant TTC',
          name: 'amount_incl_tax',
          type: 'amount'
        },
        {
          label: 'Bien immobilier',
          name: 'realty',
          type: 'reference',
          reference: 'realty',
          url: `/workspace/${workspace.id}/realty/`,
          displayValue: 'short_description'
        },
      ]

      this.loading = true;
      this.db.getQuotations(workspace.id)
        .then(quotations => this.records = quotations)
        .catch(error => console.warn(error))
        .finally(() => this.loading = false);
    })
  }
}
