import { Component, OnInit } from '@angular/core';
import { Room } from 'src/app/models/room/room';
import { ActivatedRoute } from '@angular/router';
import { RoomService } from 'src/app/services/room/room.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})

export class RoomComponent implements OnInit {
  loading: boolean;
  table = 'property';
  options: FormOptions = {
    title: {
      default: 'Nouvelle chambre',
      prefix: 'Chambre',
      fields: 'short_description'
    },
    page: '/appartement/chambre',
    redirectTo: 'appartements'
  }

  sections: FormSection[];
  relatedLists: RelatedList[] = [];
  record: Room;
  actions: FormAction[] = [];

  constructor(private db: RoomService,
    private workspaceService: WorkspaceService,
    private realtyService: PropertyService,
    private router: ActivatedRoute,
    private notification: UINotificationService,
    private tenantService: TenantService) {

  }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.record = null;
        return;
      }

      this.loading = true;

      this.loadSections(workspace.id).then(() => this.router.paramMap.subscribe(params => {
        const id = params.get('id')
        this.record = new Room();

        if (id !== '-1') {
          this.db.getRoom(id).then((data: Room) => {
            this.record = data;
          }).then(() => {
            this.options.previousPage = `/appartement/${this.record.realty}`;
            let newTenantButton: FormAction = {
              name: 'new_application',
              label: 'Nouveau locataire',
              link: '/locataire/-1',
              type: 'link',
              primary: true
            };

            let defaultValues = `room=${this.record.id}`;

            if (this.record.realty) {
              return this.realtyService.getProperty(this.record.realty).then(realty => {
                defaultValues += `^rental_type=${realty.rental_type}^realty=${realty.id}^`;

                if (realty.rental_type === 's') {
                  defaultValues += `^monthly_rent=${this.record.monthly_rent}^` +
                    `monthly_charges=${this.record.monthly_charges}^` +
                    `charges_details=${realty.charges_details}`;
                }

                newTenantButton.queryParams = {
                  sysparm_default: defaultValues
                }

                this.actions.push(newTenantButton);
              })
            }

            this.actions.push(newTenantButton);

            return;
          }).then(() => this.loadFormButtons(workspace.id, this.record.realty)
          ).catch(exception => {
            this.notification.snack(exception, 'danger')
          }).finally(() => this.loading = false);
        } else {
          this.loading = false;
        }
      }));
    })
  }

  loadSections(workspaceID: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sections = [
        {
          columns: [
            {
              elements: [
                {
                  field: 'short_description',
                  label: 'Libellé',
                  type: 'string',
                  isRequired: true,
                },
                {
                  field: 'realty',
                  label: 'Appartement',
                  type: 'reference',
                  page: 'bien-immobilier',
                  url: `workspace/${workspaceID}/realty/`,
                  reference: 'realty',
                  displayValue: 'short_description',
                  refQualifier: [
                    { field: 'property_type', operator: 'IN', value: 'a,h' }
                  ],
                  isRequired: true,
                },
                {
                  field: 'living_area',
                  label: 'Surface',
                  type: 'string',
                  isRequired: true,
                  suffix: 'm2'
                }
              ],
            },
            {
              elements: [
                {
                  field: 'monthly_rent',
                  label: 'Loyer mensuel HC',
                  type: 'amount',
                  isRequired: true,
                  hint: 'Loyer mensuel par défaut lors de la création d\'un locataire',
                  onChange: (room) => {
                    if (room.monthly_rent && room.monthly_rent) {
                      room.monthly_rent_with_charges = parseFloat(room.monthly_rent) + parseFloat(room.monthly_charges);
                    }
                  }
                },
                {
                  field: 'monthly_charges',
                  label: 'Charges mensuelles',
                  type: 'amount',
                  isRequired: true,
                  hint: 'Charges mensuelles par défaut lors de la création d\'un locataire',
                  onChange: (room) => {
                    if (room.rent && room.charge) {
                      room.rent_with_charge = parseFloat(room.rent) + parseFloat(room.charge);
                    }
                  }
                },
                {
                  field: 'charges_details',
                  label: 'Détails des charges',
                  type: 'textarea',
                  hint: 'Détails des charges par défaut lors de la création d\'un locataire',
                  isRequired: false,
                }
              ]
            }
          ]
        },
        {
          columns: [
            {
              elements: [
                { field: 'description', label: 'Descrption', type: 'textarea' }
              ]
            }
          ]
        }
      ];

      resolve();
    })
  }

  loadFormButtons(workspaceID: string, realtyID: string): Promise<void> {
    return Promise.all([
      this.tenantService.getTenantsByRoom(workspaceID, this.record.id),
      this.realtyService.getProperty(realtyID)
    ]).then(result => {
      const tenants = result[0];
      const realty = result[1];

      let defaultValues = `rental_type=${realty.rental_type}^realty=${realty.id}^room=${this.record.id}`;

      if (realty.rental_type === 's') {
        defaultValues += `^monthly_rent=${this.record.monthly_rent}^` +
          `monthly_charges=${this.record.monthly_charges}^` +
          `charges_details=${realty.charges_details}`;
      }

      let newTenantButton: FormAction = {
        name: 'new_application',
        label: 'Nouveau locataire',
        link: '/locataire/-1',
        type: 'link',
        primary: true,
        queryParams: {
          sysparm_default: defaultValues
        }
      };

      this.relatedLists.push({
        title: 'Locataires',
        name: 'tenants',
        page: '/locataire',
        redirectTo: this.router.snapshot.url.join(''),
        actions: [newTenantButton],
        columns: [
          {
            label: 'Prénom',
            name: 'first_name',
            type: 'string'
          },
          {
            label: 'Nom',
            name: 'last_name',
            type: 'string'
          },
          {
            label: 'Email',
            name: 'email',
            type: 'email'
          },
          {
            label: 'Téléphone',
            name: 'phone',
            type: 'phone'
          },
          {
            label: 'Date d\'arrivée',
            name: 'contract_start_date',
            type: 'date'
          },
          {
            label: 'Date de départ',
            name: 'contract_end_date',
            type: 'date'
          }
        ],
        records: tenants
      })
    })
  }
}
