import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { WorkspaceInvitationService } from '../../services/workspace-invitation/workspace-invitation.service';
import { WorkspaceService } from '../../services/workspace/workspace.service';

@Component({
  selector: 'app-workspace-invitation-request',
  templateUrl: './workspace-invitation-request.component.html',
  styleUrls: ['./workspace-invitation-request.component.scss']
})
export class WorkspaceInvitationRequestComponent implements OnInit {
  @Input() workspaceID: string;
  recipient: string;
  message: string;
  saving: boolean;

  constructor(private dialog: NbDialogRef<WorkspaceInvitationRequestComponent>,
    private workspace: WorkspaceInvitationService,
    private notification: UINotificationService,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.message = "Envoi en cours";
    this.saving = true;

    this.workspace.sendInvitation(this.recipient, this.auth.user.getValue().email, this.workspaceID).then(result => {
      this.notification.snack(`L\'invitation a bien été envoyé à ${this.recipient}`, 'info');
      this.dialog.close();
    }).catch (exception => {
      this.notification.snack(exception, 'danger');
      this.message = null;
      this.saving = false;
    })
  }
}
