import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateUtil } from 'src/app/@core/util/date-util';
import { DocumentService } from 'src/app/services/document/document.service';
import { environment } from 'src/environments/environment';
import { Notification } from '../../components/notification';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private documentService: DocumentService) {

  }

  getNotifications(workspaceID): Promise<Notification[]> {
    return this.getPendingInvoices(workspaceID).then(invoices =>
      invoices.map(invoice => {
        let body = `Vous avez une facture à payer`;

        if (invoice.invoice_due_date) {
          body += ` avant le ${new DateUtil().toFormat(invoice.invoice_due_date, 'DD MMMM YYYY')}`;
        }

        body += ` d'un montant de ${invoice.amount_incl_tax.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })}.`;

        return {
          title: `Facture ${invoice.name} à payer`,
          body: body,
          link: `document/${invoice.id}`,
          icon: 'receipt'
        }
      })
    )
  }

  getPendingInvoices(workspaceID: string) {
    return this.documentService.getInvoices(workspaceID).then(invoices => {
      return invoices.filter(invoice => invoice.status == 'tp');
    })
  }
}
