import { Record } from '../@core/models/record/record';
import { User } from '../@auth/models/user/user';
import { ICompany } from '../interfaces/company';

export class Company extends Record implements ICompany {
    name: string;
    number: string;
    short_description: string;
    address: string;
    manager: string;
    phone: string;
    email: string;
    website: string;
    type: 'c' | 't';

    constructor() {
        super('company');
    }
}
