import { Record } from '../@core/models/record/record';

export class Guarantor extends Record{
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    place_of_birth: string;
    date_of_birth: string;
    address: string;
    tenant: string;

    constructor(){
        super('guarantor');
    }
}
