import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { Email } from 'src/app/@core/models/email';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { DateUtil } from 'src/app/@core/util/date-util';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarEventService } from 'src/app/services/calendar-event/calendar-event.service';

@Component({
  selector: 'app-generate-calendar-event',
  templateUrl: './generate-calendar-event.component.html',
  styleUrls: ['./generate-calendar-event.component.scss']
})

export class GenerateCalendarEventComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() startDate: Date;
  @Input() link: string;

  message: string;
  event: CalendarEvent;

  constructor(private calendar: CalendarEventService,
     private auth: AuthenticationService,
      private dateUtil: DateUtil,
      private notification: UINotificationService,
      private dialog: NbDialogRef<GenerateCalendarEventComponent>) { }

  ngOnInit(): void {
    this.event = new CalendarEvent();
    this.event.title = this.title;
    this.event.description = this.description;
    this.event.startDate = this.startDate;
    this.event.endDate = this.startDate;
    this.event.link = this.link;
  }

  onDownload() {
    this.message = 'Veuillez patienter...';
    this.event.startDate = this.dateUtil.toFormat(this.event.startDate, 'YYYY-MM-DD hh:mm:ss');
    this.event.endDate = this.dateUtil.toFormat(this.event.endDate, 'YYYY-MM-DD hh:mm:ss');

    this.calendar.downloadEvent(this.event).then(
      () => this.message = null
    ).catch(
      error => {
        this.message = 'Une erreur est survenue. Veuillez réessayer.';
      }
    );
  }

  onSend(){
    this.message = 'Envoi en cours...';
    const email = new Email();
    email.recipient = this.auth.email;
    email.title = 'Rappel de validation de loyer';
    email.body = 'Bonjour, vous trouverez en pièce jointe un rappel à ajouter à votre calendrier.';

    this.event.startDate = this.dateUtil.toFormat(this.event.startDate, 'YYYY-MM-DD hh:mm:ss');
    this.event.endDate = this.dateUtil.toFormat(this.event.endDate, 'YYYY-MM-DD hh:mm:ss');

    this.calendar.sendEvent(email, this.event).then(
      () => {
        this.notification.snack(`Email envoyé à ${email.recipient}`)
        this.dialog.close();
      }
    ).catch(
      error => {
        this.message = 'Erreur lors de l\'envoi. Veuillez réessayer.';
      }
    );
  }
}
