import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { BankAccount } from 'src/app/models/bank-account';
import { BankAccountService } from 'src/app/services/bank-account/bank-account.service';
import { PlaidService } from '../../services/plaid/plaid.service';

@Component({
  selector: 'app-sync-transactions',
  templateUrl: './sync-transactions.component.html',
  styleUrls: ['./sync-transactions.component.scss']
})
export class SyncTransactionsComponent implements OnInit {
  accounts: BankAccount[]; 
  account: BankAccount;
  workspaceID: string;

  constructor(private accountService: BankAccountService, private plaid: PlaidService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if(!workspace){
        this.accounts = [];
        return;
      }

      this.workspaceID = workspace.id;
      this.accountService.getAccounts(workspace.id).then(accounts => this.accounts = accounts);
    })
  }

  onSubmit(){
    this.plaid.getAccountTransactions(this.workspaceID, '12').then(result => console.log(result));
  }
}
