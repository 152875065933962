import { NbMenuItem } from "@nebular/theme";

export class LeftMenu {
    loading: boolean;
    items: NbMenuItem[];
    compact?: string;
    search: string;

    constructor(){
        this.compact = localStorage.getItem('liimmo.sidebar.compact');
        this.items = [];
    }
}
