import { Component, Input, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NbDialogRef } from '@nebular/theme';
import { EmailService } from 'src/app/@core/services/email/email.service';
import { DateUtil } from 'src/app/@core/util/date-util';
import { APP_DATE_FORMATS } from 'src/app/@core/util/datepicker-format';
import { User } from 'src/app/@auth/models/user/user';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { Rent } from 'src/app/models/rent';
import { Tenant } from 'src/app/models/tenant/tenant';
import { Transaction } from 'src/app/models/transaction/transaction';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { RentService } from 'src/app/services/rent/rent.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { PdfGeneratorService } from 'src/app/@core/services/pdf-generator/pdf-generator.service';

@Component({
  selector: 'app-review-tenant-balance',
  templateUrl: './review-tenant-balance.component.html',
  styleUrls: ['./review-tenant-balance.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class ReviewTenantBalanceComponent implements OnInit {
  @Input() id: string;

  edit: boolean;
  sendReceipt: boolean;
  loading: boolean;
  saving: boolean;
  message: string;
  tenant: Tenant;
  rent: Rent;
  period: string;
  user: User;
  transactions: Transaction[] = [];

  constructor(
    private tenantService: TenantService,
    private rentService: RentService,
    private transactionService: TransactionService,
    private workspaceService: WorkspaceService,
    protected modal: NbDialogRef<ReviewTenantBalanceComponent>,
    private email: EmailService,
    private notification: UINotificationService,
    private document: DocumentService,
    private pdfGenerator: PdfGeneratorService,
    private documentUtil: DocumentGeneratorService,
    private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.loading = true;

      if (!this.id) {
        this.message = 'Aucun loyer correspondant';
        return;
      }

      this.user = this.auth.user.getValue();

      this.rentService.getRent(this.id).then(
        (record) => {
          this.rent = record;
          this.period = new DateUtil().toFormat(this.rent.due_date, 'MMMM YYYY');
        }
      ).then(
        async () => {
          this.tenant = await this.tenantService.getTenant(this.rent.tenant).catch(error => {
            throw error;
          })

          this.sendReceipt = this.tenant.receipt_model != null;

          this.transactionService.getTransactionByRent(workspace.id, this.rent.id as string).then(
            (transactions) => {
              this.transactions = transactions
              if (this.transactions.length == 0) {
                this.initializeTransaction();
              }
            }
          ).catch(error => {
            throw error
          });
        }
      ).catch(error => this.notification.snack(error, 'danger')
      ).finally(() => this.loading = false);
    })
  }

  initializeTransaction() {
    let transaction = new Transaction();
    transaction.short_description = this.rent.short_description;
    transaction.category = 'r';
    transaction.amount = this.rent.amount_due;
    transaction.operation_date = this.rent.due_date;
    transaction.realty = this.rent.realty as string;
    transaction.tenant = this.rent.tenant;
    transaction.rent = this.rent.id;

    this.transactions.push(transaction);
  }

  addTransaction() {
    const transaction = new Transaction();
    transaction.id = this.transactions.length + 1 + '';
    transaction.short_description = '';
    transaction.reference = '';
    transaction.operation_date = new DateUtil().toFormat(this.period, 'DD MMM YYYY');
    transaction.amount = Math.round(-1 * this.getBalance() * 100) / 100;
    transaction.rent = this.rent.id;
    transaction.category = 'r';
    transaction.realty = this.rent.realty as string;
    transaction.tenant = this.rent.tenant;

    this.transactions.push(transaction);
  }

  deleteTransaction(id: string) {
    this.transactions = this.transactions.filter(transaction => transaction.id !== id);
  }

  getBalance(): number {
    return this.transactions.reduce((total, transaction) => {
      return total + Math.round(transaction.amount * 100) / 100;
    }, 0) - this.rent.amount_due;
  }

  onPreview() {
    const title = `Quittance de loyer - ${this.period?.toLocaleUpperCase()} - ${this.tenant.first_name} ${this.tenant.last_name}`;

    this.documentUtil.createTenantReceipt(this.rent.id, this.tenant.receipt_model).then(
      receipt => this.pdfGenerator.generate(title, receipt)
    ).catch(error => {
      console.warn('Create attachment from html', error);
      error => this.notification.snack(error, 'danger')
    })
  }

  onCancel(): void {
    this.modal.close();
  }

  onSubmit(): void {
    this.saving = true;
    this.message = 'Veuiller patienter...';
    this.transactions.map(transaction => {
      if (transaction.created_on) {
        transaction.id = null;
      }

      return transaction;
    })

    if (!this.sendReceipt) {
      this.transactionService.createTransactions(this.transactions).then(
        (transactions) => {
          this.message = 'Les transaction ont bien été enregistrées.';
          this.transactions = transactions;
          this.modal.close();
        }
      ).catch(error => this.message = error
      ).finally(() => this.saving = false);
    } else {
      this.transactionService.createTransactions(this.transactions).then(
        (transactions) => {
          this.message = 'La transaction a bien été enregistrée.';
          this.transactions = transactions
        }
      ).then(() => this.document.create(this.workspaceService.id, `Quittance de loyer - ${this.period.toLocaleUpperCase()} - ${this.tenant.first_name} ${this.tenant.last_name}`,
        this.tenant.id, this.rent.realty as string, 'r')
      ).then((document) => {
        this.message = 'Création de la quittance en cours...';
        return this.documentUtil.createTenantReceipt(this.rent.id, this.tenant.receipt_model).then(receipt => {
          return this.document.createAttachmentFromHtml(this.workspaceService.id, `Quittance`, receipt, 'document', document.id);
        }).catch(error => {
          console.warn('Create attachment from html', error);
          throw error;
        });
      }).then(attachment => {
        this.message = 'Envoi de la quittance par email en cours...';
        return this.email.send([this.tenant.email],
          `Quittance de loyer - ${this.period.toLocaleUpperCase()} - ${this.tenant.first_name} ${this.tenant.last_name}`,
          `Bonjour,<br/><br/>Vous trouverez en pièce jointe la quittance de loyer pour ${this.period}.<br/>
          Cordialement,<br/>${this.user.first_name} ${this.user.last_name}`,
          [attachment.id]);
      }).then(
        () => this.message = `La quittance a été envoyé à ${this.rent.tenant} pour la période de ${this.period}.`
      ).then(() => this.modal.close()
      ).catch(
        (error) => {
          console.warn(error);
          this.message = error['detail'] || 'Une erreur est survenue. Merci de réessayer.';
        }
      )
    }
  }
}
