import { SelectionModel } from '@angular/cdk/collections';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService, NbStepperComponent } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { PlaidService } from 'src/app/@finance/services/plaid/plaid.service';
import { EditImportedTransactionComponent } from 'src/app/components/edit-imported-transaction/edit-imported-transaction.component';
import { Transaction } from 'src/app/models/transaction/transaction';
import { PropertyService } from 'src/app/services/property/property.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-import-transaction-wizard',
  templateUrl: './import-transaction-wizard.component.html',
  styleUrls: ['./import-transaction-wizard.component.scss']
})

export class ImportTransactionWizardComponent implements OnInit {
  method: 'excel' | 'auto' = 'excel';
  file: File;
  loading: boolean;
  uploading: boolean;
  transactions: any[];
  step = 0;
  period = '1';
  holdShift: boolean;
  coalesce: string = 'reference';
  displayedColumns: string[] = ['select', 'reference', 'short_description', 'amount', 'operation_date'];
  dataSource: MatTableDataSource<any>;
  workspaceID: string;
  selection = new SelectionModel<Transaction>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('stepper') stepper: NbStepperComponent;

  @HostListener('window:keyup', ['$event'])
  handleKeyupEvent(event: KeyboardEvent) {
    if (event.key === 'Shift') {
      this.holdShift = false;
      document.dispatchEvent(new Event('mouseup'));
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Shift') {
      this.holdShift = true;
      document.dispatchEvent(new Event('mouseup'));
    }
  }

  constructor(private transactionService: TransactionService,
    private notif: UINotificationService,
    private dialog: NbDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private workspace: WorkspaceService,
    private plaid: PlaidService) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.route.queryParams.subscribe(parameters => {
        if (parameters['method']) {
          this.method = parameters['method'];
        }
      })
      this.workspaceID = workspace.id;
      this.stepper.reset();
    })
  }

  synchronize() {
    const transactions = this.plaid.getAccountTransactions(this.workspaceID, this.period);

    this.importTransactions(transactions);
  }

  importTransactions(source: Promise<Transaction[]>) {
    this.uploading = true;

    source.then(async transactions => {
      if (!this.coalesce) {
        this.transactions = transactions
        return;
      }

      const existingTransactions = await this.transactionService.getTransactions(this.workspaceID);

      this.transactions = transactions.filter(
        transaction => this.mapTransactions(transaction, this.coalesce, existingTransactions)
      )

      if (this.transactions.length == 0) {
        this.notif.snack('Toutes les opérations ont déjà été importées. Veuillez choisir un autre fichier.', 'info');
        return;
      }
    }).then(() => {
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(this.transactions);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.stepper.next();
    }).catch(error => this.notif.snack(error, 'danger')
    ).finally(() => this.uploading = false)
  }

  importFile(event) {
    this.file = event.target.files[0];
    const transactions = this.transactionService.importFromExcel(this.file);
    this.importTransactions(transactions);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  mapTransactions(transaction: Transaction, coalesce: string, existingTransactions: Transaction[]): boolean {
    if (!coalesce) {
      return true;
    }

    if (coalesce == 'reference') {
      const existingTransaction = existingTransactions.find(
        element => element.reference == transaction.reference);

      if (existingTransaction) {
        return false;
      }
    }

    if (coalesce == 'short_description') {
      const existingTransaction = existingTransactions.find(
        element => element.short_description == transaction.short_description);

      if (existingTransaction) {
        return false;
      }
    }

    if (coalesce == 'amount_operation_date') {
      const existingTransaction = existingTransactions.find(
        element => element.amount == transaction.amount && element.operation_date == transaction.operation_date);

      if (existingTransaction) {
        return false;
      }
    }

    return true;
  }

  createTransactions() {
    this.stepper.next();
    this.loading = true;

    this.transactionService.createTransactions(this.selection.selected).then(() => {
      this.loading = false;
      this.notif.snack('Les transactions ont bien été créés. Vous allez être redirigé vers la page des opérations bancaires.');

      setTimeout(() => this.router.navigate(['/banque']), 1000)
    }).catch(error => {
      this.notif.snack(error, 'danger');
      this.stepper.previous();
    }).finally(() => this.loading = false)
  }

  edit(transaction) {
    this.dialog.open(EditImportedTransactionComponent, {
      context: {
        source: transaction
      }
    }).onClose.subscribe(transaction => {
      let result = this.transactions.find(element => element.id == transaction.id);

      if (result) {
        result = transaction;
        this.paginator._changePageSize(this.paginator.pageSize);
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Transaction): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  ignore(transaction: Transaction) {
    transaction.ignored = !transaction.ignored;

    if (transaction.ignored) {
      transaction.memo = 'La transaction ne sera pas importée';
    } else {
      transaction.memo = '';
    }
  }

  copy(transaction: Transaction) {
    let copiedTransaction = Object.assign({}, transaction);
    copiedTransaction.memo = `Copie de l'opération ${transaction.short_description} du ${transaction.operation_date} d'un montant de ${transaction.amount}€.`;
    copiedTransaction.parent = transaction;

    const index = this.transactions.indexOf(transaction);
    this.transactions.splice(index, 0, copiedTransaction);
  }
}