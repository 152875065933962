import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { PlaidService } from 'src/app/@finance/services/plaid/plaid.service';
import { Transaction } from 'src/app/models/transaction/transaction';
import { BankAccountService } from 'src/app/services/bank-account/bank-account.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {
  account: any;
  transactions: Transaction[];
  actions: any;
  webhookEnabled: boolean;

  constructor(
    private router: Router,
    private snackbar: UINotificationService,
    private workspaceService: WorkspaceService,
    private bankService: BankAccountService,
    private transactionService: TransactionService,
    private plaidService: PlaidService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.account = null;
        return;
      }

      Promise.all([
        this.bankService.getMainAccount(workspace),
        this.transactionService.getTransactions(workspace.id)
      ]).then(result => {
        this.account = result[0];

        this.webhookEnabled = workspace.webhook_enabled;

        if (workspace.plaid_access_token) {
          this.actions = [
            {
              label: 'Désynchroniser',
              tooltip: 'Cliquer sur ce bouton pour supprimer la connexion avec votre compte en banque',
              onClick: () => {
                if (!confirm('Êtes-vous sûr.e de vouloir de-synchroniser votre compte en banque ?')) {
                  return;
                }

                this.plaidService.deleteAccount(workspace.id).then(
                  () => this.account = null
                );
              }
            },
            {
              label: this.webhookEnabled ? 'Import automatique' : 'Import manuel',
              tooltip: `Cliquer sur ce bouton pour ${this.webhookEnabled ? 'désactiver' : 'activer'} l'import automatique des opérations bancaires depuis votre compte en banque`,
              onClick: () => {
                if (!confirm('Êtes-vous sûr.e de vouloir arrêter l\'import automatique de vos transactions ?')) {
                  return;
                }

                this.workspaceService.togglePlaidSync(workspace);
              }
            }
          ]
        } else {
          if (this.account) {
            this.actions = [
              {
                label: 'Modifier',
                onClick: () => {
                  this.router.navigate(['/compte-bancaire/', this.account.id])
                }
              }
            ]
          }
        }

        this.transactions = result[1];
      }).catch(error => this.snackbar.snack(error, 'danger'))
    })
  }

}
