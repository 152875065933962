import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { RealtySelectionComponent } from 'src/app/components/realty-selection/realty-selection.component';
import { Property } from 'src/app/models/property/property';
import { PropertyService } from 'src/app/services/property/property.service';

@Component({
  selector: 'app-store-houses',
  templateUrl: './store-houses.component.html',
  styleUrls: ['./store-houses.component.scss']
})
export class StoreHousesComponent implements OnInit {
  loading: boolean;
  columns: TableColumn[] = [
    { name: 'short_description', label: 'Description courte', type: 'string' },
    { name: 'address', label: 'Adresse', type: 'string' },
    { name: 'monthly_income', label: 'Loyer mensuel', type: 'amount' }
  ]

  actions: FormAction[];

  records: Property[] = [];

  constructor(private service: PropertyService,
    private workspaceService: WorkspaceService,
    private modal: NbDialogService,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        return;
      }

      this.loading = true;
      this.service.getStoreHouses(workspace.id)
        .then((records: Property[]) => this.records = records)
        .then(() => this.actions = [
          {
            name: 'new',
            label: 'Ajouter un bien',
            type: 'script',
            primary: true,
            onClick: () => {
              this.modal.open(RealtySelectionComponent);
            }
          }
        ])
        .catch(error => this.notification.snack(error, 'danger'))
        .finally(() => this.loading = false)
    })
  }

}
