import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Attachment } from '../../models/attachment/attachment';
import { DatabaseService } from '../database/database.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { Action } from 'rxjs/internal/scheduler/Action';

@Injectable({
  providedIn: 'root'
})

export class FileStorageService {
  baseUrl = environment.backend.url;

  constructor(private http: HttpClient, private db: DatabaseService, private workspace: WorkspaceService) {

  }

  getAttachments(table: string, id: string): Promise<Attachment[]>{
    return this.db.getCollection<Attachment>(`file/${table}/${id}/`, 'attachment').then(
      (files: any[]) => files.map(file => {
        let attachment = new Attachment();
        attachment.id = file.id;
        attachment.name = file.name;
        attachment.content_type = file.content_type;
        attachment.object_id = file.object_id;
        attachment.file = environment.backend.url + file.file;
        attachment.format = file.format;

        return attachment;
      })
    ).catch(error => {
      throw this.handleError(error)
    });
  }

  rename(attachment: Attachment): Promise<any>{
    const workspaceID = this.workspace.current.getValue().id;

    return this.http.put(`${environment.backend.url}file/api/`, {
      id: attachment.id,
      name: attachment.name,
      content_type: attachment.content_type,
      object_id: attachment.object_id,
      workspace: workspaceID
    }).pipe(
      catchError(error => this.handleError(error))
    ).toPromise();
  }

  upload(file: File, table: string, id: string) {
    const workspaceID = this.workspace.current.getValue().id;

    const _formData = new FormData();
    _formData.append('file', file, file.name);
    _formData.append('workspace', workspaceID);
    _formData.append('object_id', id);
    _formData.append('content_type', table);

    return this.http.post(`${this.baseUrl}file/api/`, _formData).pipe(
      catchError(error => this.handleError(error))
    ).toPromise();
  }

  attach(id: string, table: string, target: string){
    return this.http.put(`${environment.backend.url}/file/api`, {
      id: id,
      content_type: table,
      object_id: target
    }).toPromise();
  }

  delete(id: string): Promise<any>{
    return this.http.delete(`${environment.backend.url}file/delete/${id}/`).pipe(
      catchError(error => this.handleError(error))
    ).toPromise();
  }

  private handleError(response: HttpErrorResponse): string {
    try {
      let error = 'Une erreur est survenue.';
      const status = response.status;

      switch (status) {
        case 405:
          error = 'Vous n\êtes pas authorisé à effectuer cette action.';
          break;
        default:
          error = 'Une erreur inattendue est survenue. Merci de réessayer.';
          break;
      }

      return error;
    } catch (exception) {
      return 'Une erreur inattendue est survenue. Merci de réessayer.';
    }
  }
}
