import { Attachment } from '../attachment/attachment';

export class Record {
    id?: string;
    table: string;
    attachments: Attachment[];
    created_on?: Date;
    updated_on?: Date;
    workspace: string;

    constructor(table: string, id?: string){
        this.table = table;
        this.id = id;
        this.attachments = [];
        this.created_on = new Date();
        this.updated_on = new Date();
    }
}
