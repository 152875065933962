import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  label: string = 'Chargement...';
  quotes: string[] = [
    'En cours de chargement',
    'Ca va s\'afficher dans pas longtemps',
    'La patience est la vertue des sages',
    'La j\'avoue, même moi je trouve ça long',
    'Aller prendre un café, ça fera passer le temps',
    'Vous êtes toujours là ?',
    'Moi aussi... (pas trop le choix)',
    'Aller encore un petit effort',
  ];

  constructor() { }

  ngOnInit(): void {
    setInterval(
      () => {
        let index = this.quotes.indexOf(this.label);

        if (index === this.quotes.length) {
          index = 0;
        } else {
          index ++;
        }

        this.label = this.quotes[index];
      }, 2500
    );
  }

}
