import { Component, OnInit } from '@angular/core';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { BankAccount } from 'src/app/models/bank-account';
import { BankAccountService } from 'src/app/services/bank-account/bank-account.service';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss']
})

export class BankAccountsComponent implements OnInit {
  title = 'Comptes bancaires';
  loading: boolean;
  accounts: BankAccount[];
  columns: TableColumn[] = [
    {
      name: 'bank', label: 'Banque', type: 'string'
    },
    {
      name: 'short_description', label: 'Description courte', type: 'string'
    },
    {
      name: 'number', label: 'Nº de compte', type: 'string'
    },
    {
      name: 'iban', label: 'Iban', type: 'string'
    },
    {
      name: 'bic', label: 'BIC', type: 'string'
    },
  ];

  actions: FormAction[] = [
    { name: 'new_account', label: 'Ajouter un compte', type: 'link', link: '/compte-bancaire/-1', primary: true }
  ];

  constructor(private db: BankAccountService, private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if(!workspace){
        this.accounts = [];
        return;
      }

      this.loading = true;
      this.db.getAccounts(workspace.id).then(
        records => this.accounts = records
      ).catch(error => console.warn(error)
      ).finally(() => this.loading = false);
    })
  }
}
