import { Injectable } from '@angular/core';
import { WorkspaceMember } from 'src/app/@auth/models/workspace-member/workspace-member';
import { Deposit } from 'src/app/models/deposit';
import { Guarantor } from 'src/app/models/guarantor';
import { Property } from 'src/app/models/property/property';
import { Rent } from 'src/app/models/rent';
import { Email } from '../../models/email';
import { Record } from '../../models/record/record';

@Injectable({
  providedIn: 'root'
})
export class ModelMappingService {

  constructor() { }

  create<T>(type: (new () => T)): T {
    return new type();
  }

  getModel(table: string, id?: string): {url: string, record: Record}{
    switch (table) {
      case 'workspace-member':
        return {
          url: 'workspace/member',
          record: new WorkspaceMember()
        };
      case 'deposit':
        return {
          url: 'transaction/deposit',
          record: new Deposit()
        };
      case 'rent':
      return {
        url: 'transaction/rent',
        record: new Rent()
      };
      case 'guarantor':
        return {
          url: 'guarantor',
          record: new Guarantor()
        };
      case 'email':
        return {
          url: `email/get_archives/workspace`,
          record: new Email()
        }
      default:
        return {
          url: table,
          record: new Record(table, id)
        }
    }
  }
}
