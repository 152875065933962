import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company/company.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})

export class CompanyComponent implements OnInit {
  loading: boolean;
  error: string;
  options: FormOptions = {
    title: {
      default: 'Nouvelle entreprise',
      prefix: 'Entreprise',
      fields: 'short_description'
    },
    page: 'entreprise',
    redirectTo: 'contacts'
  }
  relatedLists: RelatedList[] = [];

  sections: FormSection[];
  company: Company;

  constructor(private db: CompanyService,
     private router: ActivatedRoute,
     private workspaceService: WorkspaceService,
     private transactionService: TransactionService,
     private documentService: DocumentService) { }

  ngOnInit(): void {
    this.loading = true;

    this.workspaceService.current.subscribe(workspace => {
      if(!workspace){
        return;
      }

      this._loadSections().then(() => {
        this.router.paramMap.subscribe(params => {
          const id = params.get('id');
          this.company = new Company();
  
          if (id !== '-1') {
            this.db.getCompany(id)
              .then(record => this.company = record)
              .then(() => this._loadRelatedLists(workspace.id, id))
              .catch(error => this.error = error)
              .finally(() => this.loading = false);
          } else {
            this.loading = false;
          }
        });
      });
    })
  }

  _loadSections(): Promise<FormSection[]>{
    return new Promise((resolve, reject) => {
      this.sections = [
        {
          columns: [
            {
              elements: [
                {
                  field: 'name', label: 'Nom de l\'entreprise', type: 'string', isRequired: true
                },
                {
                  field: 'short_description',
                  label: 'Description de l\'entreprise',
                  type: 'string',
                  isRequired: true,
                  hint: 'Ex: plombier, peintre, etc.'
                },
                {
                  field: 'number', label: 'Numéro de SIRET', type: 'string'
                },
                {
                  field: 'manager', label: 'Responsable', type: 'string', isRequired: true
                },
              ]
            },
            {
              elements: [
                {
                  field: 'website', label: 'Site web', type: 'url'
                }, 
                {
                  field: 'address', label: 'Adresse', type: 'location'
                },
                {
                  field: 'phone', label: 'Téléphone', type: 'phone', isRequired: true
                },
                {
                  field: 'email', label: 'Email', type: 'email', isRequired: true
                },
              ]
            }
          ]
        }
      ]

      resolve(this.sections);
    })
  }

  _loadRelatedLists(workspaceID: string, id: string){
    Promise.all([this.transactionService.getTransactions(workspaceID), this.documentService.getDocuments(workspaceID)]).then(
      result => {
        const transactions = result[0].filter(transaction => transaction.company === id);

        this.relatedLists.push({
          name: 'transactions',
          columns: [
            { name: 'short_description', label: 'Libelle', type: 'string'},
            { name: 'amount', label: 'Montant', type: 'amount'},
            { name: 'operation_date', label: 'Date opération', type: 'date'}
          ],
          page: '/transaction',
          title: `Transactions (${transactions.length})`,
          records: transactions
        })

        const documents = result[1].filter(document => document.company === id);

        this.relatedLists.push({
          name: 'documents',
          columns: [
            { name: 'name', label: 'Nom du document', type: 'string'},
            { name: 'memo', label: 'Memo', type: 'string'},
            { name: 'category', label: 'Catégorie', type: 'choice'},
            { name: 'amount_incl_tax', label: 'Montant TTC', type: 'amount'},
          ],
          page: '/document',
          title: `Document (${documents.length})`,
          records: documents
        })

        const members = [];

        this.relatedLists.push({
          name: 'company_members',
          columns: [
            { name: 'username', label: 'Utilisateur', type: 'string'},
            { name: 'email', label: 'Email', type: 'email'},
            { name: 'phone', label: 'Téléphone', type: 'phone'},
          ],
          actions: [
            {
              name: 'add_member',
              label: 'Ajouter un utilisateur',
              type: 'link',
              primary: true
            }
          ],
          page: '/entreprise/utilisateur',
          title: `Membres (${documents.length})`,
          records: members
        })
      }
    )
  }
}
