import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { Email } from 'src/app/@core/models/email';
import { EmailService } from 'src/app/@core/services/email/email.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})

export class EmailComponent implements OnInit {
  email: Email;
  error: string;
  states = new ChoiceList().getChoices('email', 'status');

  constructor(private workspaceService: WorkspaceService,
     private location: ActivatedRoute,
      private mailbox: EmailService,
      private notification: UINotificationService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.location.params.subscribe(params => {
        this.mailbox.getEmails(workspace.id).then(
          emails => this.email = emails.find(email => email.id == params['id'])
        ).then(() => console.log(this.email)
        ).catch(error => this.notification.snack(error, 'danger'));
      })
    })
  }

}
