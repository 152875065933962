import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private returnUrl: string;

  constructor(private auth: AuthenticationService, private location: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    const user = this.auth.user.getValue();

    if (!user) {
      this.location.navigate(['auth/connexion'], { queryParams: { returnUrl: state.url } });
      return false;
    } else {
      return true;
    }
  }
}
