import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { Product } from '../../models/product';
import { StripeSubscription } from '../../models/stripe-subscription';
import { SubscriptionService } from '../../services/subscription/subscription.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.scss']
})

export class SubscribeComponent implements OnInit {
  product: string;
  error: string;
  step: string = 'offer';
  space: string;
  subscription: StripeSubscription;
  paymentRequired: boolean;
  interval: 'month' | 'year' = 'month';

  constructor(private dialog: NbDialogRef<SubscribeComponent>,
     private subscriptionService: SubscriptionService,
     private notification: UINotificationService,
     private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.space = workspace.id;
      this.subscriptionService.getSubscription().then(
        subscription => {
          this.subscription = subscription
          
          if(!subscription.default_payment_method){
            this.paymentRequired = true;
          }
        }
      ).catch(error => this.notification.snack(error, 'danger'));
    })
  }

  onSubscription(){
    this.subscriptionService.initialize();
    this.notification.snack('Abonnement mis à jour');
    this.dialog.close();
  }
}
