import { Record } from '../@core/models/record/record'
import { Transaction } from './transaction/transaction';

export class BankAccount extends Record {
    short_description: string;
    bank: string;
    bic: string;
    iban: string;
    official_name: string;
    balance?: number;
    currency: string;
    account_id: string;
    transactions: Transaction[];

    constructor(id?: string) {
        super('bank-account', id);
        this.currency = 'EUR';
    }
}
