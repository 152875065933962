import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { Building } from 'src/app/models/building';
import { Property } from 'src/app/models/property/property';
import { RealtyGroupService } from 'src/app/services/realty-group.service';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})

export class BuildingComponent implements OnInit {

  loading: boolean;
  options: FormOptions = {
    title: {
      default: 'Nouvel immeuble',
      prefix: 'Immeuble',
      fields: 'short_description'
    },
    page: 'immeuble',
    redirectTo: 'immeubles'
  }
  building: Building;
  sections: FormSection[];

  realties: RelatedList = {
    name: 'realties',
    title: 'Appartements',
    columns: [
      { label: 'Description courte', name: 'short_description', type: 'string' },
      { label: 'Taux de remplissage', name: 'filling_rate', type: 'percentage' },
      { label: 'Revenu mensuel', name: 'monthly_income', type: 'amount' },
    ]
  };

  constructor(private realtyGroup: RealtyGroupService, private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.loading = true;
    this._loadSections().then(
      () => this.router.paramMap.subscribe(params => {
        const id = params.get('id');

        if (id !== '-1') {
          this.realtyGroup.getBuilding(id).then(
            record => this.building = record
          ).then(
            () => {
              this.realties.records = this.building.realties;
              this.realties.title = `Appartements (${this.realties.records.length})`;
              this.realties.actions = [
                {
                  name: 'new_flat',
                  label: 'Ajouter un appartement',
                  type: 'link',
                  primary: true,
                  link: '/bien-immobilier/-1', queryParams: {
                    sysparm_default: `realtygroup=${id}^property_type=a^address=${this.building.address}^building_year=${this.building.building_year}`
                  }
                }
              ]
            }
          ).catch(error => console.warn(error)
          ).finally(
            () => this.loading = false
          )
        } else {
          this.building = new Building();
          this.loading = false;
        }
      })
    )
  }

  private _loadSections(): Promise<FormSection[]> {
    return new Promise((resolve, reject) => {
      this.sections = [
        {
          columns: [
            {
              elements: [
                {
                  field: 'short_description', label: 'Description courte', type: 'string', isRequired: true
                },
                {
                  field: 'building_year', label: 'Année de construction du bâtiment', type: 'integer'
                },
              ]
            },
            {
              elements: [
                {
                  field: 'address', label: 'Adresse', type: 'location', isRequired: true
                }
              ]
            }
          ]
        }
      ]

      resolve(this.sections);
    });
  }
}
