import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { bounceInOnEnterAnimation } from 'angular-animations';
import { Attachment } from 'src/app/@core/models/attachment/attachment';
import { EmailService } from 'src/app/@core/services/email/email.service';
import { PdfGeneratorService } from 'src/app/@core/services/pdf-generator/pdf-generator.service';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { DocumentTemplate } from 'src/app/models/document-template';
import { Rent } from 'src/app/models/rent';
import { Tenant } from 'src/app/models/tenant/tenant';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { environment } from 'src/environments/environment';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';

@Component({
  selector: 'app-tenant-receipt',
  templateUrl: './tenant-receipt.component.html',
  styleUrls: ['./tenant-receipt.component.scss'],
  animations: [
    bounceInOnEnterAnimation()
  ]
})
export class TenantReceiptComponent implements OnInit {
  @Input() id: string;

  @ViewChild('form') ngForm: NgForm;

  message: string;
  tenants: Tenant[];
  rents: Rent[];
  current: {
    tenant: Tenant,
    template: DocumentTemplate,
    rent: Rent
  } = { tenant: null, template: null, rent: null };
  loading: boolean;
  refresh: boolean;
  output: '';
  templates: DocumentTemplate[];
  model: string;
  content: string;
  replaceVariable = true;
  config = environment.tiny.config;
  key = environment.tiny.apiKey;

  constructor(private tenant: TenantService,
    private pdfGenerator: PdfGeneratorService,
    private document: DocumentService,
    private email: EmailService,
    private dialog: NbDialogRef<TenantReceiptComponent>,
    private router: Router,
    private auth: AuthenticationService,
    private workspaceService: WorkspaceService,
    private documentGenerator: DocumentGeneratorService) {
  }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.current = null;
        return;
      }

      this.loading = true;

      Promise.all([this.tenant.getActiveTenants(workspace.id), this.document.getTemplates(workspace.id)]).then(
        result => {
          this.tenants = result[0];
          this.templates = result[1];
        }
      ).then(() => {
        this.current.template = this.templates[0];
        this.current.tenant = this.tenants.find(tenant => tenant.id === this.id);

        if (this.current.tenant) {
          return this.tenant.getRentsByTenant(this.current.tenant.id).then(
            rents => this.rents = rents
          );
        }
      }).catch(error => console.warn(error)
      ).finally(() => this.loading = false);
    })
  }

  refreshTenant() {
    if (this.current.tenant) {
      return this.tenant.getTenant(this.current.tenant.id).then(
        tenant => this.current.tenant = tenant
      );
    }
  }

  onTenantChange($event) {
    if (this.current.tenant) {
      this.tenant.getRentsByTenant(this.current.tenant.id).then(
        rents => this.rents = rents
      );
    }
  }

  applyTemplate(template: DocumentTemplate) {
    if (!template) {
      return;
    }

    this.refresh = true;
    this.document.getTemplate(template.id).then(
      template => this.model = template.content
    ).then(() => {
      return this.documentGenerator.createTenantReceipt(this.current.rent.id, this.current.template.id).then(
        (content) => this.content = content
      );
    }).finally(() => this.refresh = false);
  }

  download() {
    const title = `${this.current.template.name} - ${this.current.tenant.first_name} ${this.current.tenant.last_name}`;
    this.pdfGenerator.generate(title, this.content);
  }

  send() {
    const user = this.auth.user.getValue();
    let signature = 'L\équipe Liimo';

    if (user) {
      signature = `${user.first_name} ${user.last_name}`;
    }

    const recipient = this.current.tenant.email;
    const body = `Bonjour,<br/><br/>Vous trouverez en pièce jointe votre quittance de loyer.<br/><br/>Cordialement,<br/>${signature}`

    this.document.createAttachmentFromHtml(this.workspaceService.id, `Quittance`, this.content, 'tenant', this.id).then(
      (attachment: Attachment) => {
        this.email.send([recipient],
          this.current.template.name,
          body,
          [attachment.id]);
      }
    ).then(() => this.message = `La document a bien été envoyé à ${this.current.tenant.email}.`);
  }

  edit(templateID: string) {
    this.router.navigate(['/administration/modele-de-document', templateID]);
    this.dialog.close();
  }

  htmlEntities(input: string): string {
    return String(input).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }
}