import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { TransactionPayment } from 'src/app/models/transaction-payment/transaction-payment';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-rent-transactions',
  templateUrl: './rent-transactions.component.html',
  styleUrls: ['./rent-transactions.component.scss']
})
export class RentTransactionsComponent implements OnInit {
  @Input() workspaceID: string;
  @Input() id: string;

  loading: boolean;
  transactions: Transaction[];

  constructor(private transactionService: TransactionService,
    private dialog: NbDialogRef<RentTransactionsComponent>,
    private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.transactionService.getTransactions(this.workspaceID).then(
      transactions => this.transactions = transactions.filter(
        transaction => transaction.deposit == this.id || transaction.rent == this.id
      )
    ).then(() => this.loading = false)
  }

  goTo(transactionID: string) {
    this.router.navigate(['/transaction', transactionID]);
    this.dialog.close();
  }
}
