import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { Email } from 'src/app/@core/models/email';
import { TenantService } from '../tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})

export class CalendarEventService {
  constructor(private http: HttpClient, private tenantUtil: TenantService) { }

  getTenantEvents(workspaceID: string): Promise<CalendarEvent[]> {
    return this.tenantUtil.getActiveTenants(workspaceID).then(
      tenants => {
        const events: CalendarEvent[] = [];
        const today = new Date();

        tenants.forEach((tenant) => {
          const checkIn = new Date(tenant.check_in);
          const checkOut = new Date(tenant.checkout);

          if(checkIn > today){
            events.push({
              title: `Arrivée de ${tenant.first_name} ${tenant.last_name}`,
              description: 'Prévoir l\'arrivée du locataire',
              startDate: checkIn,
              endDate: checkIn,
              link: `${environment.frontend.url}/locataire/${tenant.id}`,
              allDay: false,
            })
          }

          if(checkOut > today){
            events.push({
              title: `Départ de ${tenant.first_name} ${tenant.last_name}`,
              description: 'Prévoir le départ du locataire',
              startDate: checkOut,
              endDate: checkOut,
              link: `${environment.frontend.url}/locataire/${tenant.id}`,
              allDay: false,
            })
          }
        });

        return events.sort((a, b) => {
          if(a.startDate < b.endDate){
            return 1;
          }

          if(a.startDate > b.endDate){
            return -1;
          }

          return 0;
        });
      }
    )
  }

  downloadEvent(event: CalendarEvent) {
    return this.http.post(`${environment.backend.url}calendar/`, {
      title: event.title,
      description: event.description,
      link: event.link,
      start_date: event.startDate,
      end_date: event.endDate,
    }, {
      responseType: 'text'
    }).toPromise().then(
      text => this._downLoadFile(`${event.title}.ics`, text, 'plain/text')
    ).catch(error => console.warn(error));
  }

  sendEvent(email: Email, event: CalendarEvent) {
    if (!environment.production) {
      email.recipient = 'paul.galmot@gmail.com';
    }

    return this.http.post(`${environment.backend.url}calendar/email/`, {
      title: email.title,
      recipient: email.recipient,
      sender: email.sender,
      body: email.body,
      event: {
        start_date: event.startDate,
        end_date: event.endDate,
        title: event.title,
        description: event.description,
        link: event.link
      }
    }).toPromise();
  }

  _downLoadFile(title: string, data: any, type: string) {
    const blob = new Blob([data], { type: type });
    const a = document.createElement('a')
    const objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = title;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }
}
