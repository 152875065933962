import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { DateUtil } from 'src/app/@core/util/date-util';
import { APP_DATE_FORMATS } from 'src/app/@core/util/datepicker-format';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-financial-dashboard',
  templateUrl: './financial-dashboard.component.html',
  styleUrls: ['./financial-dashboard.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class FinancialDashboardComponent {
  categories: { label: string, categories: string, total: number, months: {} }[];
  months: { label: string, startDate: Date, endDate: Date, total: number }[];
  filter: { startFrom: string, period: number };
  transactions: Transaction[];
  cashFlow: { total: number, inflow: 0, outflow: 0 };
  startDate: Date;
  endDate: Date;
  month: any;
  category: any;
  options: any;
  choiceList = new ChoiceList();
  dateUtil = new DateUtil();
  dataSource: any[];
  headers: string[];
  transactionHeaders = ['short_description', 'operation_date', 'amount', 'category'];
  selectedTransactions: [];

  constructor(private workspace: WorkspaceService,
    private transactionService: TransactionService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.transactionService.getTransactions(workspace.id).then(
        transactions => {
          this.filter = {
            startFrom: null,
            period: 6
          };

          this.transactions = transactions;
          this.refresh();
        }
      )
    })
  }

  refresh(): void {
    this.headers = ['category'];
    this.endDate = this.dateUtil.getEndOfMonth(new Date());

    if (this.filter.period > 1) {
      this.startDate = this.dateUtil.add(this.endDate, -(this.filter.period - 1), 'M');
    } else {
      this.startDate = this.dateUtil.getBeginningOfMonth(this.endDate);
    }

    this.categories = [
      {
        label: 'Loyer',
        categories: 'r,d',
        total: 0,
        months: {}
      },
      {
        label: 'Emprunt bancaire',
        categories: 'l',
        total: 0,
        months: {}
      },
      {
        label: 'Charges',
        categories: 'c,e,b',
        total: 0,
        months: {}
      },
      {
        label: 'Impôts & Taxes',
        categories: 't',
        total: 0,
        months: {}
      },
      {
        label: 'Autres',
        categories: 'o',
        total: 0,
        months: {}
      }
    ]

    let month = {
      label: this.dateUtil.toFormat(this.startDate, 'MMM YYYY'),
      startDate: this.dateUtil.getBeginningOfMonth(this.startDate),
      endDate: this.dateUtil.getEndOfMonth(this.startDate),
      total: 0
    }

    this.months = [];

    do {
      this.months.push(month);
      this.headers.push(month.label);

      const startDate = this.dateUtil.add(month.endDate, 1, 'd');

      month = {
        label: this.dateUtil.toFormat(startDate, 'MMM YYYY').toString(),
        startDate: this.dateUtil.getBeginningOfMonth(startDate),
        endDate: this.dateUtil.getEndOfMonth(startDate),
        total: 0
      }
    } while (this.endDate >= month.endDate);

    this.headers.push('total');
    this.updateTable(this.transactions);
    this.dataSource = this.categories;
  }

  updateTable(transactions: Transaction[]) {
    if (!transactions) {
      return;
    }

    transactions.filter(transaction => {
      const dateUtil = new DateUtil();
      const operationDate = new Date(transaction.operation_date);

      return dateUtil.isAfter(operationDate, this.startDate) &&
        dateUtil.isBefore(operationDate, this.endDate, true);
    }).forEach(transaction => {
      let category = this.categories.find(category => category.categories.indexOf(transaction.category.toString()) > -1);

      if (!category) {
        category = this.categories.find(category => category.categories.indexOf('o') > -1);
      }

      if (category) {
        const month = this.months.find(month =>
          month.startDate <= transaction.operation_date && month.endDate >= transaction.operation_date
        );

        if (month) {
          category.total += transaction.amount;
          month.total += transaction.amount;

          if (!category.months[month.label]) {
            category.months[month.label] = { total: transaction.amount, transactions: [transaction] };
          } else {
            category.months[month.label].total += transaction.amount;
            category.months[month.label].transactions.push(transaction);
          }
        }
      }
    })

    var previous = 0;

    this.months.map(month => {
      month['trend'] = month.total - previous;
      previous = month.total || 0;
      return month;
    });

    this.cashFlow = this.categories.reduce((result, category) => {
      if (category.total > 0) {
        result.total += category.total;
        result.inflow += category.total;
      } else {
        result.total += category.total;
        result.outflow += category.total;
      }

      return result;
    }, { total: 0, inflow: 0, outflow: 0 })

    this.options = {
      tooltip: {
        trigger: 'item',
        formatter: (input) => {
          const amount = parseFloat(input.value);
          return 'Montant total : ' + amount.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR' })
        }
      },
      legend: {
        show: false
      },
      series: [
        {
          name: 'Flux',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          emphasis: {
            label: {
              show: true,
              fontSize: '12',
              fontWeight: 'bold',
            }
          },
          data: [
            {
              value: this.cashFlow?.inflow,
              name: 'Flux entrant'
            },
            {
              value: -1 * this.cashFlow?.outflow,
              name: 'Flux sortant'
            }
          ]
        }
      ]
    };
  }

  showTransactions(category: any, month: any) {
    this.category = category;
    this.month = month;

    this.selectedTransactions = category?.months[month.label]?.transactions || [];
  }

  goTo(id: string){
    this.router.navigate(['transaction', id]);
  }

  getTotal(): number {
    return this.categories.reduce((total, category) => {
      return total + category.total;
    }, 0);
  }
}
