import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { QueryFn } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Record } from '../../models/record/record';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ModelMappingService } from '../model-mapping/model-mapping.service';

@Injectable({
  providedIn: 'root'
})

export class DatabaseService {
  baseUrl = environment.backend.url;
  headers = new HttpHeaders({
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
  });

  events: BehaviorSubject<{ table: string, record: Record, operation: 'insert' | 'update' | 'delete' }>;

  constructor(private http: HttpClient, private workspace: WorkspaceService, private mapping: ModelMappingService) {
    this.events = new BehaviorSubject(null);
  }

  get<T>(table: string, id: string): Promise<any> {
    const mapping = this.mapping.getModel(table);

    if (!mapping) {
      throw new Error('No matching model');
    }

    const url = `${this.baseUrl}${mapping.url}/${id}/`;

    return this.http.get<T>(url).pipe(
      map(record => {
        if (record['id'] !== id) {
          throw 'Aucun enregistrement ne correspond à votre demande';
        }

        let model = mapping.record;
        model = { ...model, ...record };
        model.created_on = record['date_created'];
        delete model['date_created'];
        model.updated_on = record['last_update'];
        delete model['last_update'];

        return model;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          throw this.handleError(error);
        } else {
          throw error;
        }
      })
    ).toPromise();
  }

  getRecord<T>(table: string, query?: QueryFn): Promise<any> {
    return this.http.get(`${this.baseUrl}${table}`).pipe(
      map(record => {
        return record;
      }),
      catchError(error => {
        throw this.handleError(error);
      })
    ).toPromise();
  }

  getCollection<T extends Record>(url: string, table: string, query?: QueryFn): Promise<T[]> {
    const mapping = this.mapping.getModel(table, null);

    if (!mapping) {
      throw new Error('No matching model');
    }

    return this.http.get<T[]>(`${this.baseUrl}${url}`).pipe(
      map((records: any[]) => {

        if (!records) {
          return [];
        }

        if (!(records instanceof Array)) {
          return [];
        }

        return records?.map(record => {
          record.table = table;
          record.created_on = record['date_created'];
          delete record['date_created'];
          record.updated_on = record['last_update'];
          delete record['last_update'];

          return record;
        });
      }),
      catchError(error => {
        throw this.handleError(error);
      })
    ).toPromise();
  }

  save<T extends Record>(record: Record): Promise<T> {
    try {
      if (!record.workspace) {
        record.workspace = this.workspace.current.getValue().id;
      }

      const mapping = this.mapping.getModel(record.table, record.id);

      if (!mapping) {
        throw new Error(`No mapping for current record ${record.table}`);
      }

      if (record.id) {
        console.log(record);
        return this.http.put<T>(`${this.baseUrl}${mapping.url}/api/`, record).pipe(
          map(response => {
            console.log(response);
            record = { ...response };
            record.created_on = response['date_created'];
            record.updated_on = response['last_update'];
            delete record['last_update'];

            return record as T;
          }),
          tap(() => this.events.next({ table: record.table, record: record, operation: 'update' })),
          catchError(error => {
            console.warn(error);
            throw this.handleError(error);
          })
        ).toPromise();
      }

      delete record.id;

      return this.http.post<T>(`${this.baseUrl}${mapping.url}/api/`, record).pipe(
        map(response => {
          let model = mapping.record;
          model = { ...response, ...model };
          model.id = response.id;
          model.created_on = response['date_created'];
          delete model['date_created'];
          model.updated_on = response['last_update'];
          delete model['last_update'];

          return model as T;
        }),
        tap(() => this.events.next({ table: record.table, record: record, operation: 'insert' })),
        catchError(error => {
          throw this.handleError(error);
        })
      ).toPromise();
    } catch (exception) {
      throw exception;
    }
  }

  delete(table: string, id: string): Promise<void | any> {
    return this.http.delete(`${this.baseUrl}${table}/delete/${id}/`).pipe(
      tap(() => this.events.next({ table: table, record: null, operation: 'delete' })),
      catchError(exception => {
        throw this.handleError(exception)
      })
    ).toPromise();
  }

  private handleError(response: HttpErrorResponse): { message: string, details?: any } {
    try {
      const status = response.status;

      switch (status) {
        case 500:
          return {
            message: response.error?.detail || 'Une erreur est survenue. Merci de réessayer.'
          }
          break;
        case 400:
          return {
            message: 'Donnée(s) incorrecte(s)',
            details: response.error
          }
          break;
        case 401:
          return {
            message: response.statusText
          }
          break;
        case 404:
          return {
            message: 'Aucun enregistrement ne correspond à la requête.'
          }
          break;
        case 405:
          return {
            message: 'Vous n\'êtes pas autorisés à effectuer cette opération.'
          }
          break;
        default:
          return {
            message: response.statusText
          }
          break;
      }
    } catch (exception) {
      return { message: 'Une erreur est survenue. Merci de réessayer.' };
    }
  }
}
