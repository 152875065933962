import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError, tap } from 'rxjs/operators';
import { User } from '../../models/user/user';
import { Router } from '@angular/router';
import { Person } from '../../models/person';
import { Corporation } from '../../models/corporation';
import { WorkspaceService } from '../workspace/workspace.service';

export interface AuthResponseData {
  id: string;
  kind: string;
  idToken: string;
  email: string;
  access_token: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
  expires_in: Date;
}

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  profile: User;
  user = new BehaviorSubject<User>(null);
  isAuthenticated = new BehaviorSubject(false);
  private tokenExpirationTimer: any;

  accessToken: string;
  refreshToken: string;
  expiresIn: number;
  options: {
    headers?: HttpHeaders | { [header: string]: string | string[] },
    observe?: 'body' | 'events' | 'response',
    params?: HttpParams | { [param: string]: string | string[] },
    reportProgress?: boolean,
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
    withCredentials?: boolean,
  }

  constructor(private http: HttpClient, private router: Router, private workspace: WorkspaceService) {
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<any> {
    const data = {
      grant_type: 'password',
      client_id: environment.backend.client_id,
      client_secret: environment.backend.client_secret,
      username: email,
      password: password
    };

    return await this.http.post<AuthResponseData>(`${environment.backend.url}auth/token/`, data).pipe(
      tap(response => {
        return this.handleAuthentication(
          email,
          response.access_token,
          +response.expires_in
        );
      }),
      catchError(error => this.handleError(error))
    ).toPromise().then(
      () => this.getProfile(email)).then(user => {
        this.user.next(user);
      }).catch(error => {
        throw this.handleError(error);
      });
  }

  get email(): string {
    const user = this.user.getValue();

    if (!user) {
      return null;
    }

    return user.email;
  }

  sendResetNotification(email: string): Promise<any> {
    return this.http.post(`${environment.backend.url}user/forgot_password/`, {
      email: email,
      invitation_type: 'pf'
    }).pipe(catchError((error: HttpErrorResponse) => {
      if(error.status == 404){
        throw 'Email non trouvé.';
      }

      throw 'Une erreur est survenue. Merci de réessayer';
    })).toPromise();
  }
  
  resetPassword(ticket: string, password: string, passwordConfirm: string): Promise<any> {
    return this.http.put(`${environment.backend.url}user/reset-password/`, {
      invitation_id: ticket,
      password1: password,
      password2: passwordConfirm
    }).pipe(catchError((error: HttpErrorResponse) => {
      throw 'Une erreur est survenue. Merci de réessayer';
    })).toPromise();
  }

  getProfile(email: string): Promise<Person> {
    return this.http.get<User>(`${environment.backend.url}user/${email}/workspace/`).pipe(
      map(data => {
        const user = { ...data } as Person;

        if (!user.profile) {
          user.profile = {
            date_of_birth: '',
            place_of_birth: ''
          }
        }

        return user;
      }),
      catchError(error => throwError(error))
    ).toPromise();
  }

  updateProfile(user: Person): Promise<User> {
    return this.http.put<User>(`${environment.backend.url}user/api/`, {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
      address: user.address,
      email: user.email,
      phone: user.phone,
      profile: user.profile,
      company: user.company,
      company_role: user.company_role
    }).toPromise().then(
      () => this.getProfile(user.email)
    );
  }

  createUserWithEmailAndPassword(email: string, password: string, firstName: string, lastName: string,
    agreeTermsAndConditions: boolean): Promise<AuthResponseData> {
    const data = {
      email: email,
      password: password,
      name: `${firstName} ${lastName}`,
      first_name: firstName,
      last_name: lastName,
      address: '',
      phone: '',
      terms_conditions_accepted: agreeTermsAndConditions,
      is_human: true
    };

    return this.http.post<AuthResponseData>(`${environment.backend.url}user/create/`, data).pipe(
      catchError(error => {
        console.log(error);
        throw this.handleError(error);
      }),
    ).toPromise();
  }

  createCorporation(corporation: Corporation) {
    const data = {
      email: corporation.email,
      name: corporation.name,
      number: corporation.number,
      password: Math.random().toString(36).slice(-8),
      first_name: corporation.first_name,
      last_name: corporation.last_name,
      address: corporation.address,
      phone: corporation.phone,
      terms_conditions_accepted: true,
      is_company: true
    };

    return this.http.post<Corporation>(`${environment.backend.url}user/create/`, data).pipe(
      catchError(error => {
        throw new Error(this.handleError(error));
      })
    ).toPromise();
  }

  signOut(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(this.user.next(null));
    })
  }

  isLoggedIn(): Promise<boolean> {
    if (!this.accessToken) {
      return new Promise(resolve => resolve(false));
    }

    return this.http.get(`${environment.backend.url}user/token/${this.accessToken}/`).pipe(
      map((userID) => {
        return !!userID;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        throw error
      })
    ).toPromise();
  }

  autoLogin(): Promise<User> {
    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userData'));

    if (!userData) {
      return new Promise((resolve, reject) => resolve(null));
    }

    const loadedUser = new Person(
      userData.email,
      userData._token,
      new Date(userData._tokenExpirationDate)
    );

    if (!loadedUser.token) {
      return new Promise((resolve, reject) => reject(null));
    }

    this.accessToken = loadedUser.token;

    return this.isLoggedIn().then(
      (isLoggedIn) => {
        if (!isLoggedIn) {
          this.accessToken = null;
          return null;
        }

        return this.getProfile(userData.email).then(user => {
          this.user.next(user);

          const expirationDuration =
            new Date(userData._tokenExpirationDate).getTime() -
            new Date().getTime();

          this.isAuthenticated.next(true);
          this.autoLogout(expirationDuration);
          return user;
        }).catch(error => {
          console.warn(error);
          throw this.handleError(error)
        });
      }).catch(error => {
        console.warn(error);
        this.accessToken = null;
        return null;
      });
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/auth/connexion']);
    localStorage.removeItem('userData');

    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }

    this.tokenExpirationTimer = null;
    this.isAuthenticated.next(false);
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  private handleAuthentication(
    email: string,
    token: string,
    expiresIn: number
  ): User {

    this.accessToken = token;
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new Person(email, token, expirationDate);
    this.autoLogout(expiresIn * 1000);
    localStorage.setItem('userData', JSON.stringify(user));

    return user;
  }

  private handleError(response: HttpErrorResponse) {
    try {
      let errorMessage = 'Une erreur est survenue. Merci de réessayer.';

      if (!response.error) {
        return errorMessage;
      }

      if (response.status === 429) {
        return 'Le serveur est actuellement indisponible. Merci de réessayer plus tard';
      }

      const error = response.error?.email[0] || response.error;

      switch (error) {
        case 'invalid_grant':
          errorMessage = 'Identifiants incorrects';
          break;
        case 'Un objet User avec ce champ Email address existe déjà.':
          errorMessage = 'Un compte associé à cet email existe déja.';
          break;
        case 'INVALID_PASSWORD':
          errorMessage = 'Mot de passe incorrect.';
          break;
      }

      return errorMessage;
    } catch (exception) {
      console.log(exception);
      return 'Une erreur est survenue. Merci de réessayer.';
    }
  }
}
