import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkspaceInvitation } from '../../models/workspace-invitation/workspace-invitation';
import { AuthenticationService } from '../authentication/authentication.service';
import { WorkspaceService } from '../workspace/workspace.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceInvitationService {

  constructor(private workspaceService: WorkspaceService,
    private httpClient: HttpClient,
    private auth: AuthenticationService) { }

  getInvitations(email: string): Promise<WorkspaceInvitation[]> {
    return this.httpClient.get(`${environment.backend.url}user/workspace/invitation/${email}/`).pipe(
      map(
        (invitations: any[]) => invitations.map(invitation => {
          return this._parseInvitation(invitation);
        })
      )
    ).toPromise().then(
      (invitations: any[]) => Promise.all(invitations.map(async invitation => {
        if(!invitation.meta.workspace_id){
          return null;
        }
        
        const workspace = await this.workspaceService.getWorkspace(invitation.meta.workspace_id).then(
          workspace => workspace
        ).catch(error => console.warn(error));

        if (!workspace) {
          return null;
        }

        invitation.short_description = workspace.short_description;

        return invitation;
      }))
    ).catch(error => {
      console.warn(error);
      throw error
    })
  }

  getInvitation(id: string): Promise<WorkspaceInvitation> {
    return this.httpClient.get(`${environment.backend.url}user/workspace/invitation/${id}/`).pipe(
      map(response => this._parseInvitation(response))
    ).toPromise()
  }

  approveInvitation(email: string, invitationID: string, workspaceID: string): Promise<WorkspaceInvitation> {
    const $invitation = this.getInvitation(invitationID);
    const $workspace = this.workspaceService.getWorkspace(workspaceID);

    return Promise.all([$invitation, $workspace]).then(
      async result => {
        let invitation = result[0];
        const workspace = result[1];

        if (!workspace) {
          throw 'L\'espace de travail n\'existe pas';
        }

        if (!invitation) {
          throw 'L\'invitation n\'existe pas';
        }

        const user = this.auth.user.getValue();
        await this.workspaceService.createMember(user.id, workspaceID, 'a').catch(error => {
          throw error
        })

        return this.httpClient.put(`${environment.backend.url}user/workspace/invitation/api/`, {
          id: invitation.id,
          email_sender: invitation.invited_by,
          approval: 'a',
          email: email
        }).pipe(
          map(response => this._parseInvitation(response)),
          catchError((error: HttpErrorResponse) => {
            if (error.status == 405) {
              throw 'Vous n\'êtes pas autorisé à effectuer cette action';
            }

            throw 'Une erreur est survenue.';
          })
        ).toPromise();
      }).catch(error => {
        throw error;
      })
  }

  rejectInvitation(email: string, invitationID: string, workspaceID: string): Promise<WorkspaceInvitation> {
    const $invitation = this.getInvitation(invitationID);
    const $workspace = this.workspaceService.getWorkspace(workspaceID);

    return Promise.all([$invitation, $workspace]).then(
      result => {
        let invitation = result[0];
        const workspace = result[1];

        if (!workspace) {
          throw 'L\'espace de travail n\'existe pas';
        }

        if (!invitation) {
          throw 'L\'invitation n\'existe pas';
        }

        return this.httpClient.put(`${environment.backend.url}user/workspace/invitation/api/`, {
          id: invitation.id,
          email_sender: invitation.invited_by,
          approval: 'd',
          email: email
        }).pipe(
          map(response => this._parseInvitation(response))
        ).toPromise();
      }).catch(error => {
        throw error;
      })
  }

  sendInvitation(email: string, from: string, workspaceID: string) {
    const data = {
      email: email,
      email_sender: from,
      invitation_type: 'w',
      meta_data: {
        workspace_id: workspaceID
      }
    };

    return this.httpClient.post(`${environment.backend.url}user/workspace/invitation/`, data).pipe(
      map(invitation => {
        return invitation as WorkspaceInvitation;
      }),
      catchError((response: HttpErrorResponse) => {
        if (response.error['detail']) {
          throw response.error['detail']
        }

        throw "Une erreur est survenue. Veuillez réessayer."
      })
    ).toPromise();
  }

  private _parseInvitation(data: any): WorkspaceInvitation {
    return {
      id: data.id,
      short_description: data.short_description,
      approval: data.approval,
      invited_by: data.email_sender,
      meta: {
        workspace_id: data?.meta_data?.workspace_id
      }
    }
  }
}
