import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Workspace } from 'src/app/@auth/models/workspace/workspace';
import { Transaction } from 'src/app/models/transaction/transaction';
import { environment } from 'src/environments/environment';

interface BankAccountInfo{
  account_id: string;
  name: string;
  official_name: string;
  balance: number;
  currency: string;
  bic: string;
  iban: string
}

@Injectable({
  providedIn: 'root'
})

export class PlaidService {

  constructor(private http: HttpClient) { }

  getBanks() {
    return this.http.get(`${environment.backend.url}banking/bank/`).pipe(
      map((banks: any[]) => banks.map(bank => {
        console.log(bank);
        return bank;
      })),
      catchError(error => {
        throw this.handleError(error);
      })
    ).toPromise();
  }

  getBankAccountInfo(workspaceID: string): Promise<BankAccountInfo>{
    return this.http.get(`${environment.backend.url}banking/get_bank_info/${workspaceID}/`).pipe(
      map((response: any) => {
        const accounts = response.accounts;

        if(!accounts || accounts.length == 0){
          return null;
        }

        const account = accounts[0];
        const numbers = response.numbers;

        return {
          account_id: account.account_id,
          name: account.name,
          official_name: account.offical_name,
          balance: account.balances.current,
          currency: account.balances.iso_currency_code,
          bic: numbers.international[0].bic,
          iban: numbers.international[0].iban,
        };
      }),
      catchError((error: HttpErrorResponse) => {
        if(error.message){
          throw error.message
        }

        throw 'Une erreur est survenue. Veuillez réessayer.'
      })
    ).toPromise();
  }

  createLinkToken(workspaceMemberID: string) {
    return this.http.get(`${environment.backend.url}banking/get_link_token/${workspaceMemberID}/`).pipe(
      catchError(error => {
        throw this.handleError(error)
      }
      )).toPromise();
  }

  getAccessToken(publicToken: string, workspaceID: string) {
    return this.http.post(`${environment.backend.url}banking/get_access_token/`, {
      public_token: publicToken,
      workspace: workspaceID
    }).pipe(
      catchError(error => {
        throw this.handleError(error);
      })
    ).toPromise();
  }

  getAccountTransactions(workspace: string, period: string = '3') {
    return this.http.get(`${environment.backend.url}banking/get_account_transaction/${workspace}/${period}/`).pipe(
      map(response => {
        if(!response['transactions']){
          return;
        }

        const transactions = response['transactions'];

        return transactions.map(transaction => {
          const operation = new Transaction();
          operation.short_description = transaction.name;
          operation.amount = transaction.amount;
          operation.operation_date = transaction.date;
          operation.transaction_id = transaction.transaction_id;

          return operation;
        });
      }),
      catchError(error => {
        throw this.handleError(error);
      })
    ).toPromise();
  }

  deleteAccount(workspaceID: string){
    return this.http.delete(`${environment.backend.url}banking/delete/${workspaceID}/`).pipe(
      catchError(error => this.handleError(error))
    ).toPromise()
  }

  private handleError(response: HttpErrorResponse) {
    switch (response.status) {
      case 400:
        if(response.error['detail']){
          return `Une erreur est survenue (${response.error['detail']}`;
        }

        return `Une erreur est survenue.`;
      case 405:
        return 'Vous n\'êtes pas authorisé à effectuer cette action';
      default:
        return `Une erreur est survenue.`;
    }
  }
}
