import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { GenerateCalendarEventComponent } from 'src/app/components/generate-calendar-event/generate-calendar-event.component';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { CalendarEventService } from 'src/app/services/calendar-event/calendar-event.service';

@Component({
  selector: 'app-dashboard-events',
  templateUrl: './dashboard-events.component.html',
  styleUrls: ['./dashboard-events.component.scss']
})

export class DashboardEventsComponent implements OnInit {
  events: CalendarEvent[] = [];

  constructor(private calendar: CalendarEventService, private dialog: NbDialogService, private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if(!workspace){
        return;
      }
      
      this.calendar.getTenantEvents(workspace.id).then(
        events => this.events = events
      )
    })
  }

  addEvent(event: CalendarEvent) {
    this.dialog.open(GenerateCalendarEventComponent, {
      context: {
        title: event.title,
        description: event.description,
        startDate: event.startDate as Date,
        link: event.link
      },
      autoFocus: false
    });
  }
}
