import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export interface DialogInputData {
  question: string;
  answer: string;
}

@Component({
  selector: 'app-dialog-input',
  templateUrl: './dialog-input.component.html',
  styleUrls: ['./dialog-input.component.scss']
})
export class DialogInputComponent implements OnInit {
  @Input() title: string;
  @Input() question: string;
  @Input() placeholder: string;

  answer: string;

  constructor(private dialog: NbDialogRef<DialogInputData>) { }

  ngOnInit(): void {
  }

  onCancel(): void{
    this.dialog.close();
  }

  onSubmit(): void{
    if(!this.answer){
      return;
    }
    
    this.dialog.close(this.answer);
  }

}
