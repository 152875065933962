import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { RealtySelectionComponent } from 'src/app/components/realty-selection/realty-selection.component';
import { Property } from 'src/app/models/property/property';
import { PropertyService } from 'src/app/services/property/property.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';

@Component({
  selector: 'app-property-assets',
  templateUrl: './property-assets.component.html',
  styleUrls: ['./property-assets.component.scss']
})

export class PropertyAssetsComponent implements OnInit {
  loading: boolean;
  keywords: string;
  columns: TableColumn[] = [
    { name: 'short_description', label: 'Description courte', type: 'string' },
    { name: 'address', label: 'Adresse', type: 'string' },
    { name: 'property_type', label: 'Type de bien', type: 'choice', choices: new ChoiceList().getChoices('realty', 'property_type')  },
    { name: 'monthly_income', label: 'Revenus menusel', type: 'amount'  }
  ]

  records: Property[] = [];

  actions: FormAction[];

  constructor(private db: PropertyService, private modal: NbDialogService, private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if(!workspace){
        this.records = [];
        return;
      }

      this.loading = true;
      this.db.getProperties(workspace.id).then(properties => {
        this.records = properties;
      }).then(() =>
        this.actions = [
          {
            name: 'new',
            label: 'Ajouter un bien',
            type: 'script',
            primary: true,
            onClick: () => {
              this.modal.open(RealtySelectionComponent);
            }
          }
        ]
      ).finally(() => this.loading = false);
    })
  }
}
