import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../../models/user/user';
import { WorkspaceInvitation } from '../../models/workspace-invitation/workspace-invitation';
import { AuthenticationService } from '../authentication/authentication.service';
import { WorkspaceService } from '../workspace/workspace.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient, private auth: AuthenticationService) {

  }

  getUsers(workspaceID: string): Promise<User[]> {
    return this.httpClient.get<User[]>(
      `${environment.backend.url}/workspace/${workspaceID}/users/`
    ).pipe(
      catchError(error => {
        console.warn(error);
        throw error['detail'] || 'Erreur lors de chargement des utilisateurs';
      })
    ).toPromise();
  }

  getUserByEmail(email: string): Promise<User> {
    return this.httpClient.get<User>(`${environment.backend.url}user/${email}/`).toPromise();
  }

  getUser(id: string): Promise<User> {
    return this.httpClient.get<User>(`${environment.backend.url}user/${id}/`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 404) {
          throw 'Not found';
        }

        throw 'Une erreur est survenue.';
      })
    ).toPromise().then(async (user:User) => {
        const profile = await this.auth.getProfile(user.email);
        user.phone = profile.phone;

        return user;
      }
    );
  }
}
