import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { Document } from 'src/app/models/document/document';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
})

export class InvoicesComponent implements OnInit {
  title = "Factures";
  loading: boolean;
  keywords: string;
  columns: TableColumn[];

  records: Document[] = [];

  actions: FormAction[] = [
    {
      name: 'new', label: 'Nouvelle facture', link: '/document/-1', type: 'link', primary: true,
      queryParams: {
        sysparm_default: 'category=b'
      }
    }
  ];

  constructor(private db: DocumentService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        this.loading = false;
        return;
      }

      this.columns = [
        {
          label: 'Nom du document',
          name: 'name',
          type: 'string',
        },
        {
          label: 'Nº facture',
          name: 'invoice_number',
          type: 'string'
        },
        {
          label: 'Memo',
          name: 'memo',
          type: 'string'
        },
        {
          label: 'Montant TTC',
          name: 'amount_incl_tax',
          type: 'amount'
        },
        {
          label: 'Statut',
          name: 'invoice_state',
          type: 'choice',
          choices: new ChoiceList().getChoices('document', 'status'),
        },
        {
          label: 'Entreprise',
          name: 'company',
          type: 'reference',
          url: `workspace/${workspace.id}/company/`,
          reference: 'company',
          displayValue: 'name'
        },
        {
          label: 'Bien immobilier',
          name: 'realty',
          type: 'reference',
          url: `workspace/${workspace.id}/realty/`,
          reference: 'realty',
          displayValue: 'short_description'
        },
        {
          label: 'Immeuble',
          name: 'realty_group',
          type: 'reference',
          url: `workspace/${workspace.id}/realtygroup/`,
          reference: 'realty_group',
          displayValue: 'short_description'
        },
        {
          label: 'Date d\'échéance',
          name: 'invoice_due_date',
          type: 'date'
        },
      ]

      this.loading = true;
      this.db.getInvoices(workspace.id)
        .then((records: Document[]) => {
          this.records = records;
        })
        .finally(() => this.loading = false);
    })
  }
}
