import { Component, Input, OnInit } from '@angular/core';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-split-transaction',
  templateUrl: './split-transaction.component.html',
  styleUrls: ['./split-transaction.component.scss']
})
export class SplitTransactionComponent implements OnInit {
  @Input() table: string;
  @Input() id: string;

  transactions: Transaction[];
  source: Transaction;
  loading: boolean;

  constructor(private transaction: TransactionService) { }

  ngOnInit(): void {
    this.loading = true;
    this.transaction.getTransaction(this.id).then(
      record => this.source = record
    ).finally(() => this.loading = false);
  }

  initialize(){
    let transaction = new Transaction();
    transaction.short_description = this.source.short_description;
    transaction.amount = this.source.amount;
    transaction.operation_date = this.source.operation_date;

    this.transactions.push(transaction);
  }

  getBalance(): number{
    return 0;
  }
}
