import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { environment } from 'src/environments/environment';
import { PlaidService } from '../../services/plaid/plaid.service';

@Component({
  selector: 'app-sync-account',
  templateUrl: './sync-account.component.html',
  styleUrls: ['./sync-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SyncAccountComponent implements OnInit {
  loading: boolean;
  response: any;
  token: any;
  workspaceID: string;
  accessToken: string;
  environment = environment.plaid.environment;
  syncRequired: boolean;

  constructor(private workspace: WorkspaceService,
    private plaid: PlaidService,
    private dialog: NbDialogRef<SyncAccountComponent>,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (workspace) {
        this.workspaceID = workspace.id;

        if(workspace.plaid_access_token){
          this.syncRequired = workspace.plaid_access_token.sync_required;
        }

        this.loading = true;
        this.response = this.plaid.createLinkToken(workspace.id).catch(
          error => {
            this.dialog.close(null);
            this.notification.snack(error, 'danger')
          }
        ).finally(() => this.loading = false);
      }
    })
  }

  onSuccess(response) {
    this.token = response;

    if(response['token']){
      this.plaid.getAccessToken(response['token'], this.workspaceID).then(
        result => {
          this.notification.snack('Compte bancaire synchronisé')
          this.dialog.close(result);
        }
      ).catch(error => {
        this.notification.snack(error, 'danger')
      })
    }
  }

  onExit($event) {
    // this.dialog.close(null)
  }

  onLoad($event) {
    // console.log($event);
  }

  onEvent($event) {
    // console.log($event);
  }

  onClick($event) {
    // console.log($event);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }

}
