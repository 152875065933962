export interface ChoiceOption {
    table?: string;
    field?: string;
    value: string | number;
    label: string;
    dependantValue?: string;
    order?: number;
    icon?: string;
    subcategories?: { value: string, label: string, order?: number, icon?: string; }[]
}

export class ChoiceList {
    readonly choices: ChoiceOption[] = [
        { table: 'deposit', field: 'status', value: 'w', label: 'En attente de paiement' },
        { table: 'deposit', field: 'status', value: 'r', label: 'Paiement reçu' },
        { table: 'deposit', field: 'status', value: 'g', label: 'Caution remboursé' },
        { table: 'realty', field: 'property_type', value: 'a', label: 'Appartement' },
        // { table: 'realty', field: 'property_type', value: 'c', label: 'Local commercial' },
        { table: 'realty', field: 'property_type', value: 'h', label: 'Maison' },
        { table: 'realty', field: 'property_type', value: 'p', label: 'Garage / Parking' },
        // { table: 'realty', field: 'property_type', value: 'g', label: 'Dépendance' },
        // { table: 'realty', field: 'property_type', value: 'w', label: 'Entrepot' },
        { table: 'realty', field: 'property_type', value: 'ce', label: 'Cave' },
        { table: 'realty', field: 'type_of_habitat', value: 'cb', label: 'Immeuble collectif' },
        { table: 'realty', field: 'type_of_habitat', value: 'ib', label: 'Immeuble individuel' },
        { table: 'realty', field: 'rental_type', value: 's', label: 'Colocation', dependantValue: 'h,a' },
        // { table: 'realty', field: 'rental_type', value: 'h', label: 'Location saisonnière' },
        { table: 'realty', field: 'rental_type', value: 'r', label: 'Location longue durée' },
        // { table: 'realty', field: 'rental_type', value: 'c', label: 'Bail commercial' },
        { table: 'realty', field: 'heating_energy', value: 'g', label: 'Chauffage au gaz' },
        { table: 'realty', field: 'heating_energy', value: 'e', label: 'Chauffage électrique' },
        { table: 'realty', field: 'heating_energy', value: 'o', label: 'Chauffage au fuel' },
        { table: 'realty', field: 'heating_energy', value: 'w', label: 'Chauffage au bois' },
        { table: 'realty', field: 'heating_energy', value: 's', label: 'Chauffage panneaux solaires' },
        { table: 'realty', field: 'heating_type', value: 'c', label: 'Chauffage central' },
        { table: 'realty', field: 'heating_type', value: 'i', label: 'Chauffage individuel' },
        { table: 'realty', field: 'type_of_furniture', value: 'f', label: 'Meublé' },
        { table: 'realty', field: 'type_of_furniture', value: 's', label: 'Semi-meublé' },
        { table: 'realty', field: 'type_of_furniture', value: 'u', label: 'Non-meublé' },
        { table: 'realty', field: 'construction_period', value: 'b1949', label: 'Avant 1949', order: 5 },
        { table: 'realty', field: 'construction_period', value: '1949-1974', label: 'De 1949 à 1974', order: 4 },
        { table: 'realty', field: 'construction_period', value: '1975-1989', label: 'De 1975 à 1989', order: 3 },
        { table: 'realty', field: 'construction_period', value: '1989-2005', label: 'De 1989 à 2005', order: 2 },
        { table: 'realty', field: 'construction_period', value: 's2005', label: 'Après 2005', order: 1 },
        { table: 'realty', field: 'use_of_premises', value: 'l', label: 'Usage d\'habitation '},
        { table: 'realty', field: 'use_of_premises', value: 'p', label: 'Usage mixte professionnel et d\'habitation'},
        { table: 'realty', field: 'nb_main_room', value: 'T1', label: 'T1', order: 1 },
        { table: 'realty', field: 'nb_main_room', value: 'T1b', label: 'T1 Bis', order: 2 },
        { table: 'realty', field: 'nb_main_room', value: 'T2', label: 'T2', order: 3 },
        { table: 'realty', field: 'nb_main_room', value: 'T2b', label: 'T2 Bis', order: 4 },
        { table: 'realty', field: 'nb_main_room', value: 'T3', label: 'T3', order: 5 },
        { table: 'realty', field: 'nb_main_room', value: 'T3', label: 'T3 Bis', order: 6 },
        { table: 'realty', field: 'nb_main_room', value: 'T4', label: 'T4', order: 6 },
        { table: 'realty', field: 'nb_main_room', value: 'T4b', label: 'T4 Bis', order: 7 },
        { table: 'realty', field: 'nb_main_room', value: 'T5', label: 'T5', order: 7 },
        { table: 'realty', field: 'nb_main_room', value: 'T5b', label: 'T5 Bis', order: 8 },
        { table: 'realty', field: 'nb_main_room', value: 'T6', label: 'T6', order: 9 },
        { table: 'realty', field: 'nb_main_room', value: 'T6b', label: 'T6 Bis', order: 10 },
        { table: 'realty', field: 'nb_main_room', value: 'T6p', label: 'T6+', order: 11 },
        { table: 'realty', field: 'legal_system', value: 'mp', label: 'Mono propriété' },
        { table: 'realty', field: 'legal_system', value: 'cp', label: 'Copropriété' },
        { table: 'realty', field: 'amenities', value: 't', label: 'Terrasse' },
        { table: 'realty', field: 'amenities', value: 'b', label: 'Balcon' },
        { table: 'realty', field: 'amenities', value: 'i', label: 'Fibre' },
        { table: 'realty', field: 'amenities', value: 'k', label: 'Cuisine équipée' },
        { table: 'realty', field: 'amenities', value: 'a', label: 'Grenier' },
        { table: 'realty', field: 'amenities', value: 'b', label: 'Cave' },
        { table: 'realty', field: 'amenities', value: 'p', label: 'Place de parking' },
        {
            table: 'document', field: 'category', value: 'tenant', label: 'Locataire', order: 0, subcategories: [
                { value: 'rc', label: 'Contrat de location', icon: 'account_box' },
                { value: 'i', label: 'État des lieux', icon: 'account_box' },
                { value: 'sb', label: 'Acte de cautionnement', icon: 'account_box' },
                { value: 'ir', label: 'Attestation d\'assurance', icon: 'account_box' },
                { value: 'tr', label: 'Quittance', icon: 'account_box' }
            ]
        },
        {
            table: 'document', field: 'category', value: 'realty', label: 'Immeuble / appartement', order: 1, subcategories: [
                { value: 'q', label: 'Devis', icon: 'receipt' },
                { value: 'b', label: 'Facture', icon: 'receipt' },
                { value: 'd', label: 'Diagnostique', icon: 'receipt' },
            ]
        },
        {
            table: 'document', field: 'category', value: 'other', label: 'Divers', order: 1, subcategories: [
                { value: 'o', label: 'Autre', icon: 'receipt' },
            ]
        },
        { table: 'document', field: 'status', value: 'tp', label: 'A payer', dependantValue: 'i' },
        { table: 'document', field: 'status', value: 'p', label: 'Payée', dependantValue: 'i' },
        { table: 'transaction', field: 'category', value: 'r', label: 'Loyer', order: 0 },
        { table: 'transaction', field: 'category', value: 'd', label: 'Caution', order: 1 },
        { table: 'transaction', field: 'category', value: 'b', label: 'Facture', order: 2 },
        { table: 'transaction', field: 'category', value: 'e', label: 'Frais divers (banque, assurance, etc.)', order: 3 },
        { table: 'transaction', field: 'category', value: 'l', label: 'Emprunt bancaire', order: 4 },
        { table: 'transaction', field: 'category', value: 't', label: 'Impôts / Taxes', order: 5 },
        { table: 'transaction', field: 'category', value: 'c', label: 'Charges (copropriété, eau, etc.)', order: 6 },
        { table: 'transaction', field: 'category', value: 'o', label: 'Autre', order: 7 },
        { table: 'tenant', field: 'rent_paiement_option', value: 'v', label: 'Virement' },
        { table: 'tenant', field: 'rent_paiement_option', value: 'c', label: 'Chèque' },
        { table: 'tenant', field: 'rent_paiement_option', value: 'm', label: 'Espèce' },
        { table: 'tenant', field: 'rental_type', value: 's', label: 'Colocation' },
        { table: 'tenant', field: 'rental_type', value: 'h', label: 'Location saisonnière' },
        { table: 'tenant', field: 'rental_type', value: 'r', label: 'Location longue durée' },
        { table: 'tenant', field: 'contract_state', value: 'cc', label: 'Contrat à créer', order: 0 },
        { table: 'tenant', field: 'contract_state', value: 'st', label: 'En attente signature locataire', order: 1 },
        { table: 'tenant', field: 'contract_state', value: 'sl', label: 'En attente signature propriétaire', order: 2 },
        { table: 'tenant', field: 'contract_state', value: 'cs', label: 'Contrat signé', order: 3 },
        { table: 'tenant', field: 'contract_state', value: 'ce', label: 'Contrat expiré', order: 4 },
        { table: 'tenant', field: 'furnished', value: 'yes', label: 'Oui', order: 1 },
        { table: 'tenant', field: 'furnished', value: 'no', label: 'Non', order: 2 },
        { table: 'tenant', field: 'mobility_lease', value: 'yes', label: 'Oui', order: 1 },
        { table: 'tenant', field: 'mobility_lease', value: 'no', label: 'Non', order: 2 },
        { table: 'tenant', field: 'tense_area', value: 'yes', label: 'Oui', order: 1 },
        { table: 'tenant', field: 'tense_area', value: 'no', label: 'Non', order: 2 },
        { table: 'tenant', field: 'max_rent_evolution', value: 'yes', label: 'Oui', order: 1 },
        { table: 'tenant', field: 'max_rent_evolution', value: 'no', label: 'Non', order: 2 },
        { table: 'tenant', field: 'max_rent_legal', value: 'yes', label: 'Oui', order: 1 },
        { table: 'tenant', field: 'max_rent_legal', value: 'no', label: 'Non', order: 2 },
        { table: 'tenant', field: 'irl', value: '130,52', label: '4e trimestre 2020', order: 1 },
        { table: 'tenant', field: 'irl', value: '130,26', label: '3e trimestre 2020', order: 2 },
        { table: 'tenant', field: 'irl', value: '130,59', label: '2e trimestre 2020', order: 3 },
        { table: 'tenant', field: 'irl', value: '129,99', label: '1e trimestre 2020', order: 4 },
        { table: 'tenant', field: 'irl', value: '130,57', label: '4e trimestre 2019', order: 5 },
        { table: 'tenant', field: 'irl', value: '129,72', label: '3e trimestre 2019', order: 6 },
        { table: 'tenant', field: 'irl', value: '130,57', label: '2e trimestre 2019', order: 7 },
        { table: 'tenant', field: 'irl', value: '129,38', label: '1e trimestre 2019', order: 8 },
        { table: 'tenant', field: 'deposit', value: 1, label: '1 mois de loyer hors-charges' },
        { table: 'tenant', field: 'deposit', value: 2, label: '2 mois de loyer hors-charges' },
        { table: 'template', field: 'category', value: 'tr', label: 'Quittance' },
        { table: 'template', field: 'category', value: 'sb', label: 'Acte de cautionnement' },
        { table: 'template', field: 'category', value: 'rc', label: 'Contrat de location' },
        { table: 'template', field: 'category', value: 'o', label: 'Autre' },
        { table: 'workspace', field: 'plan', value: 'f', label: 'Free' },
        { table: 'workspace', field: 'plan', value: 'p', label: 'Premium' },
        { table: 'workspace', field: 'plan', value: 'g', label: 'Gold' },
        { table: 'reminder', field: 'frequency', value: 'daily', label: 'Quotidienne' },
        { table: 'reminder', field: 'frequency', value: 'weekly', label: 'Hebdomadaire' },
        { table: 'reminder', field: 'frequency', value: 'monthly', label: 'Mensuelle' },
        { table: 'reminder', field: 'frequency', value: 'yearly', label: 'Annuelle' },
        { table: 'workspace-member', field: 'role', value: 'a', label: 'Administrateur' },
        { table: 'workspace-member', field: 'role', value: 'o', label: 'Propriétaire' },
        { table: 'balance-line', field: 'status', value: 'pp', label: 'En attente du paiement', order: 1 },
        { table: 'balance-line', field: 'status', value: 'a', label: 'Paiement reçu', order: 2},
        { table: 'balance-line', field: 'status', value: 'pr', label: 'Paiement remboursé', order: 3 },
        { table: 'email', field: 'status', value: 's', label: 'Envoyé', order: 1 },
        { table: 'email', field: 'status', value: 'e', label: 'Erreur', order: 2 },
    ]

    getChoice(table: string, field: string, value: string): { value: string | number, label: string, order?: number, icon?: string } {
        const choices = this.choices.filter(choice => choice.table === table && choice.field === field);

        const choice = choices.find(choice => choice.value === value)

        if (choice) {
            return choice;
        }

        const choiceList = choices.find(
            choice => choice.subcategories?.find(subchoice => subchoice.value == value)
        );

        if (choiceList?.subcategories) {
            return choiceList.subcategories.find(subchoice => subchoice.value == value);
        }

        return { value: value, label: value };
    }

    getIcon(table: string, field: string, value: string): string {
        const choices = this.choices.filter(choice => choice.table === table && choice.field === field);

        const choice = choices.find(choice => choice.value === value)

        if (choice) {
            return choice.icon;
        }

        const choiceList = choices.find(
            choice => choice.subcategories?.find(subchoice => subchoice.value == value)
        );

        if (choiceList?.subcategories) {
            return choiceList.subcategories.find(subchoice => subchoice.value == value).icon;
        }

        return null;
    }

    getChoices(table: string, field: string) {
        return this.choices.filter(choice => choice.table === table && choice.field === field).sort((a, b) => {
            if (a.order === undefined || b.order === undefined) {
                return this._sortBy(a.label, b.label)
            }

            return this._sortBy(a.order, b.order);

        });
    }

    _sortBy(x: any, y: any) {
        if (x < y) {
            return -1;
        }

        if (x > y) {
            return 1;
        }

        return 0;
    }
}
