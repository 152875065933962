import { Record } from '../@core/models/record/record';

export class DocumentTemplate{
    id?: string;
    name: string;
    category: string;
    content: string;
    standard: boolean;
    workspace: string;
    table: string;

    constructor(){
        this.table = 'template';
    }
}
