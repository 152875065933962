import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { Guarantor } from 'src/app/models/guarantor';
import { TenantService } from 'src/app/services/tenant/tenant.service';

@Component({
  selector: 'app-guarantor',
  templateUrl: './guarantor.component.html',
  styleUrls: ['./guarantor.component.scss']
})
export class GuarantorComponent implements OnInit {
  options: FormOptions = {
    title: {
      default: 'Nouveau garant',
      prefix: 'Garant',
      fields: 'first_name,last_name'
    },
    page: '/locataire/garant',
    redirectTo: '/locataires'
  };
  sections: FormSection[];
  actions: FormAction[];
  record: any;
  loading: boolean;
  error: string;
  space: string;

  constructor(private db: TenantService, private router: ActivatedRoute, private workspace: WorkspaceService) {
  }

  ngOnInit(): void {
    this.loading = true;

    this._loadSections().then(() => this.router.paramMap.subscribe(params => {
      const id = params.get('id');
      this.record = new Guarantor();

      if (id !== '-1') {
        this.db.getGuarantor(id).then((data: Guarantor) => {
          this.record = data;
        }).catch(exception => {
          this.error = exception;
        }).finally(() => this.loading = false);
      } else {
        this.loading = false;
      }
    }))
  }

  private _loadSections(): Promise<FormSection[]> {
    return new Promise((resolve, reject) => {
      const workspace = this.workspace.current.getValue().id;
      this.sections = [
        {
          columns: [
            {
              elements: [
                { field: 'first_name', label: 'Prénom', type: 'string', isRequired: true },
                { field: 'last_name', label: 'Nom', type: 'string', isRequired: true },
                { field: 'address', label: 'Adresse', type: 'location', isRequired: true }
              ]
            },
            {
              elements: [
                { field: 'email', label: 'Email', type: 'email', isRequired: true },
                { field: 'phone', label: 'Téléphone', type: 'phone', isRequired: true },
                { field: 'date_of_birth', label: 'Date de naissance', type: 'date', isRequired: true },
                { field: 'place_of_birth', label: 'Lieu de naissance', type: 'location', isRequired: true },
                {
                  field: 'tenant',
                  label: 'Locataire',
                  type: 'reference',
                  page: 'locataire',
                  url: `workspace/${workspace}/tenant/`,
                  reference: 'tenant',
                  displayValue: 'first_name,last_name',
                  isRequired: true
                }
              ]
            },
          ]
        }
      ]

      resolve(this.sections);
    })
  }
}
