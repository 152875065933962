import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { EmailService } from 'src/app/@core/services/email/email.service';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-incident-form',
  templateUrl: './incident-form.component.html',
  styleUrls: ['./incident-form.component.scss']
})
export class IncidentFormComponent implements OnInit {
  incident: any = { description: ''};
  message: string;

  constructor(private auth: AuthenticationService,
     private email: EmailService,
      private dialog: NbDialogRef<IncidentFormComponent>,
      private snackbar: UINotificationService) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.message = 'Envoi en cours...';
    this.email.reportIncident(
    `User : ${this.auth.user.getValue().email}<br/>
     Description : ${this.incident.description}
    `).then(
      () => {
        this.message = 'Votre incident a bien été envoyé. Nous vous en remercions.';
        setTimeout(() => {
          this.dialog.close();
        }, 1500);
      }
    ).catch(error => this.snackbar.snack(error, 'danger'));
  }
}
