import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { DateUtil } from 'src/app/@core/util/date-util';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { Transaction } from 'src/app/models/transaction/transaction';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TransactionService {

  constructor(private db: DatabaseService,
    private workspace: WorkspaceService,
    private http: HttpClient) {
  }

  getTransaction(id: string): Promise<Transaction> {
    return this.db.get<Transaction>('transaction', id);
  }

  getTransactions(workspaceID: string): Promise<Transaction[]> {
    return this.db.getCollection<Transaction>(`workspace/${workspaceID}/transaction/`, 'transaction').then(
      transactions => transactions.sort((x, y) => {
        if (x.operation_date < y.operation_date) {
          return 1;
        }

        if (x.operation_date > y.operation_date) {
          return -1;
        }

        return 0;
      }).map(transaction => {
        if (transaction.operation_date) {
          transaction.operation_date = new DateUtil().setDate(transaction.operation_date.toString());
        }

        return transaction;
      })
    ).catch(error => {
      console.warn(error);
      throw 'Une erreur est survenue. Veuillez réessayer';
    })
  }

  getTransactionByInvoices(workspaceID: string, invoiceNumber?: string, realty?: string, company?: string): Promise<Transaction[]> {
    return this.getTransactions(workspaceID).then(
      records => records.filter((transaction: Transaction) => {
        let result = true;

        if(invoiceNumber){
          result = transaction.short_description.indexOf(invoiceNumber) > -1 && result;
        }

        if(realty){
          result = result || (transaction.realty_group == realty || transaction.realty == realty);
        }

        if(company){
          result = result || (transaction.company == company);
        }

        return result;
      })
    )
  }

  getTransactionByDocument(workspaceID: string, document: string): Promise<Transaction[]> {
    return this.getTransactions(workspaceID).then(
      records => records.filter((transaction: Transaction) => transaction.document == document).sort(
        (a, b) => {
          if(a.operation_date < b.operation_date){
            return -1;
          }

          if(a.operation_date > b.operation_date){
            return 1;
          }

          return 0;
        }
      )
    )
  }

  getTransactionByProperty(workspaceID: string, realty: string): Promise<Transaction[]> {
    return this.getTransactions(workspaceID).then(
      records => records.filter(record => record.realty === realty)
    );
  }

  getTransactionByTenant(workspaceID: string, tenant: string): Promise<Transaction[]> {
    return this.getTransactions(workspaceID).then(
      records => records.filter(record => record.tenant === tenant)
    );
  }

  getTransactionByRent(workspaceID: string, rent: string): Promise<Transaction[]> {
    return this.getTransactions(workspaceID).then(
      records => records.filter(record => record.rent === rent)
    );
  }

  createTransaction(transaction: Transaction): Promise<Transaction | string> {
    return this.db.save<Transaction>(transaction).catch(
      error => {
        console.warn(error);
        throw error['detail'] || 'Une erreur est survenue. Merci de réessayer.';
      }
    );
  }

  createTransactions(transactions: Transaction[]): Promise<Transaction[]> {
    transactions.map(transaction => {
      transaction.operation_date = new DateUtil().toFormat(transaction.operation_date, 'YYYY-MM-DD');
      transaction.workspace = this.workspace.id;

      delete transaction.id;
      return transaction;
    });

    return this.http.post<Transaction[]>(`${environment.backend.url}transaction/batch/`, transactions).pipe(
      catchError((response: HttpErrorResponse) => {
        if(response.error['detail']){
          throw response.error['detail'];
        }

        throw "Une erreur est survenue. Veuillez réessayer."
      })
    ).toPromise();
  }

  isValidDate(value: any) {
    try {
      return new Date(value);
    } catch (exception) {
      return null;
    }
  }

  importFromExcel(file: File): Promise<Transaction[]> {
    const _formData = new FormData();
    _formData.append('file', file, file.name);
    _formData.append('workspace', this.workspace.id);

    return this.http.post(`${environment.backend.url}import/excel/`, _formData).pipe(
      map(response => {
        const transactions: Transaction[] = [];
        Object.keys(response).forEach(async key => {

          const rows = response[key];
          
          rows.forEach((row, index) => {
            let transaction = transactions[index]

            if (!transaction) {
              transaction = new Transaction();
              transaction.memo = '';
              transactions.push(transaction);
            }

            switch (key.toString()) {
              case 'Libellé':
                transaction.short_description = row;
                break;
              case 'Montant':
                transaction.amount = Math.round(parseFloat(row) * 100) / 100;
                break;
              case 'Date opération':
                if (!this.isValidDate(row)) {
                  break;
                }
                transaction.operation_date = new Date(row);
                break;
              case 'Référence':
                transaction.reference = row.toString();
                break;
              default:
                break;
            }
          })
        })

        return transactions;
      }),
      catchError(response => {
        if (response.error?.detail) {
          throw response.error.detail
        }

        throw 'Une erreur est survenu pendant le chargement du fichier. Veuiller réessayer.'
      })
    ).toPromise();
  }
}
