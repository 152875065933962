import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { environment } from 'src/environments/environment';
import { Email } from '../../models/email';
import { DatabaseService } from '../database/database.service';

@Injectable({
  providedIn: 'root'
})

export class EmailService {
  headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  });

  constructor(private http: HttpClient,
    private workspace: WorkspaceService,
    private auth: AuthenticationService,
    private db: DatabaseService) {

  }

  getEmails(workspaceID: string): Promise<Email[]> {
    return this.db.getCollection<Email>(`email/get_archives/workspace/${workspaceID}/`, 'email').then(
      mails => mails.map(mail => {
        let email = new Email();
        email = { ...mail };
        email.recipient = mail['to'].toString().replace("[", '').replace("]", '');

        return email;
      }).sort((a: Email, b: Email) => {
        if (a.created_on < b.created_on) {
          return 1;
        }

        if (a.created_on > b.created_on) {
          return -1;
        }

        return 0;
      })
    ).catch(error => {
      const message = error['detail'] || 'Une erreur est survenue';

      throw message;
    }
    )
  }

  sendEmail(recipients: { email: string, name: string }[], cc: { email: string, name: string }[] = [], subject: string, body: string) {
    const user = this.auth.user.getValue();

    if (!environment.production) {
      recipients = environment.support;
    }

    let from = { name: 'Liimmo', email: 'contact@liimmo.fr' };

    if (user) {
      from = { email: user?.email, name: `${user.first_name} ${user.last_name}` } || { name: 'Liimmo', email: 'contact@liimmo.fr' }
    }

    const workspace = this.workspace.current.getValue();

    return this.http.post(`${environment.backend.url}email/send_in_blue_email/`, {
      to: recipients,
      cc: '',
      bcc: [from],
      sender: from,
      reply_to: '',
      workspace: workspace.id,
      title: subject,
      body: this._parseHtml(body),
    }, { headers: this.headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error['detail']) {
          throw error['detail']
        }

        throw 'Une erreur est survenue durant l\'envoi de l\'email. Veuillez réessayer.';
      })
    ).toPromise();
  }

  send(recipients: string[], subject: string, body: string, attachments?: string[]) {
    const support = environment.support.map(recipient => {
      return recipient.email
    });
    
    if (!environment.production) {
      recipients = support;
    }

    const workspace = this.workspace.current.getValue();

    return this.http.post(`${environment.backend.url}email/send_with_attachment_by_ids/`, {
      sender: 'contact@liimmo.com',
      to: recipients,
      bcc: support,
      cc: '',
      reply_to: '',
      workspace: workspace.id,
      title: subject,
      body: this._parseHtml(body),
      ids: attachments || []
    }, { headers: this.headers }).pipe(
      catchError(error => {
        console.warn(error);
        throw error['detail'] || 'Erreur lors de l\'envoi d\'email';
      })
    ).toPromise();
  }

  reportIncident(message) {
    const support = environment.support.map(recipient => {
      return recipient.email
    });

    const workspace = this.workspace.current.getValue();

    return this.http.post(`${environment.backend.url}email/send/`, {
      sender: 'contact@liimmo.com',
      to: support,
      bcc: null,
      title: 'Liimmo BETA - Bug',
      body: this._parseHtml(message),
      workspace: workspace.id
    }, { headers: this.headers }).toPromise();
  }

  private _parseHtml(content: string) {
    const regExp = /[\n]+/g;
    return `<html><head></head><body>${content.replace(regExp, '<br/>')}</body></html>`;
  }

  applyTemplate(title: string, subtitle: string, body: string): string {
    return `<!DOCTYPE html>
    <html lang="en">
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Liimmo</title>
            <link rel="preconnect" href="https://fonts.gstatic.com"> 

        <style>
        @font-face {
          font-family: 'Raleway'; font-style: normal; font-weight: 400; font-display: swap; src: url('https://fonts.gstatic.com/s/raleway/v18/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCP.ttf') format('truetype');
        }

        body {
          background-color: #3b9d92;
          padding: 0px;
          line-height: 30px;
        }
    </style>
    </head>
        <body bgcolor="#3b9d92">
            <table style="margin: auto; padding: 0px 20px 20px 20px" bgcolor="#3b9d92" cellpadding="0" cellspacing="0">
                <tbody>
                    <tr>
                      <td align="right" style="padding-top: 50px; padding-bottom:30px; vertical-align: middle" width="40%">
                        <img src="https://liimmo.fr/liimmo-icon.ico" alt="Liimmo" height="80" width="80" style="margin-right: 20px;"/>
                      </td>
                      <td align="left" style="padding-top: 50px; padding-bottom:30px; vertical-align: middle;" width="60%">
                        <div style="color: white; font-size: 36px;">${title || 'Liimmo'}</div>
                        <span style="color: white; font-size: 22px;">Lancement de la plateforme</span>
                      </td>
                    </tr>
                    <tr bgcolor="white">
                        <td style="font-size: 18px; padding:50px;" colspan="2" align="justify">
                          ${body || 'No message'}
                        </td>
                    </tr>
                    <tr>
                      <td align="center" style="padding: 30px 0px 10px 0px; font-size: 16px;" colspan="2">
                        <p>Liimmo, la plateforme de gestion immo</p>
                        © 2020 Liimmo. All rights reserved
                      </td>
                    </tr>
                </tbody>
            </table>
        </body>
      </html>`;
  }
}
