import { Component, Input, OnInit } from '@angular/core';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { Email } from 'src/app/@core/models/email';
import { DateUtil } from 'src/app/@core/util/date-util';
import { CalendarEvent } from 'src/app/models/calendar-event';
import { Tenant } from 'src/app/models/tenant/tenant';
import { CalendarEventService } from 'src/app/services/calendar-event/calendar-event.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reminder-box',
  templateUrl: './reminder-box.component.html',
  styleUrls: ['./reminder-box.component.scss']
})
export class ReminderBoxComponent implements OnInit {
  @Input() id: string;

  message: string;
  reminder: CalendarEvent;
  tenant: Tenant;

  constructor(private tenantUtil: TenantService, private calendar: CalendarEventService) { }

  ngOnInit(): void {
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();

    this.tenantUtil.getTenant(this.id).then(
      record => this.tenant = record
    ).then(
      () => {
        startDate = new DateUtil().set(today.getFullYear(), today.getMonth(), this.tenant.rent_due_date);
        startDate.setHours(8);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        endDate = new DateUtil().set(today.getFullYear(), today.getMonth(), this.tenant.rent_due_date);;
        endDate.setHours(9);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
      }
    ).then().then(
      () => this.reminder = {
        title: `Vérifier loyer ${this.tenant.first_name} ${this.tenant.last_name}`,
        description: `<p>Rappel pour vérifier que le loyer de ${this.tenant.first_name} ${this.tenant.last_name} a bien été payé.</p>
                      <p>Pour accéder au solde tout compte, veuillez cliquer sur le lien ci-dessous :</p>
                      <a href="${environment.frontend.url}locataires/solde?locataire=${this.id}">Solde tout compte</a>`,
        link: `${environment.frontend.url}locataires/solde?locataire=${this.id}`,
        startDate: new DateUtil().toFormat(startDate, 'YYYY-MM-DD HH:mm:ss'),
        endDate: new DateUtil().toFormat(endDate, 'YYYY-MM-DD HH:mm:ss'),
        frequency: new ChoiceList().getChoice('reminder', 'frequency', 'monthly')?.label
      }
    ).catch(error => console.warn(error));
  }

  onDownload() {
    this.message = 'Veuillez patienter...';
    this.calendar.downloadEvent(this.reminder).then(
      () => this.message = null
    ).catch(
      error => {
        console.warn(error);
        this.message = 'Une erreur est survenue. Veuillez réessayer.';
      }
    );
  }

  onSend(){
    this.message = 'Envoi en cours...';
    const email = new Email();
    email.recipient = this.tenant.email;
    email.title = 'Rappel de validation de loyer';
    email.body = 'Bonjour, vous trouverez en pièce jointe un rappel à ajouter à votre calendrier.';

    this.calendar.sendEvent(email, this.reminder).then(
      () => this.message = null
    ).catch(
      error => {
        console.warn(error);
        this.message = 'Erreur lors de l\'envoi. Veuillez réessayer.';
      }
    );
  }
}
