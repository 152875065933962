import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EmailService } from 'src/app/@core/services/email/email.service';
import { PdfGeneratorService } from 'src/app/@core/services/pdf-generator/pdf-generator.service';
import { environment } from 'src/environments/environment';

interface Quill {
  getModule(moduleName: string);
}

interface BetterTableModule {
  insertTable(rows: number, columns: number): void;
}

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HtmlEditorComponent {
  blurred = false;
  focused = false;
  options = environment.tiny.config;

  title = 'Contrat de location';
  preview: string;
  output = `<div>Bonjour &agrave; toutes et &agrave; tous,</div>
  <div>&nbsp;</div>
  <div style="text-align: justify;">Nous sommes extr&ecirc;mement fiers, apr&egrave;s plusieurs longs mois de travail de vous inviter &agrave; essayer&nbsp;en avant-premi&egrave;re notre application de gestion de patrimoine immobilier, &nbsp;<span style="background-color: #ffffff; color: #3b9d92;"><strong>Liimmo</strong></span> ! Il ne s&rsquo;agit l&agrave; que d&rsquo;une <strong>version b&ecirc;ta</strong>, ce qui signifie&nbsp;qu&rsquo;elle&nbsp;est tr&egrave;s loin d&rsquo;&ecirc;tre compl&egrave;te!</div>
  <div>&nbsp;</div>
  <div style="text-align: justify;">En revanche, soyez s&ucirc;r.es que la s&eacute;curit&eacute; et l&rsquo;anonymat de vos donn&eacute;es sont bien respect&eacute;.es et&nbsp;l&rsquo;acc&egrave;s est totalement gratuit pour vous, mais aussi toutes les personnes qui s&rsquo;inscriront&nbsp;durant la phase de test. Enfin, &agrave; la sortie officielle de l&rsquo;application vous b&eacute;n&eacute;ficierez d&rsquo;une&nbsp;p&eacute;riode&nbsp;d'avantages non n&eacute;gligeables pour votre soutien et aide.</div>
  <h3><span style="color: #3b9d92;">Quelles sont les fonctionnalit&eacute;s disponibles dans cette version ?</span></h3>
  <div>Dans cette premi&egrave;re version, vous aurez la possibilit&eacute; de :</div>
  <ul>
  <li>Avoir une <strong>vue d'ensemble de votre patrimoine</strong></li>
  <li>Suivi de vos locataires, notamment le <strong>suivi des loyers</strong> et l'envoie de quittances automatiques.</li>
  <li>Avoir acc&egrave;s &agrave; l&rsquo;&eacute;diteur de texte en ligne, g&eacute;n&eacute;rer vos PDF directement depuis le site et&nbsp;les envoyer par email.</li>
  <li>Avoir un aper&ccedil;u de votre <strong>cash-flow</strong></li>
  <li>Enregistrer et <strong>organiser les documents</strong> li&eacute;s &agrave; la gestion immobiliere, que ce soit les contrats de locations, quittances ou encore les devis, factures, etc.</li>
  <li>Acc&eacute;der &agrave; votre <strong>carnet de contact</strong>, o&ugrave; vous retrouverez automatiquement les contacts de vos&nbsp;locataires.&nbsp;Vous avez &eacute;galement la possibilit&eacute; d'enregistrer les coordonn&eacute;es de vos artisans, comptables&nbsp;et&nbsp;autre entreprises afin de les retrouver plus facilement.</li>
  </ul>
  <p>Dans un second temps, nous ajouterons les fonctionnalit&eacute;s suivantes, une &agrave; une :</p>
  <ul>
  <li>Cr&eacute;ation d'un formulaire pas &agrave; pas pour enregistrer les nouvelles candidatures.</li>
  <li>Mise en place d'un tableau de bord financier, afin d'avoir un suivi d&eacute;taill&eacute; du rendement de&nbsp;votre patrimoine.</li>
  <li>Importer vos donn&eacute;es bancaires via un ficher Excel pour vos donn&eacute;es existantes.</li>
  <li>Synchronisation de vos compte bancaires de mani&egrave;re automatique gr&acirc;ce &agrave; l'aggr&eacute;gateur&nbsp;bancaire.</li>
  </ul>
  <p style="text-align: justify;">Afin que cette phase de test soit r&eacute;ussi, nous ferons un entretien avec vous afin de recevoir vos&nbsp;retours, et apporter des &eacute;volutions correspondant &agrave; vos attentes. Vous pourrez &eacute;galement nous&nbsp;contacter directement depuis la plateforme pour nous remonter les &eacute;ventuelles anomalies.</p>
  <h3><span style="color: #3b9d92;">Est-ce que je peux en parler autour de moi?</span></h3>
  <div>Bien &eacute;vidememnt que oui. M&ecirc;me s'il s'agit d'une version Beta, tous les retours d'utilisateurs&nbsp;sont les bienvenues.&nbsp;En parall&egrave;le de ce lancement, nous mettrons &agrave; jour r&eacute;guli&egrave;rement notre site web <span style="text-decoration: underline;"><a href="https://liimmo.fr" target="_blank" rel="noopener"><span style="color: #3b9d92; text-decoration: underline;">https://liimmo.fr</span></a></span> ainsi que la section &laquo; Aide &raquo;.</div>
  <div>&nbsp;</div>
  <div>Nous vous remercions pour votre soutien et aide dans ce superbe projet qu&rsquo;est Liimmo.</div>
  <div>&nbsp;</div>
  <p style="text-align: center;"><a style="text-align: center; color: white; background-color: #3b9d92; padding: 10px 20px; border: 1px solid #3b9d92; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px; text-decoration: none;" href="https://app.liimmo.fr">Test Liimmo</a></p>
  <div>&nbsp;</div>
  <div>Immobili&egrave;rement v&ocirc;tre, vos d&eacute;veloppeurs pr&eacute;f&eacute;r&eacute;s,</div>
  <div>Alexandre RASPAUD et Paul GALMOT</div>`;

  constructor(private http: HttpClient, private pdfGenerator: PdfGeneratorService, private mailbox: EmailService) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  applyTemplate() {
    this.preview = this.mailbox.applyTemplate('liimmo', 'Lancement de l\'application', this.output);
  }

  removeTemplate() {
    this.preview = null;
  }

  sendNotification() {
    this.mailbox.send(['paul.galmot@gmail.com'], 'Lancement de la plateforme', this.output);
  }

  exportTmp() {
    this.pdfGenerator.generate(this.title, this.output);
  }
}
