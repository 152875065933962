import { Profile } from '../profile/profile';
import { Workspace } from '../workspace/workspace';

export class User {
    public id?: string;
    public name: string;
    public first_name: string;
    public last_name: string;
    public profile: Profile;
    public address?: string;
    public phone: string;
    public company: string;
    public company_role: string;
    public workspaces: Workspace[];

    constructor(
        public email: string,
        private _token: string,
        private _tokenExpirationDate: Date
    ) {
    }

    get token() {
        if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
            return null;
        }
        return this._token;
    }

    get username(){
        return `${this.first_name} ${this.last_name.toLocaleUpperCase()}`; 
    }
}
