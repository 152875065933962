import { Record } from 'src/app/@core/models/record/record';

export class Document extends Record {
    name: string;
    category: string;
    subcategory?: string;
    realty_group?: string;
    realty?: string;
    tenant?: string;
    status?: string;
    payment_date?: Date;
    invoice_number?: string;
    invoice_due_date?: Date;
    amount_excl_tax?: number;
    tax_rate?: number;
    amount_incl_tax?: number;
    quotation_number?: string;
    deposit_amount?: number;
    deposit_rate?: number;
    company: string;
    template: string;
    content: string;

    constructor(id?: string){
        super('document', id);
    }
}
