import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { Document } from 'src/app/models/document/document';
import { DocumentService } from 'src/app/services/document/document.service';

@Component({
  selector: 'app-dashboard-import-documents',
  templateUrl: './import-documents.component.html',
  styleUrls: ['./import-documents.component.scss']
})
export class ImportDocumentsComponent implements OnInit {
  documents: Document[];

  constructor(private documentService: DocumentService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.documents = [];
        return;
      }

      this.documentService.getDocuments(workspace.id).then(
        documents => {
          this.documents = documents.map(document => {
            const category = new ChoiceList().getChoice('document', 'category', document.category);

            if(category){
              document.category = category.label;
              document['icon'] = category.icon;
            }else{
              document['icon'] = 'description';
            }

            return document;
          }).sort((x: Document, y: Document) => {
            if (x.created_on < y.created_on) {
              return -1;
            }

            if (x.created_on < y.created_on) {
              return 1;
            }

            return 0;
          }
          ).map(document => {
            const category = new ChoiceList().getChoice('document', 'category', document.category);

            if(category){
              document.category = category.label;
            }

            return document;
          }).slice(0, 4)
        }
      )
    })
  }
}
