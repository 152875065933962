import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-room',
  templateUrl: './form-room.component.html',
  styleUrls: ['./form-room.component.scss']
})
export class FormRoomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

}
