import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { shareReplay } from 'rxjs/operators';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { User } from 'src/app/@auth/models/user/user';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { MenuService } from '../../services/menu/menu.service';
import { LeftMenu } from '../../models/left-menu';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { isTypeNode } from 'typescript';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit {
  isAuthenticated = false;
  loading: boolean;
  user: User;
  isCompact: boolean = true;
  userSub: Subscription;
  menu: LeftMenu;

  constructor(public workspace: WorkspaceService,
    public auth: AuthenticationService,
    public menuService: MenuService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private sidebarService: NbSidebarService,
    private db: DatabaseService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (workspace) {
        this.menu = new LeftMenu();
        this.menuService.getItems(workspace.id).then(
          items => {
            this.menu.items = items;
            this.isCompact = this.menu.compact == 'compacted';
          }
        ).catch(error => console.warn(error)
        ).finally(() => this.menu.loading = false);

        this.router.events.subscribe(event => {
          if(event instanceof NavigationEnd){
            const path = event.url;

            this.menu.items.map(item => {
              if(`/${item.link}` == path){
                item.selected = true;
              }else{
                if(item.children){
                  item.children.map(child => {
                    child.selected = `/${child.link}` == path;
                  });
                }else{
                  item.selected = false;
                }
              }
            })
          }
        })

        this.db.events.subscribe(event => {
          if (!event) {
            return;
          }

          if (event.table === 'realty' || event.table === 'realtygroup') {
            this.menuService.getItems(workspace.id).then(
              items => {
                this.menu.items = items;
                this.isCompact = this.menu.compact == 'compacted';
              }
            );
          }
        });

        this.sidebarService.onToggle().subscribe(state => {
          if(state.compact){
            this.isCompact = true;
            localStorage.setItem('liimmo.sidebar.compact', 'compacted');
          }else{
            this.isCompact = false;
            localStorage.removeItem('liimmo.sidebar.compact');
          }
        })
      }
    });

    this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
      .pipe(
        map(result => {
          return result.matches;
        }),
        shareReplay()
      ).subscribe(isHandset => this.menuService.toggle());
  }

  search() {
    this.menu.items.map(item => {
      if (this.menu.search !== '') {
        item.children?.map(child => {
          if (child.children) {
            child.children.map(subitem => {
              subitem.hidden = subitem.title.toLocaleLowerCase().indexOf(this.menu.search.toLocaleLowerCase()) === -1;
              subitem.selected = !subitem.hidden;
            });

            child.hidden = child.children.find(subitem => !subitem.hidden) ? false : true;
            child.expanded = !child.hidden;
          } else {
            child.hidden = child.title.toLocaleLowerCase().indexOf(this.menu.search.toLocaleLowerCase()) === -1;
            child.expanded = !child.hidden;
            child.selected = !child.hidden;
          }
        });

        item.expanded = item.children?.find(child => !child.hidden) ? true : false;
      } else {
        item.children?.map(child => {
          if (child.children) {
            child.children.map(subitem => {
              subitem.hidden = false;
              subitem.selected = false;
            });
          }

          child.hidden = false;
          child.expanded = false;
          child.selected = false;
        });

        item.expanded = false;
        item.hidden = false;
      }

      return item;
    });
  }
}
