import { Record } from '../@core/models/record/record';
import { Property } from './property/property';
import { RealtyGroup } from './realty-group';
import { TenantBalanceLine } from './tenant-balance-line';
import { Tenant } from './tenant/tenant';
import { Transaction } from './transaction/transaction';

export class Rent extends TenantBalanceLine {
    workspace: string;
    status: 'pr' | 'a';
    short_description: string;
    amount_due: number;
    amount_paid: number;
    monthly_rent: number;
    monthly_charges: number;
    monthly_rent_with_charges: number;
    transaction: Transaction | string;
    realty: Property | string;
    start_date: Date | string;
    end_date: Date | string;
    days: number;
    month: number;
    year: number;
    date: Date | string;

    constructor(id?: string){
        super('rent', id);
    }

}
