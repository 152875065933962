import { Component, OnInit } from '@angular/core';
import { Tenant } from 'src/app/models/tenant/tenant';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { NbDialogService } from '@nebular/theme';
import { DocumentService } from 'src/app/services/document/document.service';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { FormButton } from 'src/app/@core/models/form-button';
import { ReminderBoxComponent } from 'src/app/components/reminder-box/reminder-box.component';
import { PropertyService } from 'src/app/services/property/property.service';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { RoomService } from 'src/app/services/room/room.service';
import { UpdateRentComponent } from 'src/app/components/update-rent/update-rent.component';
import { environment } from 'src/environments/environment';
import { DateUtil } from 'src/app/@core/util/date-util';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { EmailService } from 'src/app/@core/services/email/email.service';

@Component({
  selector: 'app-tenant',
  templateUrl: './tenant.component.html',
  styleUrls: ['./tenant.component.scss']
})

export class TenantComponent implements OnInit {
  sections: FormSection[] = [];
  transactions: RelatedList;
  documents: RelatedList;
  guarantors: RelatedList;
  emails: RelatedList;

  table: 'tenant';
  actions: FormAction[];
  buttons: FormButton[];
  loading: boolean;
  record: Tenant;
  error: string;
  options: FormOptions = {
    title: {
      prefix: 'Locataire',
      default: 'Nouveau locataire',
      fields: 'first_name,last_name'
    },
    page: 'locataire',
    redirectTo: 'locataires'
  }

  constructor(
    private location: ActivatedRoute,
    private tenants: TenantService,
    private document: DocumentService,
    private workspace: WorkspaceService,
    private realty: PropertyService,
    private mailbox: EmailService,
    private dialog: NbDialogService,
    private router: Router,
    private notification: UINotificationService,
    private roomService: RoomService) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.loadForm();
  }

  private loadForm() {
    this.workspace.current.subscribe(async workspace => {
      if (!workspace) {
        return;
      }

      await this.loadSections(workspace.id).catch(error => console.warn(error));

      this.location.paramMap.subscribe(params => {
        const id = params.get('id');

        if (id !== '-1') {
          this.tenants.getTenant(id)
            .then(result => this.record = result)
            .then(() => this.loadFormButtons())
            .then(() => this.loadRelatedLists(workspace.id, id))
            .catch(exception => {
              this.error = exception;
            }).finally(() => this.loading = false);
        } else {
          this.record = new Tenant();
          this.loading = false;
        }
      })
    })
  }

  private loadSections(workspaceID): Promise<void> {
    return this.document.getTemplates(workspaceID).then(templates => {
      this.sections = [
        {
          title: 'Locataire',
          displayTitle: true,
          columns: [
            {
              elements: [
                { label: 'Nom', field: 'last_name', type: 'string', isRequired: true },
                { label: 'Prénom', field: 'first_name', type: 'string', isRequired: true },
                { label: 'Date de naissance', field: 'date_of_birth', type: 'date', isRequired: true },
                {
                  label: 'Adresse',
                  field: 'last_address',
                  hint: 'Adresse avant la location',
                  type: 'location',
                  isVisible: () => {
                    return this.record.contract_state == 'cc';
                  },
                  isRequired: () => {
                    return this.record.contract_state == 'cc';
                  }
                }
              ]
            },
            {
              elements: [
                { label: 'Email', field: 'email', type: 'email', isRequired: true },
                { label: 'Téléphone', field: 'phone', type: 'string', isRequired: true },
                { label: 'Lieu de naissance', field: 'place_of_birth', type: 'location', isRequired: true }
              ]
            }
          ]
        },
        {
          displayTitle: false,
          columns: [
            {
              elements: [
                { label: 'A propos du locataire', field: 'about', type: 'textarea' }
              ]
            }
          ]
        },
        {
          title: 'Détails contractuels',
          displayTitle: true,
          columns: [
            {
              elements: [
                {
                  label: 'Status du contrat', field: 'contract_state', type: 'choice',
                  choices: new ChoiceList().getChoices('tenant', 'contract_state'),
                  defaultValue: 'cc',
                  isRequired: true,
                  onChange: () => {
                    if (this.record.contract_state == 'ce') {
                      this.record.active = false;
                    } else {
                      this.record.active = true;
                    }
                  }
                },
                {
                  label: 'Type de location', field: 'rental_type', type: 'choice',
                  choices: new ChoiceList().getChoices('tenant', 'rental_type'),
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  }
                },
                {
                  label: 'Bien immobilier', field: 'realty', type: 'reference',
                  url: `workspace/${workspaceID}/realty/`,
                  reference: 'realty',
                  page: 'bien-immobilier',
                  refQualifier: [
                    { field: 'property_type', operator: '!=', value: 'b' }
                  ],
                  displayValue: 'short_description',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  },
                  onChange: () => {
                    this.realty.getProperty(this.record.realty).then(result => {
                      this.record.monthly_rent = result.monthly_rent;
                      this.record.monthly_charges = result.monthly_charges;
                      this.record.charges_details = result.charges_details;

                      if (result.type_of_furniture == 'f') {
                        this.record.furnished = 'yes';
                      } else {
                        this.record.furnished = 'no';
                      }
                    })
                  }
                },
                {
                  label: 'Chambre', field: 'room', type: 'reference',
                  url: `workspace/${workspaceID}/room/`,
                  reference: 'realty',
                  displayValue: 'short_description',
                  page: 'appartement/chambre',
                  dependantFrom: 'realty',
                  optionHint: 'description',
                  isVisible: (record: Tenant) => {
                    return record.rental_type === 's' && record.realty;
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  },
                  onChange: () => {
                    this.roomService.getRoom(this.record.room).then(room => {
                      this.record.monthly_rent = room.monthly_rent;
                      this.record.monthly_charges = room.monthly_charges;
                    })
                  }
                },
                {
                  label: 'Date de début de contrat',
                  field: 'contract_start_date',
                  type: 'date',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  },
                  onChange: (tenant: Tenant) => {
                    tenant.check_in = tenant.contract_start_date;
                  }
                },
                {
                  label: 'Date d\'état des lieux d\'entrée',
                  field: 'check_in',
                  type: 'date',
                  isRequired: true,
                  hint: 'Date d\'état des lieux d\'entrée du locataire dans le logement',
                },
                {
                  label: 'Durée du bail (en mois)',
                  field: 'contract_duration',
                  type: 'integer',
                  defaultValue: '12',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  },
                  onChange: () => {
                    if (this.record.mobility_lease == 'yes') {
                      if (this.record.contract_duration > 10) {
                        this.record.contract_duration = null;
                        this.notification.alert('La durée du contrat ne peut pas dépasser 10 mois dans le cadre d\'un bail mobilité');
                        return;
                      }

                      const startDate = new Date(this.record.contract_start_date);
                      let endDate = new DateUtil().add(startDate, this.record.contract_duration, 'M');
                      endDate = new DateUtil().add(endDate, -1, 'd');

                      this.record.contract_end_date = endDate;
                    } else {
                      this.record.contract_end_date = null;
                    }
                  }
                },
                {
                  label: 'Dépot de garantie',
                  field: 'deposit_coeff',
                  type: 'choice',
                  choices: new ChoiceList().getChoices('tenant', 'deposit'),
                  isVisible: () => {
                    return this.record.contract_state === 'cc' &&
                      this.record.mobility_lease == 'no';
                  },
                  isRequired: () => {
                    return this.record.contract_state === 'cc' &&
                      this.record.mobility_lease == 'no';
                  },
                },
                {
                  label: 'Jour de paiement du loyer',
                  field: 'rent_due_date',
                  type: 'integer',
                  defaultValue: '1',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  }
                },
                {
                  label: 'Location meublée', field: 'furnished', type: 'choice',
                  choices: new ChoiceList().getChoices('tenant', 'furnished'),
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  },
                  onChange: () => {
                    if (this.record.furnished == 'yes') {
                      this.record.contract_duration = 12;
                      if (this.record.mobility_lease == 'yes') {
                        this.record.deposit_coeff = null;
                      } else {
                        this.record.deposit_coeff = 2;
                      }
                    } else {
                      this.record.contract_duration = 36;
                      this.record.deposit_coeff = 1;
                    }
                  }
                },
                {
                  label: 'Bail mobilité', field: 'mobility_lease', type: 'choice',
                  defaultValue: 'no',
                  choices: new ChoiceList().getChoices('tenant', 'mobility_lease'),
                  isVisible: () => {
                    return this.record.contract_state === 'cc' && this.record.furnished == 'yes';
                  },
                  isRequired: () => {
                    return this.record.contract_state === 'cc' && this.record.furnished == 'yes';
                  },
                  onChange: () => {
                    if (this.record.mobility_lease == 'yes') {
                      this.record.contract_duration = 10;
                      this.record.contract_end_date = new DateUtil().add(this.record.contract_start_date, this.record.contract_duration, 'M');
                      this.record.deposit = null;
                    } else {
                      this.record.contract_duration = 12;

                      if (this.record.furnished == 'yes') {
                        this.record.deposit = 2;
                      } else {
                        this.record.deposit = 1;
                      }
                    }
                  }
                },
                {
                  label: 'IRL', field: 'irl', type: 'choice',
                  choices: new ChoiceList().getChoices('tenant', 'irl'),
                  hint: 'Indice de Référence Loyer',
                  defaultValue: new ChoiceList().getChoices('tenant', 'irl')[0].value,
                  isRequired: () => {
                    return this.record.contract_state === 'cc' && this.record.furnished == 'yes';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  }
                }
              ]
            },
            {
              elements: [
                {
                  label: 'Loyer mensuel', field: 'monthly_rent', type: 'amount',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  }
                },
                {
                  label: 'Charges mensuelles', field: 'monthly_charges', type: 'amount',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  }
                },
                {
                  label: 'Options de paiement du loyer', field: 'rent_paiement_option', type: 'choice',
                  choices: new ChoiceList().getChoices('tenant', 'rent_paiement_option'),
                  defaultValue: 'v',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  }
                },
                {
                  label: 'Détails des charges', field: 'charges_details', type: 'textarea',
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isReadOnly: () => {
                    return this.record.contract_state !== 'cc';
                  }
                },
                {
                  label: 'Date de fin de contrat',
                  field: 'contract_end_date',
                  type: 'date',
                  onChange: () => {
                    const endDate = new Date(this.record.contract_end_date.toString());

                    if (new DateUtil().isInthePast(endDate)) {
                      this.record.contract_state = 'ce';
                      this.record.active = false;
                    } else {
                      this.record.contract_state = 'cs';
                      this.record.active = true;
                    }
                  }
                },
                {
                  label: 'Date d\'état des lieux de sortie',
                  field: 'checkout',
                  type: 'date',
                  hint: 'Date d\état des lieux de sortie du locataire dans le logement',
                  isVisible: () => {
                    return this.record.contract_state === 'cs' || this.record.contract_state === 'ce';
                  }
                },
                {
                  label: 'Modèle de quittance', field: 'receipt_model', type: 'choice',
                  defaultValue: templates.find(template => template.category == 'r')?.id,
                  hint: 'Laisser le champ vide si vous ne souhaitez pas envoyer de quittance automatiquement',
                  choices: templates.filter(template => template.category == 'tr').map(template => {
                    return {
                      value: template.id,
                      label: template.name
                    }
                  }),
                  isVisible: () => {
                    return this.record.contract_state === 'cs' || this.record.contract_state === 'ce';;
                  }
                },
                {
                  label: 'Logement en zone tendue',
                  field: 'tense_area',
                  type: 'choice',
                  defaultValue: 'no',
                  choices: new ChoiceList().getChoices('tenant', 'tense_area'),
                  isRequired: () => {
                    return this.record.contract_state === 'cc';
                  },
                  isVisible: () => {
                    return this.record.contract_state === 'cc';
                  }
                },
                {
                  label: 'Le loyer du logement objet du présent contrat est soumis au décret fixant annuellement le montant maximum d\'évolution des loyers à la relocation',
                  field: 'max_rent_evolution',
                  type: 'choice',
                  defaultValue: 'no',
                  choices: new ChoiceList().getChoices('tenant', 'max_rent_evolution'),
                  isVisible: () => {
                    return this.record.contract_state === 'cc' && this.record.tense_area === 'yes';
                  },
                  isRequired: () => {
                    return this.record.contract_state === 'cc' && this.record.tense_area === 'yes';
                  }
                },
                {
                  label: 'Montant du loyer de référence',
                  field: 'ref_rent',
                  type: 'amount',
                  defaultValue: 0,
                  isVisible: () => {
                    return this.record.contract_state === 'cc' && this.record.max_rent_evolution === 'yes'
                  },
                  isRequired: () => {
                    return this.record.contract_state === 'cc' && this.record.max_rent_evolution === 'yes'
                  }
                },
                {
                  label: 'Le loyer du logement objet du présent contrat est soumis au loyer de référence majoré fixé par arrêté préfectoral',
                  field: 'max_rent_legal',
                  type: 'choice',
                  defaultValue: 'no',
                  choices: new ChoiceList().getChoices('tenant', 'max_rent_legal'),
                  isVisible: () => {
                    return this.record.contract_state === 'cc' && this.record.tense_area === 'yes';
                  },
                  isRequired: () => {
                    return this.record.contract_state === 'cc' && this.record.tense_area === 'yes';
                  }
                },
                {
                  label: 'Montant du loyer de majoré / m2',
                  field: 'ref_max_rent',
                  type: 'amount',
                  defaultValue: 0,
                  isVisible: () => {
                    return this.record.contract_state === 'cc' && this.record.max_rent_legal === 'yes'
                  },
                  isRequired: () => {
                    return this.record.contract_state === 'cc' && this.record.max_rent_legal === 'yes'
                  }
                },
              ]
            }
          ]
        }
      ]
    });
  }

  private loadRelatedLists(workspaceID: string, id: string) {
    return Promise.all([
      this.tenants.getTransactionsByTenant(workspaceID, id),
      this.document.getDocumentsByTenant(workspaceID, id),
      this.tenants.getGuarantorsByTenant(id),
      this.mailbox.getEmails(workspaceID)
    ]).then(result => {
      this.transactions = {
        records: result[0],
        name: 'transactions',
        title: `Transactions (${result[0].length})`,
        actions: [
          {
            name: 'new_transaction', label: 'Nouvelle transaction', primary: true, type: 'link', link: '/transaction/-1', queryParams: {
              sysparm_default: `tenant=${this.record.id}`,
              syparm_redirect: `locataire/${id}`
            }
          }
        ],
        columns: [
          { label: 'Libellé', name: 'short_description', type: 'string' },
          { label: 'Montant', name: 'amount', type: 'amount' },
          { label: 'Date', name: 'operation_date', type: 'date' },
          { label: 'Catégorie', name: 'category', type: 'choice', choices: new ChoiceList().getChoices('transaction', 'cateogry') }
        ]
      };

      this.guarantors = {
        records: result[2],
        name: 'guarantors',
        columns: [
          { label: 'Prénom', name: 'first_name', type: 'string' },
          { label: 'Nom', name: 'last_name', type: 'string' },
          { label: 'Email', name: 'email', type: 'email' },
          { label: 'Téléphone', name: 'phone', type: 'phone' },
        ],
        actions: [
          {
            name: 'new_guarantor',
            label: 'Nouveau garant',
            type: 'link',
            primary: true,
            link: '/locataire/garant/-1',
            queryParams: {
              sysparm_default: `tenant=${this.record.id}`,
              syparm_redirect: `locataire/${id}`
            }
          }
        ]
      }

      this.documents = {
        records: result[1],
        name: 'documents',
        actions: [
          {
            name: 'new_document', label: 'Nouveau document', primary: true, type: 'link', link: '/document/-1', queryParams: {
              sysparm_redirect: `locataire/${id}`
            }
          }
        ],
        columns: [
          { label: 'Nom du document', name: 'name', type: 'string' },
          { label: 'Catégorie', name: 'category', type: 'choice', choices: new ChoiceList().getChoices('document', 'cateogry') }
        ]
      };

      this.emails = {
        columns: [
          { name: 'title', label: 'Sujet', type: 'string' },
          { name: 'created_on', label: 'Date d\'envoi', type: 'date' },
        ],
        name: 'tenant_emails',
        title: 'Email',
        sortBy: { field: 'created_on', direction: 'desc' },
        // records: result[3].filter(email => email.recipient?.indexOf(this.record.email) > -1)
        records: result[3]
      }
    }).finally(() => this.loading = false);
  }

  private loadFormButtons() {
    const balance = this.record?.balance || 0;
    this.buttons = [
      {
        name: 'generate_contrat',
        label: 'Créer le contrat',
        color: 'primary',
        onClick: () => {
          let template = environment.templates.contract_unfurnished;

          if (this.record.mobility_lease == 'yes') {
            template = environment.templates.contract_mobility_lease;
          } else if (this.record.furnished == 'yes') {
            template = environment.templates.contract_furnished;
          }

          this.router.navigate(['/document-pdf'], {
            queryParams: {
              tenant: this.record?.id,
              realty: this.record.realty,
              template: template
            }
          })
        },
        isVisible: () => {
          return this.record.contract_state == 'cc';
        },
      },
      {
        name: 'balance',
        label: `Solde : ${balance}€`,
        color: balance < 0 ? 'warn' : 'primary',
        isVisible: () => {
          return this.record.contract_state == 'cs' || this.record.contract_state == 'ce';
        },
        onClick: () => {
          this.router.navigate([`solde-locataire`], {
            queryParams: {
              locataire: this.record.id
            }
          });
        }
      }
    ];
    this.actions = [
      {
        name: 'create_tenant_receipt',
        label: 'Créer une quittance',
        type: 'script',
        onClick: () => {
          this.router.navigate(['/document-pdf'], {
            queryParams: {
              tenant: this.record?.id,
              template: this.record.receipt_model
            }
          })
        },
        isVisible: () => {
          return this.record.contract_state == 'cs';
        },
      },
      {
        name: 'review_rent', label: 'Réviser le loyer', type: 'script',
        isVisible: () => {
          return this.record.contract_state == 'cs';
        },
        onClick: () => {
          const dialog = this.dialog.open(UpdateRentComponent, {
            context: {
              id: this.record.id
            }
          });

          dialog.onClose.subscribe(result => {
            this.record = result;
            this.loadForm();
          })
        }
      },
      {
        name: 'create_reminder', label: 'Créer un rappel', type: 'script',
        onClick: () => {
          this.dialog.open(ReminderBoxComponent, {
            context: {
              id: this.record.id
            }
          })
        }
      }
    ]
  }
}
