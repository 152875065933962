import { ThrowStmt } from '@angular/compiler';
import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { DateUtil } from 'src/app/@core/util/date-util';
import { TenantBalanceLine } from 'src/app/models/tenant-balance-line';
import { Tenant } from 'src/app/models/tenant/tenant';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-deposit-received',
  templateUrl: './deposit-received.component.html',
  styleUrls: ['./deposit-received.component.scss']
})
export class DepositReceivedComponent implements OnInit {
  @Input() tenantID: string;
  @Input() id: string;

  tenant: Tenant;
  deposit: TenantBalanceLine;
  loading: boolean;

  constructor(private transactionService: TransactionService,
    private tenantService: TenantService,
    private notification: UINotificationService,
    private modal: NbDialogRef<DepositReceivedComponent>) {
  }

  ngOnInit(): void {
    this.loading = true;

    Promise.all([this.tenantService.getTenant(this.tenantID), this.tenantService.getBalance(this.tenantID)]).then(
      result => {
        this.tenant = result[0];
        const balance = result[1];
        this.deposit = balance.lines.find(line => line.category == 'deposit')
        this.loading = false;
      }
    ).catch(error => this.notification.snack(error, 'danger'))
  }

  onClose() {
    this.modal.close();
  }

  onSubmit() {
    this.loading = true;
    let transaction = new Transaction();
    transaction.short_description = this.deposit.short_description;
    transaction.amount = this.deposit.amount_due;
    transaction.operation_date = new DateUtil().toFormat(this.deposit.due_date, 'YYYY-MM-DD');
    transaction.deposit = this.deposit.id;
    transaction.tenant = this.tenantID;
    transaction.category = 'd';

    this.transactionService.createTransaction(transaction).then(
      () => this.modal.close()
    ).catch(error => console.log(error)
    ).finally(() => this.loading = false)
  }

}
