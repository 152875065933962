import { Component, OnInit } from '@angular/core';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { Workspace } from 'src/app/@auth/models/workspace/workspace';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';

@Component({
  selector: 'app-workspaces',
  templateUrl: './workspaces.component.html',
  styleUrls: ['./workspaces.component.scss']
})

export class WorkspacesComponent implements OnInit {
  title: string;
  columns: TableColumn[] = [
    { name: 'short_description', label: 'Nom', type: 'string' },
    { name: 'plan', label: 'Abonnement', type: 'choice', choices: new ChoiceList().getChoices('workspace', 'plan') },
    { name: 'storage_available', label: 'Espace disponible', type: 'percentage' }
  ];
  records: Workspace[];
  actions: FormAction[];
  loading: boolean;

  constructor(private workspace: WorkspaceService, private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.loading = true;
    const user = this.auth.user.getValue();

    this.workspace.getWorkspaces(user).then(
      records => this.records = records
    ).catch(error => console.warn(error)
    ).finally(() => this.loading = false);
  }

}
