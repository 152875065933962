import { Injectable } from '@angular/core';
import { Tenant } from 'src/app/models/tenant/tenant';
import { Contact } from 'src/app/models/contact/contact';
import { TenantService } from '../tenant/tenant.service';
import { PropertyService } from '../property/property.service';
import { CompanyService } from '../company/company.service';
import { Company } from 'src/app/models/company';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private tenant: TenantService, private realty: PropertyService, private company: CompanyService) {

  }

  getContacts(workspaceID: string): Promise<Contact[]> {
    return Promise.all([this.tenant.getTenants(workspaceID), this.company.getCompanies(workspaceID)]).then(results => {
      const tenants = results[0];
      const companies = results[1] || [];
      let contacts: Contact[] = [];

      contacts = tenants.map((tenant: Tenant) => {
        const contact = new Contact(tenant.first_name, tenant.last_name, tenant.email, tenant.phone);
        contact.category = 't';
        contact.id = tenant.id;
        contact.address = tenant.address;

        this.realty.getProperty(tenant.realty).then(realty => {
          if (tenant.active) {
            contact.short_description = 'Locataire à ' + realty.short_description;
            contact.address = realty.address;
          } else {
            contact.short_description = 'Ancien locataire à ' + realty.short_description;
          }
        });

        return contact;
      });

      companies.forEach((company: Company) => {
        const contact = new Contact(company.name, null, company.email, company.phone);
        contact.id = company.id;
        contact.short_description = company.short_description;
        contact.category = 'c';
        contact.website = company.website;
        contact.address = company.address;
        contact.company = `${company.manager} (${company.name})`;
        contact.website = company.website;

        contacts.push(contact);
      });

      return contacts.sort((contactA, contactB) => {
        if (contactA.name < contactB.name) {
          return -1;
        }

        if (contactA.name > contactB.name) {
          return 1;
        }

        return 0;
      });
    });
  }
}
