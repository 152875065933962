import { Injectable } from '@angular/core';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { Room } from 'src/app/models/room/room';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { TenantService } from '../tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class RoomService {

  constructor(private db: DatabaseService, private tenant: TenantService) {
  }

  getRoom(id: string): Promise<Room>{
    return this.db.get<Room>('room', id);
  }

  getRooms(workspaceID: string): Promise<Room[]>{
    return this.db.getCollection<Room>(`/workspace/${workspaceID}/room/`, 'room');
  }

  getRoomsByProperty(workspaceID: string, property: string): Promise<Room[]>{
    return Promise.all([this.getRooms(workspaceID), this.tenant.getTenantsByProperty(workspaceID, property)]).then(
      result => {
        const rooms = result[0];
        const tenants = result[1];
        
        return rooms.filter(room => room.realty == property).map(room => {
          const tenant = tenants.find(tenant => tenant.room == room.id && tenant.active);

          if(tenant){
            room.tenant = tenant.id;
          }

          return room;
        });
      }
    )
  }
}
