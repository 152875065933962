import { Component } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { WorkspaceInvitationsComponent } from './@auth/components/workspace-invitations/workspace-invitations.component';
import { AuthenticationService } from './@auth/services/authentication/authentication.service';
import { WorkspaceInvitationService } from './@auth/services/workspace-invitation/workspace-invitation.service';
import { WorkspaceService } from './@auth/services/workspace/workspace.service';
import { UINotificationService } from './@core/services/ui-notification/ui-notification.service';
import { SyncAccountComponent } from './@finance/components/sync-account/sync-account.component';
import { SubscriptionService } from './@subscription/services/subscription/subscription.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Liimmo';

  constructor(private auth: AuthenticationService,
    private workspace: WorkspaceService,
    private subscription: SubscriptionService,
    private dialog: NbDialogService,
    private workspaceInvitationService: WorkspaceInvitationService,
    private uiNotification: UINotificationService) {
    // this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fas', iconClassPrefix: 'fa' });
  }

  ngOnInit(): void {
    this.auth.user.subscribe(user => {
      if (user) {
        this.workspace.intialize(user).then(
          workspaces => {
            if(workspaces.length > 0){
              this.checkInvitations(user.email);
            }
          }
        ).catch(error => console.warn(error));
      }
    });

    this.workspace.current.subscribe((workspace) => {
      if (!workspace) {
        return;
      }

      this.subscription.initialize();

      if(workspace.plaid_access_token){
        const plaid = workspace.plaid_access_token;

        if(plaid.sync_required){
          this.dialog.open(SyncAccountComponent, {
            autoFocus: false
          })
        }
      }
    })
  }

  checkInvitations(email: string){
    this.workspaceInvitationService.getInvitations(email).then(invitations => {
      const requests = invitations.filter(invitation => invitation?.approval == 'r');

      if(requests.length > 0){
        this.dialog.open(WorkspaceInvitationsComponent, {
          context: {
            email: email,
            invitations : requests
          }
        })
      }
    }).catch(error => this.uiNotification.snack(error, 'danger'))
  }
}