import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Workspace } from '../../models/workspace/workspace';
import { WorkspaceMember } from '../../models/workspace-member/workspace-member';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { User } from '../../models/user/user';
import { WorkspaceInvitation } from '../../models/workspace-invitation/workspace-invitation';
import { WorkspaceInitalizationStatusComponent } from '../../components/workspace-initalization-status/workspace-initalization-status.component';

export class FinancialPerformance {
  gross: { expected: number, actual: number };
  net: { expected: number, actual: number };
  cash_flow: number
}

@Injectable({
  providedIn: 'root'
})

export class WorkspaceService {
  initialization: BehaviorSubject<{ type: 'success' | 'danger', message: string }>;
  current: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);

  constructor(private http: HttpClient) {
  }

  get subscription() {
    const workspace = this.current.getValue();

    if (workspace) {
      return workspace.subscription;
    }

    return null;
  }

  selectWorkspace(workspace: Workspace) {
    if (workspace) {
      this.current.next(workspace);
    }
  }

  intialize(user: User): Promise<Workspace[]> {
    return this.getWorkspaces(user).then(workspaces => {
      const currentWorkspaceID = localStorage.getItem('workspace.current');

      if (currentWorkspaceID) {
        const current = workspaces.find(workspace => workspace.id == currentWorkspaceID)

        if (current) {
          this.current.next(current);
        } else {
          this.current.next(user.workspaces[0]);
        }
      } else {
        this.current.next(user.workspaces[0]);
      }

      return workspaces;
    })
  }

  getWorkspaces(user: User): Promise<Workspace[]> {
    return Promise.all(user.workspaces.map(workspace => {
      return this.http.get(`${environment.backend.url}/workspace/${workspace.id}/`).pipe(
        map((data: any) => {
          let workspace = new Workspace(data['id']);
          const capacity = parseFloat((data['storage_limit']));
          const fileStorage = parseFloat((data['file_storage']));
          const availableStorage = Math.round((capacity - fileStorage) / capacity * 10000) / 100;

          workspace = {
            table: 'workspace',
            storage_files: parseFloat(data['file_counter']),
            storage_file_uploaded: parseFloat(data['file_counter']),
            storage_available: availableStorage,
            ...data
          } as Workspace;

          return workspace;
        }),
        catchError(error => {
          console.warn(error);
          throw error;
        })
      ).toPromise();
    }));
  }

  getWorkspace(id: string): Promise<Workspace> {
    return this.http.get(`${environment.backend.url}workspace/${id}/`).toPromise().then(
      (data: any) => {
        let workspace = new Workspace(id);
        const capacity = parseFloat((data['storage_limit']));
        const fileStorage = parseFloat((data['file_storage']));
        const availableStorage = Math.round((capacity - fileStorage) / capacity * 10000) / 100;
        let plaid = null;

        if(data.plaid_access_token){
          plaid = {
            id: data.plaid_access_token.id,
            item_id: data.plaid_access_token.item_id,
            access_token: data.plaid_access_token.access_token,
          }
        }

        workspace = {
          table: 'workspace',
          id: id,
          storage_files: parseFloat(data['file_counter']),
          storage_file_uploaded: parseFloat(data['file_counter']),
          storage_available: availableStorage,
          plaid: plaid,
          ...data
        } as Workspace;
        
        return workspace;
      }
    ).catch(error => {
      console.warn(error);

      throw 'Une erreur est survenue.'
    });
  }

  togglePlaidSync(workspace: Workspace){
    workspace.webhook_enabled = !workspace.webhook_enabled;

    return this.http.put(`${environment.backend.url}workspace/api/`, workspace).toPromise().then(
      (workspace: Workspace) => {
        this.current.next(workspace);
        return workspace
      }
    );
  }

  getMembers(workspaceID: string): Promise<WorkspaceMember[]> {
    const workspace = this.http.get<Workspace>(`${environment.backend.url}workspace/${workspaceID}/`).toPromise();
    const users = this.http.get<User[]>(`${environment.backend.url}workspace/${workspaceID}/users/`).toPromise();

    return Promise.all([workspace, users]).then(result => {
      const _members = result[0]['workspace_members'];
      const _users = result[1];

      return _members.map(member => {
        member.table = 'workspace-member';
        const user = _users.find(user => member.user === user.id);

        if (user) {
          member.name = `${user.first_name} ${user.last_name}`;
          member.email = user.email;
        }

        return member;
      });
    });
  }

  getMember(workspace: string, id: string): Promise<WorkspaceMember> {
    return this.getMembers(workspace).then(
      members => {
        return members.find(member => member.id === id)
      }
    );
  }

  getMemberByEmail(workspace: string, email: string): Promise<WorkspaceMember> {
    return this.getMembers(workspace).then(
      members => members.find(member => member.email === email)
    );
  }

  createWorkspace(name: string): Promise<Workspace> {
    return this.http.post(`${environment.backend.url}workspace/api/`, {
      short_description: name,
    }).pipe(
      map(record => {
        return { ...record } as Workspace;
      }),
      catchError((error) => throwError(error))
    ).toPromise();
  }

  createMember(user: string, workspace: string, role: string): Promise<WorkspaceMember | string> {
    return this.http.post(`${environment.backend.url}workspace/member/api/`, {
      workspace: workspace,
      user: user,
      role: role
    }).pipe(
      map((record: WorkspaceMember) => {
        return { ...record } as WorkspaceMember;
      }),
      catchError(response => this.handleError(response))
    ).toPromise();
  }

  getInvitation(id: string): Promise<WorkspaceInvitation> {
    return this.http.get(`${environment.backend.url}user/workspace/invitation/${id}/`).pipe(
      map(invitation => {
        return invitation as WorkspaceInvitation;
      }),
      catchError(error => {
        console.log(error);
        throw 'Aucune invitation trouvé';
      })
    ).toPromise();
  }

  getPerformancePerWorkspace(workspaceID: string):
    Promise<FinancialPerformance> {
    return Promise.all([
      this.getGrossPerformancePerWorkspace(workspaceID),
      this.getNetPerformancePerWorkspace(workspaceID),
      this.getCashFlowPerWorkspace(workspaceID)
    ]).then(result => {
      return {
        gross: result[0].gross_efficiency,
        net: result[1].net_efficiency,
        cash_flow: result[2].cash_flow
      } as FinancialPerformance
    }).catch(error => {
      throw 'Une erreur est survenue.'
    })
  }


  getCashFlowPerWorkspace(workspaceID: string): Promise<any> {
    return this.http.get<number>(`${environment.backend.url}workspace/${workspaceID}/cash_flow/`).pipe(
      catchError((error) => {
        console.warn(error);
        throw 'Erreur lors du calcul du rendement brut de l\'espace de travail';
      })
    ).toPromise();
  }

  getNetPerformancePerWorkspace(workspaceID: string): Promise<any> {
    return this.http.get<number>(`${environment.backend.url}workspace/${workspaceID}/net_efficiency/`).pipe(
      catchError((error) => {
        console.warn(error);
        throw 'Erreur lors du calcul du rendement net de l\'espace de travail';
      })
    ).toPromise();
  }

  getGrossPerformancePerWorkspace(workspaceID: string): Promise<any> {
    return this.http.get<number>(`${environment.backend.url}workspace/${workspaceID}/gross_efficiency/`).pipe(
      catchError((error) => {
        console.warn(error);
        throw 'Erreur lors du calcul du cashflow de l\'espace de travail';
      })
    ).toPromise();
  }

  private handleError(response: HttpErrorResponse): Observable<string> {
    try {
      let errorMessage = 'An unknown error occurred!';

      if (!response.error) {
        return throwError(errorMessage);
      }

      const error = response.error.error;

      switch (error) {
        case 'invalid_grant':
          errorMessage = 'Identifiants incorrects';
          break;
        case 'User with this Email address already exists.':
          errorMessage = 'This email is already used.';
          break;
        case 'INVALID_PASSWORD':
          errorMessage = 'This password is not correct.';
          break;
        default:
          errorMessage = response.error;
      }

      return throwError(errorMessage);
    } catch (exception) {
      return throwError('Unknow error');
    }
  }

  get id(): string {
    const workspace = this.current.getValue();

    if (!workspace) {
      return null;
    }

    return workspace.id;
  }
}
