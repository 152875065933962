import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbContextMenuModule } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { environment } from 'src/environments/environment';
import { Product } from '../../models/product';
import { StripeBillingInfo } from '../../models/stripe-billing-info';
import { StripeCustomer } from '../../models/stripe-customer';
import { StripeSubscription } from '../../models/stripe-subscription';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {
  current: BehaviorSubject<StripeSubscription> = new BehaviorSubject(null);
  free = {
    features: [
      { short_description: '1 bien immobilier', description: 'Vous pouvez n\'enregistrer qu\'un seul bien immobilier.' },
      { short_description: 'Nombre de locataire illimité', description: 'Vous pouvez enregistrer autant de locataires que vous le souhaitez.' },
      { short_description: 'Création de contrats de location standards', description: 'Vous pouvez créer des documents à partir de modèles de document standards.' },
      { short_description: 'Envoi de quittance', description: 'Vous pouvez créer des documents à partir de modèles de document standards.' },
      { short_description: 'Import excel d\'opérations bancaires', description: 'Vous pouvez importer des opérations bancaires via un fichier Excel.' },
      { short_description: 'Espace de stockage 150 Mo', description: 'Vous disposez d\'un espace de stockage de 50 Mo pour stocker vos documents' },
      { short_description: '1 espace de travail', description: 'Vous disposez d\'un espace de travail' },
      { short_description: '2 membres / espace de travail', description: 'Vous pouvez avoir jusqu\'à 2 personnes sur votre espace de travail' },
    ]
  };
  pro = {
    features: [
      { short_description: 'Nombre de bien(s) illimité(s)', description: 'Vous pouvez enregistrer autant de biens que vous le souhaitez.' },
      { short_description: 'Nombre de locataire(s) illimité(s)', description: 'Vous pouvez enregistrer autant de locataires que vous le souhaitez.' },
      { short_description: 'Création de contrats de location personalisables', description: 'Vous pouvez créer des documents à partir de modèles de document standards.' },
      { short_description: 'Envoi de quittance automatique', description: 'Vous pouvez créer des documents à partir de modèles de document standards.' },
      { short_description: 'Synchronisation bancaire', description: 'Vous pouvez lié votre compte en banque et importer automatiquement vos opérations bancaires dans votre espace de travail.' },
      { short_description: 'Documents personnalisables', description: 'Vous pouvez créer vos propres modèles de documents.' },
      { short_description: 'Espace de stockage 2Go', description: 'Vous pouvez enregistré tous vos documents dans votre espace de travail.' },
      { short_description: 'Tableaux de bord', description: 'Visualiser tout votre patrimoine sur votre tableau de bord.' },
      { short_description: 'Nombre d\'espace de travail illimité', description: 'Vous pouvez créer autant d\'un espace de travail que vous le souhaitez.' },
      { short_description: '5 membres / espace de travail', description: 'Vous pouvez avoir jusqu\'à 5 personnes sur votre espace de travail.' },
    ]
  }

  constructor(private http: HttpClient, private workspace: WorkspaceService) {
  }

  initialize() {
    this.getSubscription().then(
      subscription => this.current.next(subscription)
    ).catch(error => {
      this.current.next(null);
      console.warn(error);
    });
  }

  getProducts(): Promise<Product[]> {
    return this.http.get(`${environment.backend.url}stripe/products/`).pipe(
      map((products) => {
        return products['data'].map(product => {

          const prices = product.price.data.map(price => {
            return {
              id: price.id,
              amount: price.unit_amount / 100,
              interval: price.recurring.interval,
              trial_period_days: price.recurring.trial_period_days
            }
          });

          let features = [];

          if(product.name.toLowerCase() == 'gratuit'){
            features = this.free.features;
          }

          if(product.name.toLowerCase() == 'pro'){
            features = this.pro.features;
          }

          return {
            id: product.id,
            name: product.name,
            description: product.description,
            image: product.images[0],
            prices: prices || [],
            features: features
          }
        }).sort((a, b) => {
          if(a.prices[0].amount < b.prices[0].amount){
            return -1;
          }

          if(a.prices[0].amount > b.prices[0].amount){
            return 1;
          }

          return 0;
        })
      }),
      catchError(error => {
        console.warn(error);

        throw error['detail'] || 'Erreur lors de la récupération des prix';
      })).toPromise();
  }

  createCustomer(email: string): Promise<StripeCustomer> {
    return this.http.post(
      `${environment.backend.url}stripe/customer/api/`, {
      email: email,
      workspace: this.workspace.id
    }).pipe(
      map(response => {
        if (!response['customer']) {
          return null;
        }

        const customer = response['customer'];
        let subscription = customer.subscriptions.data
          .filter(subscription => subscription.status == 'active')
          .map(subscription => {
            return {
              ...subscription.id
            };
          })

        if (subscription.length > 1) {
          subscription = subscription[0];
        }

        return {
          email: customer.email,
          id: customer.id,
          subscription: subscription
        } as StripeCustomer;
      }),
      catchError(response => {
        throw this.processError(response)
      })
    ).toPromise();
  }

  getCustomer(): Promise<StripeCustomer> {
    return this.http.get<{ id: string; email: string; subscriptions: any }>(
      `${environment.backend.url}stripe/customer/${this.workspace?.id}/`
    ).pipe(
      map(customer => {
        if (!customer || !customer.subscriptions) {
          return null;
        }

        let subscription = null;
        const subscriptions = customer.subscriptions.data
          .filter(subscription => subscription.status == 'active')
          .map(subscription => {
            const items = subscription.items.data;
            const plan = items[0].plan;

            return {
              id: subscription.id,
              product: plan.product,
              price: plan.id,
              default_payment_method: subscription.default_payment_method,
              amount: plan.amount / 100,
              startDate: new Date(subscription.start_date * 1000)
            };
          })

        if (subscriptions.length >= 1) {
          subscription = subscriptions[0];
        }

        return {
          email: customer.email,
          id: customer.id,
          subscription: subscription,
        } as StripeCustomer;
      }),
      catchError(response => {
        throw this.processError(response)
      })
    ).toPromise();
  }

  getPaymentMethods(workspace: string) {
    return this.http.get<{ data: any[] }>(`${environment.backend.url}stripe/payment_method/${workspace}/`).pipe(
      map(response => response.data.map(method => {
        return {
          id: method.id,
          brand: method.card.brand,
          last4: method.card.last4,
          exp_month: method.card.exp_month,
          exp_year: method.card.exp_year
        }
      })),
      catchError(response => {
        throw this.processError(response)
      })
    ).toPromise()
  }

  createSubscription(email: string, paymentMethodID: string, priceID: string, billingInfo: StripeBillingInfo): Promise<any> {
    return this.http.put(`${environment.backend.url}stripe/subscription/api/`, {
      email: email,
      workspace: this.workspace.id,
      payment_method_id: paymentMethodID,
      price_id: priceID,
      name: billingInfo.name,
      phone: billingInfo.phone,
      city: billingInfo.city,
      country: billingInfo.country,
      line_one: billingInfo.line_one,
      postal_code: billingInfo.postal_code,
    }).pipe(
      catchError(response => {
        throw this.processError(response)
      })
    ).toPromise()
  }

  updateSubscription(subscriptionID: string, priceID: string, workspaceID: string, paymentMethodID: string): Promise<any> {
    return this.http.put(`${environment.backend.url}stripe/subscription/api/`, {
      workspace: workspaceID,
      price_id: priceID,
      subscription_id: subscriptionID,
      payment_id: paymentMethodID
    }).pipe(
      catchError(response => {
        if(response.detail){
          if(response.details.indexOf('This customer has no attached payment source or default payment method.') > -1){
            throw "Payment required";
          }
        }

        throw this.processError(response)
      })
    ).toPromise()
  }

  createFreeSubscription(workspaceID: string){
    return this.http.post(`${environment.backend.url}stripe/free_subscription/`, {
      workspace: workspaceID
    }).toPromise();
  }

  setSubscription(workspace: string, subscription: string, product: string): Promise<any> {
    return this.http.put(`${environment.backend.url}stripe/subscription/api/`, {
      workspace: workspace,
      subscription_id: subscription,
      price_id: product
    }).pipe(
      map(response => {
        return response;
      }),
      catchError(response => {
        throw this.processError(response)
      })
    ).toPromise()
  }

  getSubscription(): Promise<StripeSubscription> {
    return Promise.all([this.getProducts(), this.getCustomer()]).then(
      result => {
        const products = result[0];
        const customer = result[1];

        let subscription = { ...customer?.subscription };

        const product = products.find(product => subscription?.product == product.id);

        if (product) {
          subscription.name = product.name;
        }

        return subscription;
      }).catch(error => {
        throw this.processError(error)
      });
  }

  getInvoices(workspaceID: string, limit: number = 10): Promise<any[]> {
    return this.http.get(
      `${environment.backend.url}stripe/customer_bills/${workspaceID}/${limit}/`
    ).pipe(
      map(response => {
        const data: any[] = response['data'];

        if(!data){
          return null;
        }

        return data.map(receipt => {
          return {
            number: receipt.number,
            total: receipt.total,
            amount: receipt.total / 100,
            currency: 'EUR',
            invoice_pdf: receipt.invoice_pdf,
            status: receipt.status,
            subscription: receipt.subscription,
            customer: receipt.customer_email,
            created: new Date(receipt.created * 1000)
          };
        })
      })
    ).toPromise();
  }

  private processError(response): string {
    try {
      if (!response['error']) {
        return `Une erreur s\'est produite (${response})`;
      }

      const error = response?.error;

      if (!error['detail']) {
        return 'Une erreur s\'est produite';
      }

      const details = error['detail'];

      if (details instanceof Object) {
        throw Object.keys(details).reduce((result, key) => {
          return result + details[key] + '\n';
        }, '');
      }

      return details;
    } catch (error) {
      return error;
    }
  }
}
