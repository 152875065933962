import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList, ChoiceOption } from 'src/app/@core/models/choice-list/choice-list';
import { PdfGeneratorService } from 'src/app/@core/services/pdf-generator/pdf-generator.service';
import { Building } from 'src/app/models/building';
import { Company } from 'src/app/models/company';
import { Document } from 'src/app/models/document/document';
import { Property } from 'src/app/models/property/property';
import { Tenant } from 'src/app/models/tenant/tenant';
import { CompanyService } from 'src/app/services/company/company.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';

@Component({
  selector: 'app-document-import',
  templateUrl: './document-import.component.html',
  styleUrls: ['./document-import.component.scss']
})

export class DocumentImportComponent implements OnInit {
  @Input() file: File;

  error: string;
  document: Document;
  categories: ChoiceOption[] = new ChoiceList().getChoices('document', 'category');
  url: any;
  saving: boolean;
  cancelled: boolean;
  buildings: Promise<Building[]>;
  realties: Promise<Property[]>;
  tenants: Promise<Tenant[]>;
  companies: Promise<Company[]>

  constructor(private util: DocumentService,
              private property: PropertyService,
              private tenant: TenantService,
              private workspaceService: WorkspaceService,
              private companyUtil: CompanyService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if(!workspace){
        return;
      }

      this.document = new Document();
      this.document.name = this.file.name;
      this.realties = this.property.getProperties(workspace.id);
      this.buildings = this.property.getBuildings(workspace.id);
      this.tenants = this.tenant.getActiveTenants(workspace.id);
      this.companies = this.companyUtil.getCompanies(workspace.id);
  
      const reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (event) => {
        this.url = event.target.result;
      }
    })
  }

  save(){
    this.saving = true;
    
    this.util.createDocumentWithAttachment(this.document, this.file).then(
      result => this.document = result
    ).catch(error => {
      console.warn(error);
      this.error = "Une erreur est survenu. Merci de réessayer";
    }
    ).finally(() => this.saving = false);
  }

  cancelImport(){
    this.document = null;
    this.cancelled = true;
  }
}
