import { Component, OnInit, Input, OnChanges, SimpleChange, SimpleChanges, ɵChangeDetectorStatus } from '@angular/core';
import { Attachment } from '../../models/attachment/attachment';
import { NbDialogService } from '@nebular/theme';
import { FormAttachmentsComponent } from '../form-attachments/form-attachments.component';
import { FileStorageService } from '../../services/file-storage/file-storage.service';
import { FormSection } from '../../models/form-section/form-section';
import { FormAction } from '../../models/form-action/form-action';
import { Record } from '../../models/record/record';
import { FormButton } from '../../models/form-button';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessControl } from '../../models/access-control';
import { DatabaseService } from '../../services/database/database.service';

export class FormOptions {
  page?: string;
  redirectTo?: string;
  previousPage?: string;
  title: {
    hidden?: boolean;
    default: string;
    prefix?: string;
    fields: string;
  };

  permissions?: AccessControl;

  constructor() {
    this.permissions = new AccessControl();
  }
}

@Component({
  selector: 'app-form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss']
})

export class FormCardComponent implements OnInit, OnChanges {
  @Input() sections: FormSection[];
  @Input() record: Record;
  @Input() actions: FormAction[];
  @Input() buttons: FormButton[];
  @Input() loading: boolean;
  @Input() options: FormOptions;

  nextRecord: Record;
  previousRecord: Record;

  _options: FormOptions = {
    title: {
      hidden: false,
      default: 'Nouvel enregistrement',
      fields: 'short_description'
    }
  };

  title: string;
  attachments: Attachment[] = [];
  files: File[] = [];
  showUploader = false;
  lastUpdate: Date;
  redirectTo: string;

  constructor(private dialog: NbDialogService,
    private db: DatabaseService,
    private attachment: FileStorageService,
    private location: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.location.queryParams.subscribe(parameters => {
      if (parameters['sysparm_redirect']) {
        this.redirectTo = parameters['sysparm_redirect'];
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {
      this._options = { ...this._options, ...this.options };

      if (!this._options.permissions) {
        this._options.permissions = new AccessControl();
      }

      if (changes.record) {
        this.setTitle();
        if (!this.record?.id || !this.record?.table) {
          return;
        }

        this.lastUpdate = this.record.updated_on;
        this.attachment.getAttachments(this.record.table, this.record.id).then(
          attachments => this.record.attachments = attachments
        ).catch(error => console.warn(error))
      }
    } catch (error) {
      console.warn(error);
    }
  }

  onRedirectTo() {
    this.router.navigateByUrl(this.redirectTo);
  }

  isVisible(button: FormButton): boolean {
    if (!button.isVisible) {
      return true;
    }

    if (button.isVisible instanceof Function) {
      return button.isVisible();
    }

    return button.isVisible;
  }

  onClick(button: FormButton) {
    try {
      button.onClick(this.dialog, this.record);
    } catch (exception) {
      console.warn(exception);
    }
  }

  openAttachmentsWindow() {
    const dialog = this.dialog.open(FormAttachmentsComponent, {
      context: {
        table: this.record.table,
        id: this.record.id,
      },
      closeOnBackdropClick: true,
    });

    dialog.onClose.subscribe(() => {
      this.attachment.getAttachments(this.record.table, this.record.id).then(
        attachments => this.record.attachments = attachments
      ).catch(error => console.warn(error))
    })
  }

  refresh(record) {
    this.lastUpdate = record.updated_on;
    this.setTitle();
  }

  setTitle() {
    try {
      const options = this._options?.title;

      if (!this.record || !this.record.id) {
        this.title = options.default;
        return;
      }

      if (options.fields) {
        this.title = options.fields.split(',').reduce((title, field) => {
          if (this.record[field]) {
            title += this.record[field] + ' ';
          }

          return title;
        }, '').trim();
      }

      if (options.prefix) {
        this.title = `${options.prefix} - ${this.title}`;
      }
    } catch (exception) {
      console.warn(exception);
    }
  }
}
