import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SyncAccountComponent } from './components/sync-account/sync-account.component';
import { NbCardModule } from '@nebular/theme';
import { NgxPlaidLinkModule } from 'ngx-plaid-link';
import { SyncTransactionsComponent } from './components/sync-transactions/sync-transactions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { NebularModule } from '../nebular/nebular.module';

@NgModule({
  declarations: [SyncAccountComponent, SyncTransactionsComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    NebularModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPlaidLinkModule
  ],
  exports: [
    SyncAccountComponent,
    SyncTransactionsComponent
  ]
})

export class FinanceModule { }
