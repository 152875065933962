import { Component, OnInit } from '@angular/core';
import { Tenant } from 'src/app/models/tenant/tenant';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { ActivatedRoute } from '@angular/router';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent implements OnInit {
  loading: boolean;
  error: string;
  columns: TableColumn[];
  records: Tenant[] = [];

  actions: FormAction[] = [
    { name: 'new', label: 'Nouveau locataire', primary: true, link: '/locataire/-1', type: 'link' }
  ];

  constructor(private db: TenantService, private route: ActivatedRoute, private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.loading = true;
    this.workspace.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        return;
      }

      this.columns = [
        { name: 'first_name', label: 'Prénom', type: 'string' },
        { name: 'last_name', label: 'Nom', type: 'string' },
        { name: 'email', label: 'Email', type: 'email' },
        { name: 'phone', label: 'Téléphone', type: 'phone' },
        {
          name: 'realty',
          label: 'Appartement',
          type: 'reference',
          url: `workspace/${workspace.id}/realty/`,
          reference: 'realty',
          page: '/appartement',
          displayValue: 'short_description'
        },
        {
          name: 'balance', label: 'Solde', type: 'template', template: (record: Tenant) => {
            if (record.balance < 0) {
              return `<a href="solde-locataire?locataire=${record.id}">
                    <span class="text-danger">
                      ${record.balance} €
                    </span>
                  </a>`;
            }

            return `<a href="solde-locataire?locataire=${record.id}">
                    <span class="text-success">
                      ${record.balance} €
                    </span>
                  </a>`;
          }
        }
      ];

      this.route.params.subscribe(parameters => {
        this.db.getTenants(workspace.id).then(
          records => this.records = records.filter(record => {
            if (parameters['state']) {
              return record.active === (parameters['state'] === 'en-cours');
            }

            return true;
          })
        ).catch(error => this.error = error
        ).finally(() => this.loading = false);
      })
    })
  }
}
