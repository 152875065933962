import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { Property } from 'src/app/models/property/property';
import { PropertyService } from 'src/app/services/property/property.service';

@Component({
  selector: 'app-basements',
  templateUrl: './basements.component.html',
  styleUrls: ['./basements.component.scss']
})
export class BasementsComponent implements OnInit {
  loading: boolean;
  keywords: string;
  error: string;
  columns: TableColumn[] = [
    { name: 'short_description', label: 'Description courte', type: 'string' },
    { name: 'address', label: 'Addresse', type: 'textarea' },
    { name: 'monthly_income', label: 'Revenus mensuels', type: 'amount' }
  ]

  records: Property[];
  actions: FormAction[] = [
    {
      name: 'new', label: 'Nouvelle cave', link: '/bien-immobilier/-1', type: 'link', queryParams: {
        sysparm_default: 'property_type=b'
      }
    }];

  constructor(private db: PropertyService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        return;
      }

      this.loading = true;
      this.db.getBasements(workspace.id).then((records: Property[]) => {
        this.records = records;
      }
      ).catch(error => this.error = error
      ).finally(() => this.loading = false);
    })
  }
}
