import { Record } from 'src/app/@core/models/record/record';

export class Room extends Record {
    id?: string;
    short_description: string;
    description: string;
    legal_living_area: number;
    realty: string;
    monthly_rent: number;
    monthly_charges: number;
    monthly_rent_with_charges: number;
    tenant?: string;

    constructor(id?: string){
        super('room', id);
    }
}
