import { Component, OnInit } from '@angular/core';
import { EChartOption } from 'echarts';
import { DateUtil } from 'src/app/@core/util/date-util';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-financial-trend',
  templateUrl: './financial-trend.component.html',
  styleUrls: ['./financial-trend.component.scss']
})
export class FinancialTrendComponent implements OnInit {
  loading: boolean;
  periods = [
    { value: 3, label: '3 mois'},
    { value: 6, label: '6 mois'},
    { value: 12, label: '1 an'}
  ];
  period = 3;
  workspaceID: string;
  options: EChartOption;

  constructor(private transaction: TransactionService, private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if(!workspace){
        return;
      }

      this.workspaceID = workspace.id;
      this._loadData();
    })
  }

  refreshData(){
    this._loadData();
  }

  private _loadData(){
    this.loading = true;
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - this.period);
    startDate.setDate(1);

    this.options = {
      xAxis: {
        type: 'category',
        data: [],
      },
      yAxis: {
        type: 'value',
      },
      legend: {
        data: ['Crédit', 'Débit'],
        align: 'left'
      },
      series: [
        {
          name: 'Crédit',
          data: [],
          type: 'bar',
        },
        {
          name: 'Débit',
          data: [],
          type: 'bar',
        },
      ],
      animationEasing: 'elasticOut',
      animationDelayUpdate: (idx) => idx * 5,
    };

    this.transaction.getTransactions(this.workspaceID).then(
      transactions => {
        this.options = transactions.filter(transaction => {
          const operationDate = new Date(transaction.operation_date);

          if(operationDate >= startDate){
            return true;
          }

          return false;
        }).sort((x, y) => {
          if (x.operation_date <= y.operation_date) {
            return -1;
          }

          if (x.operation_date >= y.operation_date) {
            return 1;
          }

          return 0;
        }).reduce((output, transaction) => {
          const operationDate = new DateUtil().toFormat(transaction.operation_date as string, 'MMM YY');

          if (output.xAxis.data.indexOf(operationDate) === -1) {
            output.xAxis.data.push(operationDate);
          }

          const index = output.xAxis.data.indexOf(operationDate);
          output.series.forEach(serie => {
            if (serie.data[index] === undefined) {
              serie.data[index] = 0;
            }

            if (transaction.amount >= 0 && serie.name === 'Crédit') {
              serie.data[index] += transaction.amount;
            }

            if (transaction.amount < 0 && serie.name === 'Débit') {
              serie.data[index] += -1 * transaction.amount;
            }
          });

          return output;
        }, this.options);
      }
    ).finally(() => this.loading = false);
  }
}
