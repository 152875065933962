import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { User } from 'src/app/@auth/models/user/user';
import { UserService } from 'src/app/@auth/services/user/user.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { APP_DATE_FORMATS } from 'src/app/@core/util/datepicker-format';
import { Property } from 'src/app/models/property/property';
import { Room } from 'src/app/models/room/room';
import { Tenant } from 'src/app/models/tenant/tenant';
import { PropertyService } from 'src/app/services/property/property.service';
import { RoomService } from 'src/app/services/room/room.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { RoomComponent } from '../room/room.component';

@Component({
  selector: 'app-tenant-contract-wizard',
  templateUrl: './tenant-contract-wizard.component.html',
  styleUrls: ['./tenant-contract-wizard.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class TenantContractWizardComponent implements OnInit {
  tenants: Tenant[] = [];
  tenant: Tenant;
  realties: Property[] = [];
  realty: Property;
  rooms: Room[] = [];
  landlord: User;
  users: User[] = [];
  rentalTypes = new ChoiceList().getChoices('realty', 'rental_type');
  constructionPeriods = new ChoiceList().getChoices('realty', 'construction_period');
  legalSystems = new ChoiceList().getChoices('realty', 'legal_system');
  roomNumbers = new ChoiceList().getChoices('realty', 'nb_main_room');
  heatingTypes = new ChoiceList().getChoices('realty', 'heating_type');
  heatingEnergies = new ChoiceList().getChoices('realty', 'heating_energy');
  loaded: boolean = false;

  constructor(private workspaceService: WorkspaceService,
    private tenantService: TenantService,
    private realtyService: PropertyService,
    private roomService: RoomService,
    private db: DatabaseService,
    private userService: UserService,
    private modal: NbDialogService,
    private location: ActivatedRoute) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      Promise.all([
        this.tenantService.getActiveTenants(workspace?.id),
        this.realtyService.getProperties(workspace?.id),
        this.userService.getUsers(workspace?.id)
      ]).then(result => {
        this.tenants = result[0]
        this.realties = result[1];
        this.users = result[2];

        this.location.queryParams.subscribe(params => {
          const realtyID = params['realty'];
          const tenantID = params['tenant'];

          if (tenantID) {
            this.tenant = this.tenants.find(tenant => tenant.id == tenantID);
          } else {
            this.tenant = new Tenant();
          }

          if (realtyID) {
            this.realty = this.realties.find(realty => realty.id == realtyID);

            if (this.realty) {
              this.landlord = this.users.find(user => user.id == this.realty.landlord);
              this.tenant.furnished = this.realty.type_of_furniture == 'furnished' ? 'yes' : 'no';
              this.tenant.shared_room = this.realty.rental_type == 's' ? 'yes' : 'no';
              
              this.updateContractDuration();
              this.updatePrivateAreaDescription();

              return this.roomService.getRoomsByProperty(workspace?.id, this.realty.id).then(rooms => {
                if (rooms.length) {
                  this.rooms = rooms;

                  const selectedRoom = this.rooms.find(room => this.tenant.room == room.id);

                  if(selectedRoom && !this.tenant.private_area_description){
                    this.tenant.private_area_description = selectedRoom.description;
                  }
                }
              }).then(() => this.loaded = true);
            }
          }
        })
      })
    })
  }

  save(record: any, field: string) {
    if (record[field]) {
      this.db.save(record);
    }
  }

  updateContractDuration() {
    
    if (this.tenant.furnished == 'yes') {
      if (this.tenant.mobility_lease == 'yes') {
        this.tenant.contract_duration = 10;
      }else{
        this.tenant.contract_duration = 12;
      }
    } else {
      this.tenant.mobility_lease = 'no';
      this.tenant.contract_duration = 36;
    }

    this.save(this.tenant, 'contract_duration');
  }

  updatePrivateAreaDescription() {
    const room = this.rooms.find(room => room.id == this.tenant.room);

    if (room) {
      this.tenant.private_area_description = room.description;
    }

    this.save(this.tenant, 'private_area_description');
  }

  addRoom() {
    this.modal.open(RoomComponent);
  }
}
