import { Record } from '../@core/models/record/record';
import { Property } from './property/property';

export abstract class RealtyGroup extends Record {
    short_description: string;
    address: string;
    category: 'b';
    building_year;
    realties: Property[]
}
