import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { UserService } from '../../services/user/user.service';
import { WorkspaceInvitationService } from '../../services/workspace-invitation/workspace-invitation.service';

@Component({
  selector: 'app-workspace-invitations',
  templateUrl: './workspace-invitations.component.html',
  styleUrls: ['./workspace-invitations.component.scss']
})
export class WorkspaceInvitationsComponent implements OnInit {
  @Input() invitations: any[];
  @Input() email: string;

  saving: boolean;

  constructor(private workspaceInvitationService: WorkspaceInvitationService,
    private uiNotification: UINotificationService,
    private dialog: NbDialogRef<WorkspaceInvitationsComponent>
  ) { }

  ngOnInit(): void {

  }

  onApprove(invitation) {
    this.saving = true;

    this.workspaceInvitationService.approveInvitation(this.email, invitation.id, invitation.meta.workspace_id).then(
      response => {
        if(response.approval !== 'a'){
          throw 'Une erreur est survenue. Merci de réessayer.';
        }

        invitation = response;
        this.refresh();
      }
    ).catch(error => this.uiNotification.snack(error, 'danger')
    ).finally(() => this.saving = false)
  }

  onReject(invitation) {
    this.workspaceInvitationService.rejectInvitation(this.email, invitation.id, invitation.meta.workspace_id).then(
      response => {
        if(response.approval !== 'd'){
          throw 'Une erreur est survenue. Merci de réessayer.';
        }

        invitation = response;
        this.refresh();
      }
    ).catch(error => this.uiNotification.snack(error, 'danger')
    ).finally(() => this.saving = false)
  }

  refresh(){
    this.invitations = this.invitations.filter(invitation => invitation == 'p');

    if(this.invitations.length == 0){
      this.dialog.close();
    }
  }
}
