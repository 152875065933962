import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { RealtySelectionComponent } from 'src/app/components/realty-selection/realty-selection.component';
import { Property } from 'src/app/models/property/property';
import { PropertyService } from 'src/app/services/property/property.service';

@Component({
  selector: 'app-parkings',
  templateUrl: './parkings.component.html',
  styleUrls: ['./parkings.component.scss']
})
export class ParkingsComponent implements OnInit {
  loading: boolean;
  error: string;
  columns: TableColumn[] = [
    { name: 'short_description', label: 'Description courte', type: 'string' },
    { name: 'address', label: 'Adresse', type: 'string' },
    { name: 'monthly_income', label: 'Revenus mensuels', type: 'amount' }
  ];

  actions: FormAction[];

  records: Property[] = [];

  constructor(private service: PropertyService, private workspaceService: WorkspaceService, private modal: NbDialogService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        return;
      }

      this.loading = true;
      this.service.getParkings(workspace.id)
        .then((records: Property[]) => this.records = records)
        .then(() => this.actions = [
          {
            name: 'new',
            label: 'Ajouter un bien',
            type: 'script',
            primary: true,
            onClick: () => {
              this.modal.open(RealtySelectionComponent);
            }
          }
        ])
        .catch(error => console.warn(error))
        .finally(() => this.loading = false)
    })
  }
}
