import { compileNgModuleFromRender2 } from '@angular/compiler/src/render3/r3_module_compiler';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { PdfGeneratorService } from 'src/app/@core/services/pdf-generator/pdf-generator.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { TemplateNameComponent } from 'src/app/components/template-name/template-name.component';
import { DocumentTemplate } from 'src/app/models/document-template';
import { DocumentService } from 'src/app/services/document/document.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-document-template',
  templateUrl: './document-template.component.html',
  styleUrls: ['./document-template.component.scss']
})

export class DocumentTemplateComponent implements OnInit {
  loading: boolean;
  saving: boolean;
  template: DocumentTemplate;
  config = environment.tiny.config;
  title = 'Modèle de document';
  categories = new ChoiceList().getChoices('template', 'category');

  key = environment.tiny.apiKey;
  workspaceID: string;
  prod = environment.production;

  constructor(private route: ActivatedRoute,
    private document: DocumentService,
    private router: Router,
    private notification: UINotificationService,
    private workspaceService: WorkspaceService,
    private pdfGenerator: PdfGeneratorService,
    private dialogService: NbDialogService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.workspaceID = workspace.id;

      this.route.params.subscribe(parameters => {
        const id = parameters['id'];
        this.loading = true;

        if (id !== '-1') {
          this.document.getTemplate(id).then(
            record => {
              if (!record) {
                return;
              }

              this.title = `Modèle de document - ${record.name}`;
              this.template = record;
            }
          ).finally(() => this.loading = false)
        } else {
          this.template = new DocumentTemplate();
          this.loading = false;
        }
      })
    })
  }

  onDelete() {
    if (confirm('Confirmez-vous la suppression du modèle?')) {
      this.document.deleteTemplate(this.template.id).then(
        () => this.router.navigate(['/administration/modeles'])
      ).then(() => this.notification.snack('Modèle de document supprimé', 'info')
      ).catch((error) => this.notification.snack(error, 'danger'))
    }
  }

  onDuplicate() {
    this.dialogService.open(TemplateNameComponent, {
      context: {
        name: this.template.name + ' (Copie)'
      }
    }).onClose.subscribe(name => {
      if (!name) {
        alert('Action aborted')
        return;
      }

      this.saving = true;
      this.document.duplicateTemplate(this.workspaceID, this.template, name).then(template => {
        this.router.navigate([`administration/modele-de-document/${template.id}`])
      }).finally(() => this.saving = false);
    })
  }

  download() {
    this.pdfGenerator.generate(this.template.name, this.template.content);
  }

  onSave() {
    this.saving = true;
    this.document.saveTemplate(this.workspaceID, this.template).then(
      template => {
        this.router.navigateByUrl(`administration/modele-de-document/${template.id}`)
      }
    ).then(() => this.notification.snack('Modèle de document enregistré', 'info')
    ).catch((error) => this.notification.snack(error, 'danger')
    ).finally(() => this.saving = false);
  }
}
