// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  snackbar: {
    duration: 8000
  },
  support: [{email: 'alex@liimmo.fr', name: 'Alexandre'}, {email: 'paul@liimmo.fr', name: 'Paul'}],
  firebase: {
    apiKey: 'AIzaSyBFwVUjZF909eWNmpSqQO9nIkYYErcU93w',
    authDomain: 'workspace-immo.firebaseapp.com',
    databaseURL: 'https://workspace-immo.firebaseio.com',
    projectId: 'workspace-immo',
    storageBucket: 'workspace-immo.appspot.com',
    messagingSenderId: '288941761760',
    appId: '1:288941761760:web:cd8ddaf8b2eaf03a431c85',
    measurementId: 'G-QEPJGWZB7G'
  },
  templates: {
    contract_unfurnished: '65d45508-60b1-4748-b52c-05ea9e39d407',
    contract_furnished: '2895c31a-7247-40bf-b561-15fa1d431df7',
    contract_mobility_lease: '43ffb446-b9c6-4f47-87e9-073d34c39bec'
  },
  frontend: {
    url: 'http://app-test.liimmo.fr/'
  },
  backend: {
    url: 'https://app-test.liimmo.cloud/',
    client_id: 'UAyc24ntF9DaYM1nM4FobKU3IOQ7P2qkCKqEdExJ',
    client_secret: 'o7IqqqmQG0qbE6rZrSenAz75vDIud2WIyxRKuVD3gFvfZfxWtM6zZvLwOvAgMpa3YI5tvWgRzbM255AmEhBqOMvWNAsXT0IfN3jrKoTrNvD7ZmC61MR5uivHISq7DsE5'
  },
  tiny: {
    apiKey: '9b51mnxiup7cvsdch6x7a8i6ss8jm4xeqg707pxoke2cs2lp',
    config: {
      height: 1000,
      entity_encoding: 'raw',
      menubar: 'file edit view insert format tools table help',
      plugins: [
        'advlist autolink lists link image charmap print preview anchor template',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
        'table'
      ],
      templates: [
        {
          title: 'Locataire - Prénom Nom',
          description: 'Afficher le nom du locataire',
          content: '[Locataire - Prénom] [Locataire - Nom]'
        },
        {
          title: 'Locataire - Email',
          description: 'Afficher l\'email du locataire',
          content: '[Locataire - Email]'
        },
        {
          title: 'Locataire - Téléphone',
          description: 'Afficher le téléphone du locataire',
          content: '[Locataire - Téléphone]'
        },
        {
          title: 'Locataire - Adresse',
          description: 'Afficher l\'adresse du locataire',
          content: '[Locataire - Adresse]'
        },
        {
          title: 'Locataire - Adresse précédente',
          description: 'Affiche l\'adresse du locataire avant la location',
          content: '[Locataire - Adresse précédente]'
        },
        {
          title: 'Locataire - Date de naissance',
          description: 'Afficher la date de naissance du locataire',
          content: '[Locataire - Date de naissance]'
        },
        {
          title: 'Locataire - Lieu de naissance',
          description: 'Afficher le lieu de naissance du locataire',
          content: '[Locataire - Lieu de naissance]'
        },
        {
          title: 'Locataire - Nationalité',
          description: 'Afficher la nationalité du locataire',
          content: '[Locataire - Nationalité]'
        },
        {
          title: 'Locataire - Solde',
          description: 'Solde du locataire',
          content: '[Locataire - Solde]'
        },
        {
          title: 'Grant - Prénom Nom',
          description: 'Afficher le nom du garant',
          content: '[Garant - Prénom] [Garant - Nom]'
        },
        {
          title: 'Garant - Email',
          description: 'Afficher l\'email du garant',
          content: '[Garant - Email]'
        },
        {
          title: 'Locataire - Téléphone',
          description: 'Afficher le téléphone du garant',
          content: '[Garant - Téléphone]'
        },
        {
          title: 'Garant - Adresse',
          description: 'Afficher l\'adresse du garant',
          content: '[Garant - Adresse]'
        },
        {
          title: 'Garant - Date de naissance',
          description: 'Afficher la date de naissance du garant',
          content: '[Garant - Date de naissance]'
        },
        {
          title: 'Garant - Lieu de naissance',
          description: 'Afficher le lieu de naissance du garant',
          content: '[Garant - Lieu de naissance]'
        },
        {
          title: 'Garant - Nationalité',
          description: 'Afficher la nationalité du garant',
          content: '[Garant - Nationalité]'
        },
        {
          title: 'Logement - Adresse',
          description: 'Afficher l\'adresse du logement',
          content: '[Logement - Adresse]'
        },
        {
          title: 'Logement - Type d\'habitat',
          description: 'Afficher le type d\'habitat logement',
          content: '[Logement - Type d\habitat]'
        },
        {
          title: 'Logement - Régime juridique',
          description: 'Afficher le régime juridique du logement',
          content: '[Logement - Régime juridique]'
        },
        {
          title: 'Logement - Période de construction',
          description: 'Afficher la période de construction du logement',
          content: '[Logement - Période de construction]'
        },
        {
          title: 'Logement - Surface habitable',
          description: 'Afficher la surface habitable du logement en m2',
          content: '[Logement - Surface habitable]'
        },
        {
          title: 'Logement - Nombre de pièces principales',
          description: 'Afficher le nombre de pièces principales du logement',
          content: '[Logement - Nombre de pièces principales]'
        },
        {
          title: 'Logement - Modalité de production de chauffage',
          description: 'Afficher la modalité de production de chauffage du logement',
          content: '[Logement - Modalité de production de chauffage]'
        },
        {
          title: 'Logement - Modalité de production d\'eau chaude sanitaire',
          description: 'Afficher la modalité de production d\'eau chaude sanitaire du logement',
          content: '[Logement - Modalité de production d\'eau chaude sanitaire]'
        },
        {
          title: 'Logement - Destination des locaux',
          description: 'Afficher la destinationn des locaux',
          content: '[Logement - Destination des locaux]'
        },
        {
          title: 'Logement - Autres parties du logement',
          description: 'Afficher la description des autres parties du logement',
          content: '[Logement - Autres parties du logement]'
        },
        {
          title: 'Logement - Équipement du logement',
          description: 'Afficher les équipements du logement (ex : garage à vélo, ascenseur, espaces verts, aires et équipements de jeux, laverie, local poubelle, gardiennage, autres prestations et services collectifs etc.)',
          content: '[Logement - Équipement du logement]'
        },
        {
          title: 'Logement - Éléments à usage commun',
          description: 'Afficher les éléments à usgae commun du logement (ex : cuisine équipée, détail des installations sanitaires etc.)',
          content: '[Logement - Équipement du logement]'
        },
        {
          title: 'Logement - Élements à usage privatif',
          description: 'Afficher les éléments à usgae privatif du logement (ex : cave, parking, garage etc.)',
          content: '[Logement - Élements à usage privatif]'
        },
        {
          title: 'Logement - Équipements technologiques',
          description: 'Afficher les équipements technologiques du logement (ex : modalités de réception de la télévision dans l\'immeuble, modalités de raccordement internet etc.)',
          content: '[Logement - Équipements technologiques]'
        },
        //Contract

        {
          title: 'Contrat - Date de début',
          description: 'Afficher la date du début du contrat de location',
          content: '[Contrat - Date de début]'
        },
        {
          title: 'Contrat - Durée',
          description: 'Afficher la durée du contrat de location',
          content: '[Contrat - Durée]'
        },
        {
          title: 'Contrat - Justification de la durée',
          description: 'Afficher la justification de la durée du contrat de location',
          content: '[Contract - Justification de la durée]'
        },
        {
          title: 'Contrat - Loyer mensuel hors-charges',
          description: 'Afficher le loyer mensuel hors-charges du contrat de location',
          content: '[Contract - Loyer mensuel hors-charges]'
        },
        {
          title: 'Contrat - Charges mensuelles',
          description: 'Afficher les charges mensuelles du contrat de location',
          content: '[Contract - Charges mensuelles]'
        },
        {
          title: 'Contrat - Loyer mensuel',
          description: 'Afficher le loyer mensuel (loyer + charges) du contrat de location',
          content: '[Contract - Loyer mensuel]'
        },
        {
          title: 'Contrat - Dépot de garantie',
          description: 'Afficher le dépôt de garantie du contrat de location (détailler la somme des montants relatifs au loyer, aux charges récupérable, à la contribution pour le partage des économies de charges et, en cas de colocation, à l\'assurance récupérable pour le compte des colocataires).',
          content: '[Contract - Dépot de garantie]'
        },
        {
          title: 'Contrat - Montant des honoraires',
          description: 'Afficher le montant des honoraires du contrat de location',
          content: '[Contract - Montant des honoraires]'
        },
        {
          title: 'Contrat - Loyer soumis au montant maximum',
          description: 'Indique si le loyer du logement objet du présent contrat est soumis au décret fixant annuellement le montant maximum d\'évolution des loyers à la relocation',
          content: '[Contract - Loyer soumis au montant maximum]'
        },
        {
          title: 'Contrat - Loyer soumis au loyer de référence',
          description: 'Indique si le loyer du logement objet du présent contrat est soumis au loyer de référence majoré fixé par arrêté préfectoral.',
          content: '[Contrat - Loyer soumis au loyer de référence]'
        },
        {
          title: 'Contrat - Montant du loyer de référence',
          description: 'Indique le montant du loyer de référence',
          content: '[Contrat - Montant du loyer de référence]'
        },
        {
          title: 'Contrat - Montant du loyer de référence majoré',
          description: 'Indique le montant du loyer de référence majoré',
          content: '[Contrat - Montant du loyer de référence majoré]'
        },
        {
          title: 'Contrat - Complément de loyer',
          description: 'Indique le montant du loyer de base, nécessairement égal au loyer de référence majoré, le montant du complément de loyer et les caractéristiques du logement justifiant le complément de loyer (si un complément de loyer est prévu)',
          content: '[Contrat - Complément de loyer]'
        },
        {
          title: 'Contrat - Loyer du dernier locataire',
          description: 'Indique le montant du loyer du dernier locataire',
          content: '[Contrat - Loyer du dernier locataire]'
        },
        {
          title: 'Contrat - Date de versement',
          description: 'Indique la date de versement du loyer du dernier locataire',
          content: '[Contrat - Date de versement]'
        },
        {
          title: 'Contrat - Dernière révision du loyer',
          description: 'Indique la Dernière révision du loyer',
          content: '[Contrat - Dernière révision du loyer]'
        },
        {
          title: 'Contrat - Date de révision',
          description: 'Indique la date de révision du loyer',
          content: '[Contrat - Date de révision]'
        },
        {
          title: 'Contrat - IRL',
          description: 'Indique le trimestre de référence de l\'indice de référence des loyers',
          content: '[Contrat - IRL]'
        },
        {
          title: 'Contrat - Montant annuel assurance',
          description: 'Indique le montant total annuel récupérable au titre de l\'assurance pour compte des colocataires,  en cas de colocation souscription par le bailleur d\'une assurance pour le compte des colocataires.',
          content: '[Contrat - Montant annuel assurance]'
        },
        {
          title: 'Contrat - Montant récupérable assurance',
          description: 'Indique le montant récupérable par douzième  en cas de colocation souscription par le bailleur d\'une assurance pour le compte des colocataires ',
          content: '[Contrat - Montant récupérable assurance]'
        },
        {
          title: 'Contrat - Périodicité du paiement',
          description: 'Indique le périodicité de paiement du loyer.',
          content: '[Contrat - Périodicité du paiement]'
        },
        {
          title: 'Contrat - Jour de paiement du loyer',
          description: 'Indique le jour du paiement du loyer.',
          content: '[Contrat - Jour de paiement du loyer]'
        },
        {
          title: 'Contrat - Modalité de paiement du loyer',
          description: 'Indique la modalité de paiement du loyer (chèque, virement)',
          content: '[Contrat - Modalité de paiement du loyer]'
        },
        {
          title: 'Contrat - Montant du premier mois de loyer',
          description: 'Indique le montant du premiere loyer',
          content: '[Contrat - Montant du premier mois de loyer]'
        },
        {
          title: 'Contrat - Montant hausse ou baisse du loyer',
          description: 'Indique le montant de la hausse ou de la baisse de loyer mensuelle, exclusivement lors d\'un renouvellement de contrat, modalités de réévaluation d\'un loyer manifestement sous-évalué',
          content: '[Contrat. - Montant hausse ou baisse du loyer]'
        },
        {
          title: 'Contrat - Montant d\'application de la hausse ou baisse du loyer',
          description: 'Indique les modalités d\'application de la hausse ou de la baisse de loyer mensuelle, exclusivement lors d\'un renouvellement de contrat, modalités de réévaluation d\'un loyer manifestement sous-évalué',
          content: '[Contrat - Montant d\'application de la hausse ou baisse du loyer]'
        },
        {
          title: 'Contrat - Modalité d\'application de la hausse ou baisse du loyer',
          description: 'Indique les modalités d\'application de la hausse ou de la baisse de loyer mensuelle, exclusivement lors d\'un renouvellement de contrat, modalités de réévaluation d\'un loyer manifestement sous-évalué',
          content: '[Contrat - Modalité d\'application de la hausse ou baisse du loyer]'
        },
        {
          title: 'Contrat - Claude de solidarité',
          description: 'Indique les modalités particulières des obligations en cas de pluralité de locataires (clause prévoyant la solidarité des locataires et l\'indivisibilité de leurs obligations en cas de pluralité de locataires)',
          content: '[Contrat - Claude de solidarité] '
        },
        {
          title: 'Contrat - Claude de solidarité',
          description: 'Indique les modalités particulières des obligations en cas de pluralité de locataires (clause prévoyant la solidarité des locataires et l\'indivisibilité de leurs obligations en cas de pluralité de locataires)',
          content: '[Contrat - Claude de solidarité] '
        },
        {
          title: 'Quittance - Période',
          description: 'Afficher la période de la quittance pour le mois sélectionné',
          content: 'Période du [Quittance - Début] au [Quittance - Fin]'
        },
        {
          title: 'Quittance - Loyer charges comprises',
          description: 'Affiche le loyer mensuel du locataire incluant les charges pour le mois sélectionné',
          content: 'Loyer menuselle charges comprises : [Locataire - Loyer CC]'
        },
        {
          title: 'Quittance - Date paiement',
          description: 'Affiche la date à laquelle le locataire a payé le loyer pour le mois sélectionné',
          content: 'Loyer HC : [Quittance - Date du paiement]'
        },
        {
          title: 'Propriétaire - Prénom Nom',
          description: 'Afficher le nom du propriétaire',
          content: '[Propriétaire - Prénom] [Propriétaire - Nom]'
        },
        {
          title: 'Propriétaire - Email',
          description: 'Afficher l\'email du propriétaire',
          content: '[Propriétaire - Email]'
        },
        {
          title: 'Propriétaire - Téléphone',
          description: 'Affiche le téléphone du propriétaire',
          content: '[Propriétaire - Téléphone]'
        },
        {
          title: 'Propriétaire - Adresse',
          description: 'Affiche l\'adresse du propriétaire',
          content: '[Propriétaire - Adresse]'
        },
        {
          title: 'Propriétaire - Société',
          description: 'Affiche la société du propriétaire (si existante)',
          content: '[Propriétaire - Société]'
        }
      ],
      formats: {
        // Changes the default format for h1 to have a class of heading
        button: {
          block: 'a',
          styles: {
            color: 'white',
            'background-color': '#3b9d92',
            padding: '10px 20px',
            border: '1px solid #3b9d92',
            'border-radius': '5px',
            'text-decoration': 'none',
          }
        }
      },
      style_formats: [
        // Adds the h1 format defined above to style_formats
        { title: 'Button', format: 'button' }
      ],
      toolbar:
        'undo redo | styleselect | bold italic forecolor backcolor | template |\
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | removeformat | \ table | \help'
    }
  },
  stripe: {
    apiKey: 'pk_test_51HW5EXEWgvmJfbeYauPgLHAjgkHkjIJNOroqU0EUWAMIvNjYOcwKfGmpU8tqjNT2l78qtr6s0GH7iI71ccgvGTYe0099aXqNJp',
    secretKey: 'sk_test_51HW5EXEWgvmJfbeYIeLci8zQ4qbQZWHsMFAstGAeAbtFWyVxgPRof6lYcQFP5U7DK8xZ0xAMbt4qcYk4NPZpPcWO00sHyuFFog'
  },
  plaid: {
    environment: 'development',
    client_id: '5fdf4896df1def0013986795',
    client_secret: 'f2b5f1ec8debc80d3e85a5d30380ea'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
