import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbMenuModule, NbSidebarModule } from '@nebular/theme';
import { NebularModule } from './nebular/nebular.module';
import { PropertyAssetComponent } from './pages/property-asset/property-asset.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { CoreModule } from './@core/core.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PropertyAssetsComponent } from './pages/property-assets/property-assets.component';
import { TenantComponent } from './pages/tenant/tenant.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TenantsComponent } from './pages/tenants/tenants.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentComponent } from './pages/document/document.component';
import { InvoicesComponent } from './pages/documents/invoices/invoices.component';
import { BuildingsComponent } from './pages/property-assets/buildings/buildings.component';
import { ApartmentsComponent } from './pages/property-assets/apartments/apartments.component';
import { ContractsComponent } from './pages/documents/contracts/contracts.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { RoomComponent } from './pages/room/room.component';
import { SplitTransactionComponent } from './components/split-transaction/split-transaction.component';
import { HtmlEditorComponent } from './pages/html-editor/html-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TenantReceiptComponent } from './components/tenant-receipt/tenant-receipt.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { GuarantorsComponent } from './pages/guarantors/guarantors.component';
import { GuarantorComponent } from './pages/guarantor/guarantor.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { DocumentTemplateComponent } from './pages/administration/document-template/document-template.component';
import { DocumentTemplatesComponent } from './pages/administration/document-templates/document-templates.component';
import { TemplateNameComponent } from './components/template-name/template-name.component';
import { QuotationsComponent } from './pages/documents/quotations/quotations.component';
import { WorkspacesComponent } from './pages/administration/workspaces/workspaces.component';
import { WorkspaceComponent } from './pages/administration/workspace/workspace.component';
import { DocumentsImportComponent } from './pages/documents-import/documents-import.component';
import { DocumentImportComponent } from './components/document-import/document-import.component';
import { AuthInterceptorService } from './@auth/auth-interceptor.service';
import { BasementsComponent } from './pages/property-assets/basements/basements.component';
import { BuildingComponent } from './pages/building/building.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InvoiceComponent } from './pages/document/invoice/invoice.component';
import { QuotationComponent } from './pages/document/quotation/quotation.component';
import { ReviewTenantBalanceComponent } from './components/review-tenant-balance/review-tenant-balance.component';
import { CompaniesComponent } from './pages/companies/companies.component';
import { CompanyComponent } from './pages/company/company.component';
import { TenantBalanceComponent } from './pages/tenant-balance/tenant-balance.component';
import { LoadingComponent } from './components/loading/loading.component';
import { RealtySelectionComponent } from './components/realty-selection/realty-selection.component';
import { HousesComponent } from './pages/property-assets/houses/houses.component';
import { ParkingsComponent } from './pages/property-assets/parkings/parkings.component';
import { StoreHousesComponent } from './pages/property-assets/store-houses/store-houses.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { ReminderBoxComponent } from './components/reminder-box/reminder-box.component';
import { ImportTransactionsComponent } from './components/import-transactions/import-transactions.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BankAccountsComponent } from './pages/bank-accounts/bank-accounts.component';
import { BankAccountComponent } from './pages/bank-account/bank-account.component';
import { IncidentFormComponent } from './components/incident-form/incident-form.component';
import { NgxMaskModule } from 'ngx-mask';
import { WelcomeMessageComponent } from './components/welcome-message/welcome-message.component';
import { RentalAnalysisComponent } from './pages/dashboard/rental-analysis/rental-analysis.component';
import { TenantBalancesComponent } from './pages/dashboard/tenant-balances/tenant-balances.component';
import { FinancialTrendComponent } from './pages/dashboard/financial-trend/financial-trend.component';
import { DashboardEventsComponent } from './pages/dashboard/dashboard-events/dashboard-events.component';
import { GenerateCalendarEventComponent } from './components/generate-calendar-event/generate-calendar-event.component';
import { ImportTransactionWizardComponent } from './pages/import-transaction-wizard/import-transaction-wizard.component';
import { LayoutModule } from './@layout/layout.module';
import { EditImportedTransactionComponent } from './components/edit-imported-transaction/edit-imported-transaction.component';
import { FinanceModule } from './@finance/finance.module';
import { UpdateRentComponent } from './components/update-rent/update-rent.component';
import { ImportDocumentsComponent } from './pages/dashboard/import-documents/import-documents.component';
import { RentTransactionsComponent } from './components/rent-transactions/rent-transactions.component';
import { TenantContractComponent } from './pages/tenant-contract/tenant-contract.component';
import { FormRoomComponent } from './components/form-room/form-room.component';
import { TenantContractWizardComponent } from './pages/tenant-contract-wizard/tenant-contract-wizard.component';
import { SendContractComponent } from './components/send-contract/send-contract.component';
import { CashFlowComponent } from './pages/dashboard/cash-flow/cash-flow.component';
import { FinancialDashboardComponent } from './pages/financial-dashboard/financial-dashboard.component';
import { EditRentComponent } from './components/edit-rent/edit-rent.component';
import { WorkspaceMemberComponent } from './pages/administration/workspace-member/workspace-member.component';
import { DepositReceivedComponent } from './components/deposit-received/deposit-received.component';
import { BankComponent } from './pages/bank/bank.component';
import { SelectTransactionsComponent } from './components/select-transactions/select-transactions.component';
import { BankAccountTransactionsComponent } from './components/bank-account-transactions/bank-account-transactions.component';
import { EmailComponent } from './pages/email/email.component';

@NgModule({
  declarations: [
    AppComponent,
    PropertyAssetComponent,
    DashboardComponent,
    PageNotFoundComponent,
    PropertyAssetsComponent,
    TenantComponent,
    TenantsComponent,
    TransactionsComponent,
    DocumentsComponent,
    ContactsComponent,
    DocumentComponent,
    InvoicesComponent,
    BuildingsComponent,
    ApartmentsComponent,
    ContractsComponent,
    TransactionComponent,
    RoomComponent,
    SplitTransactionComponent,
    HtmlEditorComponent,
    TenantReceiptComponent,
    DocumentTemplateComponent,
    DocumentTemplatesComponent,
    GuarantorsComponent,
    GuarantorComponent,
    TemplateNameComponent,
    QuotationsComponent,
    WorkspacesComponent,
    WorkspaceComponent,
    DocumentsImportComponent,
    DocumentImportComponent,
    BasementsComponent,
    BuildingComponent,
    InvoiceComponent,
    QuotationComponent,
    ReviewTenantBalanceComponent,
    CompaniesComponent,
    CompanyComponent,
    TenantBalanceComponent,
    LoadingComponent,
    RealtySelectionComponent,
    HousesComponent,
    ParkingsComponent,
    StoreHousesComponent,
    RentalAnalysisComponent,
    TenantBalancesComponent,
    FinancialTrendComponent,
    DashboardEventsComponent,
    ReminderBoxComponent,
    ImportTransactionsComponent,
    ContactCardComponent,
    BankAccountsComponent,
    BankAccountComponent,
    IncidentFormComponent,
    WelcomeMessageComponent,
    GenerateCalendarEventComponent,
    ImportTransactionWizardComponent,
    EditImportedTransactionComponent,
    UpdateRentComponent,
    ImportDocumentsComponent,
    RentTransactionsComponent,
    TenantContractComponent,
    FormRoomComponent,
    TenantContractWizardComponent,
    SendContractComponent,
    CashFlowComponent,
    FinancialDashboardComponent,
    EditRentComponent,
    WorkspaceMemberComponent,
    DepositReceivedComponent,
    BankComponent,
    SelectTransactionsComponent,
    BankAccountTransactionsComponent,
    EmailComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NebularModule.forRoot(),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    LayoutModule,
    FinanceModule,
    CoreModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatTabsModule,
    HttpClientModule,
    FormsModule,
    EditorModule,
    AngularMaterialModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
