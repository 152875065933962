import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { EmailService } from '../../services/email/email.service';
import { UINotificationService } from '../../services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-email-message-card',
  templateUrl: './email-message-card.component.html',
  styleUrls: ['./email-message-card.component.scss']
})
export class EmailMessageCardComponent implements OnInit {
  @Input() recipients: string;
  @Input() cc: string;
  @Input() bcc: string;
  @Input() title: string;
  @Input() body: string;

  _recipient_list: string;
  _cc: string;
  _bcc: string;
  _title: string;
  _body: string;

  constructor(private mailbox: EmailService, private snackbar: UINotificationService, private dialog: NbDialogRef<EmailMessageCardComponent>) { }

  ngOnInit(): void {
  }

  onClose(){
    this.dialog.close();
  }

  onSubmit(){
    const recipients = this.recipients.split(',').map(recipient => {
      return {name: recipient, email: recipient};
    });

    this.mailbox.sendEmail(recipients, null, this.title, this.body).then(() => {
      this.dialog.close();
      this.snackbar.snack('Email envoyé', 'info');
    }).catch(error => this.snackbar.snack(error, 'danger'))
  }
}
