import { Component, OnInit } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-documents-import',
  templateUrl: './documents-import.component.html',
  styleUrls: ['./documents-import.component.scss'],
  animations: [
    fadeInOnEnterAnimation()
  ]
})
export class DocumentsImportComponent implements OnInit {
  bounce: boolean;
  files: File[] = [];
  uploaded: boolean;

  constructor() { }

  ngOnInit(): void {
    this.uploaded = false;
  }

  uploadFiles(event){
    this.files = event.target.files;
    this.uploaded = true;
  }

  onBounce(){
    this.bounce = !this.bounce;
  }
}
