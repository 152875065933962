import { User } from './user/user';

export class Person extends User {
    date_of_birth: Date | string;

    constructor(email: string, token: string, tokenExpirationDate: Date)
    {
        super(email, token, tokenExpirationDate);
        this.name = `${this.first_name} ${this.last_name}`;
    }
}
