import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Workspace } from 'src/app/@auth/models/workspace/workspace';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { PlaidService } from 'src/app/@finance/services/plaid/plaid.service';
import { BankAccount } from 'src/app/models/bank-account';
import { Transaction } from 'src/app/models/transaction/transaction';
import { environment } from 'src/environments/environment';
import { TransactionService } from '../transaction/transaction.service';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {
  constructor(private http: HttpClient,
    private db: DatabaseService,
    private plaid: PlaidService,
    private transaction: TransactionService) {
  }

  getMainAccount(workspace: Workspace): Promise<BankAccount> {
    if (workspace.plaid_access_token){
      return this.plaid.getBankAccountInfo(workspace.id).then(
        account => {
          let bankAccount = new BankAccount();
          bankAccount.short_description = account.name;
          bankAccount.iban = account.iban;
          bankAccount.bic = account.bic;
          bankAccount.balance = account.balance;
          bankAccount.currency = account.currency;

          return bankAccount;
        }
      );
    }

    return this.getAccounts(workspace.id).then(accounts => {
      if(!accounts || accounts.length == 0){
        return null;
      }

      const account = accounts[0];

      let bankAccount = new BankAccount();
      bankAccount.id = account.id;
      bankAccount.short_description = account.bank;
      bankAccount.official_name = account.short_description
      bankAccount.iban = account.iban;
      bankAccount.bic = account.bic;

      return bankAccount;
    });
  }

  getInstitions(): Promise<any> {
    return this.http.post(`https://${environment.plaid.environment}.plaid.com/institutions/get/`, {
      client_id: environment.plaid.client_id,
      secret: environment.plaid.client_secret,
      count: 200,
      offset: 0,
      country_codes: ['FR']
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    }).pipe(catchError(error => {
      console.warn(error);
      return [];
    })).toPromise();
  }

  getAccounts(workspaceID: string,): Promise<BankAccount[]> {
    return this.db.getCollection<BankAccount>(`workspace/${workspaceID}/bank-account/`, 'bank-account');
  }

  getAccount(id: string): Promise<BankAccount> {
    return this.db.get<BankAccount>(`bank-account`, id);
  }

  getTransactions(workspaceID: string, account: string): Promise<Transaction[]> {
    return this.transaction.getTransactions(workspaceID).then(
      transactions => transactions.filter(transaction => transaction.account === account)
    ).catch(error => {
      console.warn(error)
      return [];
    })
  }
}
