import { P } from '@angular/cdk/keycodes';
import moment from 'moment';

export class DateUtil {

    constructor() {
        moment.locale('fr');
    }

    diffDays(startDate: string, endDate: string, format: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'): number {
        const date1 = moment(startDate, 'YYYY-MM-DD');
        const date2 = moment(endDate, 'YYYY-MM-DD');

        return date2.diff(date1, format);
    }

    set(year?: number, month?: number, day?: number): Date {
        const date = moment();

        if (day) {
            date.dayOfYear(day);
        }

        if (month) {
            date.month(month);
        }

        if (year) {
            date.year(year);
        }

        return date.toDate();
    }

    add(date: string | Date, duration: number, unit: 'y' | 'Q' | 'M' | 'w' | 'd' | 'h' | 'm' | 's' | 'ms'): Date {
        return moment(date, 'YYYY-MM-DD').add(duration, unit).toDate();
    }

    setDate(value: string): Date {
        return moment(value, 'YYYY-MM-DD').toDate();
    }

    addDays(date: Date, days: number): Date {
        return moment(date.toDateString()).add(days, 'days').toDate();
    }

    isInthePast(date: Date) {
        const now = moment();

        return moment(date.toDateString()).diff(now) < 0;
    }

    isBefore(date: Date, date2: Date, strict: boolean = false) {
        if (strict) {
            return moment(date.toDateString()).diff(date2.toDateString()) < 0;
        }

        return moment(date.toDateString()).diff(date2.toDateString()) <= 0;
    }

    isAfter(date: Date, date2: Date, strict: boolean = false) {
        if (strict) {
            return moment(date.toDateString()).diff(date2.toDateString()) > 0;
        }

        return moment(date.toDateString()).diff(date2.toDateString()) >= 0;
    }

    getEndOfMonth(date) {
        return moment(date).endOf('month').toDate();
    }

    getBeginningOfMonth(date) {
        return moment(date).startOf('month').toDate();
    }

    toFormat(date: Date | string, format: string) {
        return moment(date).format(format);
    }
}