import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { DateUtil } from 'src/app/@core/util/date-util';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-select-transactions',
  templateUrl: './select-transactions.component.html',
  styleUrls: ['./select-transactions.component.scss']
})
export class SelectTransactionsComponent implements OnInit {
  @Input() lineID: string;
  @Input() amount: number;
  @Input() category: string;
  @Input() tenantID: string;
  @Input() selection: boolean;
  
  private _transactions: Transaction[];

  total: number = 0;
  selected: Transaction[] = [];
  transactions: Transaction[];
  keywords: string;
  loaded: boolean;

  constructor(private transactionService: TransactionService,
    private workspace: WorkspaceService,
    private db: DatabaseService,
    private snackbar: UINotificationService,
    private dialog: NbDialogRef<SelectTransactionsComponent>) { }

  ngOnInit(): void {
    this.loaded = false;
    this.workspace.current.subscribe(space => {
      if (!space) {
        return;
      }

      this.transactionService.getTransactions(space.id).then(
        transactions => {
          this._transactions = transactions
          this.selected = this._transactions.filter(transaction => transaction.rent == this.lineID);
          this.onFilter();
        }
      ).catch(error => this.snackbar.snack(error, 'danger')
      ).finally(() => this.loaded = true)
    })
  }

  onFilter() {
    this.transactions = this._transactions.filter(transaction => {
      if(this.selection){
        return transaction.rent == this.lineID;
      }
      
      if (!this.keywords) {
        return true;
      }

      return transaction.short_description.toLowerCase().indexOf(this.keywords.toLowerCase()) > -1;
    })
  }

  onToggleSelection(): void{
    this.selection = !this.selection;
    this.onFilter();
  }

  onSelect(transaction: Transaction): void {
    let operation = new Transaction();
    operation = { ...transaction, };
    operation.operation_date = new DateUtil().toFormat(transaction.operation_date.toString(), 'YYYY-MM-DD');

    if (transaction.rent != this.lineID) {
      operation.tenant = this.tenantID;

      if(this.category == 'rent'){
        operation.category = 'r';
        operation.rent = this.lineID;
        operation.deposit = null;
      }

      if(this.category == 'deposit'){
        operation.category = 'd';
        operation.deposit = this.lineID;
        operation.rent = null;
      }
    } else {
      operation.deposit = null
      operation.rent = null
      operation.tenant = null;
      operation.category = 'o';
    }

    this.db.save(operation).then(
      () => {
        transaction.rent = operation.rent;
        this.snackbar.snack('Sauvegardé')
      }
    ).catch(error =>
      this.snackbar.snack(error.message, 'danger')
    )

    const existingTransaction = this.selected.find(item => transaction.id == item.id);

    if (existingTransaction) {
      this.selected = this.selected.filter(item => transaction.id != item.id);
    } else {
      this.selected.push(transaction);
    }

    this.total = this.selected.reduce((total: number, transaction: Transaction) => {
      return total + transaction.amount;
    }, 0)
  }

  isSelected(rentID: string): boolean {
    return rentID == this.lineID;
  }

  onClose() {
    this.dialog.close();
  }
}
