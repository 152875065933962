import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { Property } from 'src/app/models/property/property';
import { Tenant } from 'src/app/models/tenant/tenant';
import { PropertyService } from 'src/app/services/property/property.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';

@Component({
  selector: 'app-tenant-balances',
  templateUrl: './tenant-balances.component.html',
  styleUrls: ['./tenant-balances.component.scss']
})
export class TenantBalancesComponent implements OnInit {
  tenants: Tenant[];

  constructor(private workspace: WorkspaceService, private tenant: TenantService, private db: DatabaseService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if(!workspace){
        return;
      }

      this.tenant.getActiveTenants(workspace.id).then(
        tenants => this.tenants = tenants.map( tenant => {
          tenant.name = `${tenant.first_name} ${tenant.last_name.toUpperCase()}`;
          
          this.db.get<Property>('realty', tenant.realty).then(
            realty => {
              tenant.short_description = `Locataire à ${realty.short_description}`
            }
          );
  
          return tenant;
        })
      );
    })
  }

}
