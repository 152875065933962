import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { Tenant } from 'src/app/models/tenant/tenant';
import { TenantService } from 'src/app/services/tenant/tenant.service';

@Component({
  selector: 'app-update-rent',
  templateUrl: './update-rent.component.html',
  styleUrls: ['./update-rent.component.scss']
})
export class UpdateRentComponent implements OnInit {
  @Input() id: string;

  tenant: Tenant;
  includeCurrentMonth: boolean;

  constructor(private tenantService: TenantService,
    private notification: UINotificationService,
    private dialog: NbDialogRef<UpdateRentComponent>) { }

  ngOnInit(): void {
    this.tenantService.getTenant(this.id).then(
      tenant => this.tenant = tenant
    ).catch(error => this.notification.snack(error, 'danger'))
  }

  onSubmit() {
    this.tenantService.updateRent(this.tenant.id, this.tenant.monthly_rent, this.tenant.monthly_charges, this.includeCurrentMonth).then(
      (tenant) => {
        this.notification.snack('Le loyer a été mise à jour avec succès', 'info');
        this.dialog.close(tenant);
      }
    ).catch(error => this.notification.snack(error, 'danger'))
  }

}
