import { Injectable } from '@angular/core';
import { Tenant } from 'src/app/models/tenant/tenant';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { Transaction } from 'src/app/models/transaction/transaction';
import { Guarantor } from 'src/app/models/guarantor';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DateUtil } from 'src/app/@core/util/date-util';
import { Rent } from 'src/app/models/rent';
import { map } from 'rxjs/operators';
import { TenantBalance } from 'src/app/models/tenant-balance';
import { TenantBalanceLine } from 'src/app/models/tenant-balance-line';
import { Deposit } from 'src/app/models/deposit';
import { faCommentSlash } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})

export class TenantService {

  constructor(private db: DatabaseService, private http: HttpClient) {
  }

  getTenants(workspaceID: string): Promise<Tenant[]> {
    return this.db.getCollection<Tenant>(`workspace/${workspaceID}/tenant/`, 'tenant').then(
      records => records.sort((a: Tenant, b: Tenant) => {
        if (a.first_name > b.first_name) {
          return 1;
        }

        if (a.first_name < b.first_name) {
          return -1;
        }

        return 0;
      })
    ).catch(error => {
      throw error;
    })
  }

  getActiveTenants(workspaceID: string): Promise<Tenant[]> {
    return this.getTenants(workspaceID).then(records => {
      return records.filter(record => record.contract_state !== 'ce');
    }).then(
      records => records?.map(record => {
        let tenant = new Tenant();
        tenant = { ...record } as Tenant;

        return tenant;
      })
    ).catch(error => {
      if (error.message) {
        throw error.message;
      }

      throw error
    });
  }

  getLastTenantInfo(tenantID): Promise<any> {
    return this.http.get(`${environment.backend.url}/tenant/${tenantID}/last_tenant_info/`).toPromise();
  }

  getTenantsByProperty(workspaceID: string, realty: string): Promise<Tenant[]> {
    return this.getTenants(workspaceID).then(
      records => records.filter(record => record.realty == realty)
    ).then(records => records?.map(record => {
      let tenant = new Tenant()
      tenant = { ...record } as Tenant;

      return tenant;
    })).catch(error => {
      if (error.message) {
        throw error.message;
      }

      throw error
    });;
  }

  getTenantsByRoom(workspaceID: string, room: string): Promise<Tenant[]> {
    return this.getTenants(workspaceID).then(
      records => records.filter(record => record.room == room)
    ).then(records => records?.map(record => {
      let tenant = new Tenant()
      tenant = { ...record } as Tenant;

      return tenant;
    })).catch(error => {
      if (error.message) {
        throw error.message;
      }

      throw error
    });;
  }

  getTenant(id: string): Promise<Tenant> {
    return this.db.get<Tenant>('tenant', id).then(record => {
      let tenant = new Tenant();
      tenant = { ...record } as Tenant;

      return tenant;
    }).catch(error => {
      if (error.message) {
        throw error.message;
      }

      throw error
    });
  }

  getBalance(tenant: string): Promise<TenantBalance> {
    return this.http.get<{ balance: number, rents: Rent[], deposit: any }>(`${environment.backend.url}tenant/${tenant}/balance/`).pipe(
      map(response => {
        let lines = [];
        const rents = response.rents;
        const deposit = response.deposit;

        if (rents) {
          rents.reduce((lines, rent) => {
            const days = new DateUtil().diffDays(rent.start_date as string, rent.end_date as string, 'days') + 1;
            let comments = '';

            if (rent.amount_due > rent.amount_paid) {
              comments = 'Le montant du loyer n\'a pas encore été payé.';
            }

            if (rent.amount_due < rent.amount_paid) {
              comments = `Le locataire a un crédit de ${(rent.amount_paid - rent.amount_due)} €.`;
            }

            const line = {
              id: rent.id,
              short_description: rent.short_description,
              description: `Loyer calculé sur une base de ${days} jour(s)`,
              start_date: rent.start_date,
              end_date: rent.end_date,
              month: new Date(rent['date']).getMonth(),
              year: new Date(rent['date']).getFullYear(),
              due_date: new Date(rent['date']),
              amount_due: rent.amount_due,
              amount_paid: rent.amount_paid,
              status: rent.status,
              category: 'rent',
              balance: rent.amount_paid - rent.amount_due,
              comments: comments
            }

            lines.push(line);

            return lines;
          }, lines)
        }

        if (deposit) {
          let comments = '';

          if (deposit.amount_due > deposit.amount_paid) {
            comments = 'Le montant du dépôt de garantie n\'a pas encore été payé.';
          }

          if (deposit.amount_due < deposit.amount_paid) {
            comments = `Le locataire a payé un trop plein de ${(deposit.amount_paid - deposit.amount_due)} €.`;
          }

          let line = {
            id: deposit.id,
            short_description: 'Dépôt de garantie initial',
            description: `Dépôt de garantie équivalent à deux mois de loyer hors-charges`,
            due_date: new Date(deposit['date']).setHours(0),
            start_date: deposit.start_date,
            end_date: deposit.end_date,
            month: new Date(deposit['date']).getMonth(),
            year: new Date(deposit['date']).getFullYear(),
            amount_due: deposit.amount_due,
            amount_paid: deposit.amount_paid,
            status: deposit.status,
            category: 'deposit',
            balance: (deposit.amount_paid - deposit.amount_due),
            comments: comments
          }

          lines.push(line);
        }

        this.sortLines(lines, true);

        lines.map((line, index, lines)=>{
          if(index > 0){
            const previous = lines[index - 1];

            line.balance += previous.balance;
          }

          return line;
        })

        this.sortLines(lines, false);

        return {
          total: response.balance || 0,
          lines: lines
        }
      })
    ).toPromise();
  }

  sortLines(lines: TenantBalanceLine[], asc: boolean){
    return lines.sort((a, b) => {
      if (a.due_date < b.due_date) {
        return asc ? -1 : 1;
      }

      if (a.due_date > b.due_date) {
        return asc ? 1 : -1;
      }

      return 0;
    })
  }

  updateBalanceLine(tenantID: string, line: TenantBalanceLine) {
    return this.getBalance(tenantID).then(balance => {
      const balanceLine = balance.lines.find(balanceLine => balanceLine.id == line.id);

      if (balanceLine.category == 'deposit') {
        let deposit = new Deposit(line.id);
        deposit = { ...deposit, ...line };
        deposit.tenant = tenantID;

        return this.db.save(deposit);
      }

      if (balanceLine.category == 'rent') {
        let rent = new Rent(line.id);
        rent = { ...rent, ...line }
        rent.tenant = tenantID;
        rent.date = new DateUtil().toFormat(rent.due_date, 'YYYY-MM-DD');

        console.log(rent);

        return this.db.save(rent);
      }

      return null;
    }).catch(error => {
      if (error['detail']) {
        throw error['detail'];
      }

      throw 'Une erreur est survenue. Merci de réessayer.';
    })
  }

  deleteBalanceLine(tenantID: string, id: string) {
    return this.getBalance(tenantID).then(balance => {
      const line = balance.lines.find(line => line.id == id)

      if (line.category == 'deposit') {
        return this.http.delete(`${environment.backend.url}/transaction/deposit/${id}/`).toPromise()
      }

      if (line.category == 'rent') {
        return this.http.delete(`${environment.backend.url}/transaction/rent/${id}/`).toPromise()
      }

      return null;
    })
  }

  getTransactionsByTenant(workspaceID: string, tenant: string): Promise<Transaction[]> {
    return this.db.getCollection<Transaction>(`workspace/${workspaceID}/transaction/`, 'transaction').then(
      (records: Transaction[]) => records.filter(
        transaction => transaction.tenant == tenant
      ).sort((a: Transaction, b: Transaction) => {
        if (a.operation_date < b.operation_date) {
          return 1;
        }

        if (a.operation_date > b.operation_date) {
          return -1;
        }

        return 0;
      })
    );
  }

  getRentsByTenant(tenantID: string): Promise<any[]> {
    return this.http.get<any[]>(`${environment.backend.url}tenant/${tenantID}/all_rents/`).toPromise();
  }

  getGuarantor(id: string): Promise<Guarantor> {
    return this.db.get<Guarantor>('guarantor', id);
  }

  getGuarantorsByTenant(tenant: string): Promise<Guarantor[]> {
    return this.getTenant(tenant).then(tenant => tenant.guarantors);
  }

  updateRent(tenantID: string, monthly_rent, monthly_charges, includeCurrentMonth) {
    return Promise.all([this.getTenant(tenantID), this.getRentsByTenant(tenantID)]).then(
      async result => {
        const tenant = result[0];
        const rents = result[1];

        if (includeCurrentMonth && rents.length == 1) {
          const currentRent = rents[0];

          currentRent.monthly_rent = monthly_rent;
          currentRent.monthly_charges = monthly_charges;
          currentRent.workspace = tenant.workspace;

          await this.http.put(`${environment.backend.url}transaction/rent/api/`, currentRent).toPromise();
        }

        return tenant;
      }
    ).then((tenant) => {
      tenant.monthly_rent = monthly_rent;
      tenant.monthly_charges = monthly_charges;

      return this.db.save(tenant);
    }).catch(error => {
      if (error['detail']) {
        throw error['detail'];
      }

      throw 'Une erreur est survenue pendant la mise à jour du loyer.';
    })
  }
}
