import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-realty-selection',
  templateUrl: './realty-selection.component.html',
  styleUrls: ['./realty-selection.component.scss']
})
export class RealtySelectionComponent implements OnInit {
  types = [
    {
      name: 'Maison',
      url: '/bien-immobilier/-1?sysparm_default=property_type=h',
      icon: 'house'
    },
    {
      name: 'Appartement',
      url: '/bien-immobilier/-1?sysparm_default=property_type=a',
      icon: 'home'
    },
    {
      name: 'Immeuble',
      url: '/immeuble/-1',
      icon: 'apartment'
    },
    {
      name: 'Cave',
      url: '/bien-immobilier/-1?sysparm_default=property_type=ce',
      icon: 'wine_bar'
    },
    {
      name: 'Garage / Parking',
      url: '/bien-immobilier/-1?sysparm_default=property_type=p',
      icon: 'directions_car'
    },
    // {
    //   name: 'Local commercial',
    //   url: '/bien-immobilier/-1?sysparm_default=property_type=c',
    //   icon: 'storefront'
    // }
  ];

  constructor(private location: Router, private dialog: NbDialogRef<RealtySelectionComponent>) { }

  ngOnInit(): void {
  }

  goTo(type): void {
    this.location.navigateByUrl(type.url);
    this.dialog.close();
  }
}
