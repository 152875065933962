import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { PlaidService } from 'src/app/@finance/services/plaid/plaid.service';
import { Transaction } from 'src/app/models/transaction/transaction';

@Component({
  selector: 'app-bank-account-transactions',
  templateUrl: './bank-account-transactions.component.html',
  styleUrls: ['./bank-account-transactions.component.scss']
})
export class BankAccountTransactionsComponent implements OnInit {
  @Input() lineID: string;
  @Input() amount: number;
  @Input() category: string;
  @Input() tenantID: string;
  @Input() selection: boolean;

  _transactions: Transaction[];
  transactions: Transaction[];
  loaded: boolean;
  keywords: string;

  constructor(private plaid: PlaidService, private workspace: WorkspaceService, private snackbar: UINotificationService, private dialog: NbDialogRef<BankAccountTransactionsComponent>) { }

  ngOnInit(): void {
    this.loaded = false;
    this.workspace.current.subscribe(current =>
      this.plaid.getAccountTransactions(current.id, '3').then(
        (transactions) => {
          console.log(transactions);
          this._transactions = transactions || [];
          this.onFilter()
        }
      ).catch(error => this.snackbar.alert(error, 'danger')
      ).finally(() => this.loaded = true)
    )
  }

  onFilter() {
    this.transactions = this._transactions.filter(transaction => {
      if(!this.keywords){
        return true;
      }

      return transaction.short_description.toLowerCase().indexOf(this.keywords.toLowerCase()) > -1
    })
  }

  onClose() {
    this.dialog.close();
  }
}
