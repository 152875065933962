import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams
} from '@angular/common/http';

import { AuthenticationService } from './services/authentication/authentication.service';
import { environment } from 'src/environments/environment';
import { WorkspaceService } from './services/workspace/workspace.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthenticationService, private workspace: WorkspaceService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.auth.accessToken;

    if (!accessToken) {
      return next.handle(request);
    }

    if(request.url.startsWith(environment.backend.url)){
      let headers = request.headers.set('Authorization', `Bearer ${accessToken}`);

      const authRequest = request.clone({
        headers: headers,
      });

      return next.handle(authRequest);
    }

    return next.handle(request);
  }
}
