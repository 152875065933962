import { Record } from '../record/record';

export class Attachment extends Record {
    id: string;
    file: string;
    workspace: string;
    name: string;
    format: string;
    content_type: string;
    object_id: string

    constructor(){
        super('attachment');
    }
}
