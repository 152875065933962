import { Injectable } from '@angular/core';
import { Document } from 'src/app/models/document/document';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { DocumentTemplate } from 'src/app/models/document-template';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FileStorageService } from 'src/app/@core/services/file-storage/file-storage.service';
import { Attachment } from 'src/app/@core/models/attachment/attachment';
import { catchError, map } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class DocumentService {

  constructor(private http: HttpClient,
    private auth: AuthenticationService,
    private db: DatabaseService,
    private fileStorage: FileStorageService) {
  }

  create(workspaceID: string, name: string, tenant: string, realty: string, category: string, attachments?: string[]): Promise<Document> {
    return this.http.post<Document>(`${environment.backend.url}document/api/`, {
      name: name,
      tenant: tenant,
      realty: realty,
      category: category,
      workspace: workspaceID
    }).toPromise().then((document: Document) => {
      if (attachments) {
        attachments.forEach(attachement => {
          return this.fileStorage.attach(attachement, 'document', document.id);
        });
      }

      return document;
    }).catch(error => {
      throw new Error(error);
    });
  }

  saveAsDocument(workspaceID: string, id: string, name: string, tenant: string, realty: string, category: string, template: string, content: string): Promise<Document> {
    if (!id) {
      return this.http.post<Document>(`${environment.backend.url}document/api/`, {
        name: name,
        tenant: tenant,
        realty: realty,
        category: category,
        template: template,
        content: content,
        workspace: workspaceID
      }).pipe(
        map(document => {
          console.log(document);
          return document;
        }),
        catchError(error => {
          throw error['detail'] || 'Une erreur est survenue.';
        })
      ).toPromise()
    }

    return this.http.put<Document>(`${environment.backend.url}document/api/`, {
      name: name,
      id: id,
      tenant: tenant,
      realty: realty,
      category: category,
      template: template,
      content: content,
      workspace: workspaceID
    }).pipe(
      catchError(error => {
        throw error['detail'] || 'Une erreur est survenue.';
      })
    ).toPromise();
  }


  getDocument(id: string): Promise<Document> {
    return this.db.get<Document>('document', id);
  }

  getDocuments(workspaceID: string, category?: string): Promise<Document[]> {
    return this.db.getCollection<Document>(`workspace/${workspaceID}/document/`, 'document').then(
      (documents: Document[]) => documents.filter((document: Document) => {
        if (category) {
          return document.category === category;
        }

        return true;
      })
    )
  }

  getQuotations(workspaceID: string, invoiceNumber?: string): Promise<Document[]> {
    return this.getDocuments(workspaceID).then(
      documents => documents.filter(document => {
        let result = document.category === 'q'

        if (invoiceNumber) {
          result = result && document.quotation_number === invoiceNumber;
        }

        return result;
      })
    );
  }

  getInvoices(workspaceID: string, quotationNumber?: string): Promise<Document[]> {
    return this.getDocuments(workspaceID).then(
      documents => documents.filter(document => {
        let result = document.category === 'b'

        if (quotationNumber) {
          result = result && document.invoice_number === quotationNumber;
        }

        return result;
      }).sort((x, y) => {
        if (x.invoice_due_date < y.invoice_due_date) {
          return 1;
        }

        if (x.invoice_due_date > y.invoice_due_date) {
          return -1;
        }

        return 0;
      })
    );
  }

  getDocumentsByProperty(workspaceID: string, realty: string): Promise<Document[]> {
    return this.getDocuments(workspaceID).then(records => records.filter(record => record.realty === realty));
  }

  getDocumentsByTenant(workspaceID: string, tenant: string): Promise<Document[]> {
    return this.getDocuments(workspaceID).then(records =>
      records.filter(record => record.tenant === tenant)
    );
  }

  getTemplates(workspaceID): Promise<DocumentTemplate[]> {
    return this.http.get<DocumentTemplate[]>(`${environment.backend.url}workspace/${workspaceID}/html/templates/`).pipe(
      map(records => records?.map(record => {
        const template = new DocumentTemplate();
        template.id = record.id;
        template.name = record.name;
        template.category = record.category;
        template.standard = record['is_standard'];

        return template;
      })),
      catchError(error => {
        console.warn(error);
        const message = error['detail'] || 'Erreur lors du chargement des modèles de document';

        throw message;
      })
    ).toPromise();
  }

  getTemplate(id: string): Promise<DocumentTemplate> {
    return this.http.get(`${environment.backend.url}pdf/html/${id}/`).toPromise().then(
      record => {
        const template = new DocumentTemplate();
        template.id = id;
        template.name = record['name'];
        template.content = record['html_code'];
        template.standard = record['is_standard'];
        template.category = record['category'];
        template.content = record['html_code'];

        return template;
      }
    ).catch(error => {
      console.warn(error);
      throw error;
    });
  }

  deleteTemplate(id: string) {
    return this.http.delete(`${environment.backend.url}pdf/html/delete/${id}/`).toPromise().then(
      result => result
    ).catch(error => console.warn(error));
  }

  duplicateTemplate(workspaceID: string, template: DocumentTemplate, name: string): Promise<DocumentTemplate> {
    return this.http.post(`${environment.backend.url}pdf/html/api/`, {
      name: name,
      html_code: template.content,
      workspace: workspaceID,
      is_standard: false
    }).toPromise().then(record => {
      let template = new DocumentTemplate();

      template.id = record['id'];
      template.name = record['name'];
      template.content = record['html_code'];
      template.standard = record['is_standard'];

      return template;
    });
  }

  createAttachmentFromHtml(workspaceID: string, fileName: string, html: string, table: string, target: string): Promise<Attachment> {
    return this.http.post<Attachment>(`${environment.backend.url}file/html/api/`, {
      name: fileName,
      html: html,
      content_type: table,
      workspace: workspaceID,
      object_id: target
    }).pipe(
      catchError(error => {
        console.log(error);
        throw error['detail'] || 'Erreur lors de la création de la pièce jointe.';
      })
    ).toPromise();
  }

  createDocumentWithAttachment(document: Document, file: File): Promise<Document> {
    return this.db.save(document).then(
      (result: Document) => this.fileStorage.upload(file, 'document', result.id).then(
        attachement => result
      ).catch(error => {
        console.log(error);
        throw error['detail'] || 'Erreur lors de la création du document avec la pièce jointe.';
      })
    ).catch(error => {
      console.log(error);
      throw error['detail'] || 'Erreur lors de la création du document avec la pièce jointe.';
    });
  }

  saveTemplate(workspaceID: string, template: DocumentTemplate): Promise<DocumentTemplate> {
    if (template.id) {
      return this.http.put(`${environment.backend.url}pdf/html/api/`, {
        id: template.id,
        user: this.auth.user.getValue()?.id,
        name: template.name,
        category: template.category,
        html_code: template.content,
        workspace: workspaceID
      }).pipe(
        catchError(error => {
          throw error['detail'] || 'Une erreur est survenue. Merci de réessayer.'
        })
      ).toPromise().then(
        record => {
          const template = new DocumentTemplate();
          template.id = record['id'];
          template.name = record['name'];
          template.content = record['html_code'];
          template.category = record['category'];
          template.workspace = workspaceID;

          return template;
        }
      );
    }

    return this.http.post(`${environment.backend.url}pdf/html/api/`, {
      name: template.name,
      html_code: template.content,
      category: template.category,
      workspace: workspaceID
    }).toPromise().then(
      record => {
        const template = new DocumentTemplate();
        template.id = record['id'];
        template.name = record['name'];
        template.content = record['html_code'];
        template.category = record['category'];

        return template;
      }
    );
  }

  getVariables(tenant: string, realty: string, month: number, year: number): Promise<any> {
    return this.http.get(`${environment.backend.url}document/${tenant}/${realty}/variables/${month}/${year}/`).toPromise().then(
      data => {
        return data;
      }
    ).catch(error => {
      console.warn(error);
    });
  }
}
