import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './components/layout/layout.component';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { NbActionsModule, NbBadgeModule, NbButtonModule, NbCardModule, NbIconModule, NbInputModule, NbLayoutModule, NbMenuModule, NbSelectModule, NbSidebarModule, NbUserModule } from '@nebular/theme';
import { NotificationsComponent } from './components/notifications/notifications.component';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    NotificationsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NbLayoutModule,
    NbSidebarModule,
    NbMenuModule,
    NbActionsModule,
    NbBadgeModule,
    NbButtonModule,
    NbInputModule,
    NbActionsModule,
    NbIconModule,
    NbUserModule,
    NbSelectModule,
    NbCardModule
  ],
  exports: [
    LayoutComponent
  ]
})

export class LayoutModule { }
