import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-contract',
  templateUrl: './send-contract.component.html',
  styleUrls: ['./send-contract.component.scss']
})
export class SendContractComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
