import { first } from 'rxjs/operators';

export class Contact {
    id?: string;
    company: string;
    name: string;
    phone: string;
    email: string;
    category: string;
    short_description?: string;
    address: string;
    website?: string;

    constructor(firstName, lastName, email, phone){
     this.name = `${firstName} ${lastName}`;

     if (!lastName) {
        this.name = firstName;
     }
     this.phone = phone;
     this.email = email;
    }
}
