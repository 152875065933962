import { Record } from 'src/app/@core/models/record/record';
import { StripeSubscription } from 'src/app/@subscription/models/stripe-subscription';
import { WorkspaceMember } from '../workspace-member/workspace-member';

export class Workspace extends Record{
    id?: string;
    short_description: string;
    members?: WorkspaceMember[];
    storage_files: number;
    storage_available: number;
    storage_file_uploaded: number;
    subscription?: StripeSubscription;
    webhook_enabled: boolean;
    plaid_access_token: {
        id: string;
        access_token: string;
        item_id: string;
        sync_required: boolean;
        message?: string;
    }

    constructor(id: string){
        super('workspace', id);
    }
}
