import { Injectable } from '@angular/core';
import { DatabaseService } from '../@core/services/database/database.service';
import { Building } from '../models/building';
import { RealtyGroup } from '../models/realty-group';

@Injectable({
  providedIn: 'root'
})

export class RealtyGroupService {
  space: string;

  constructor(private db: DatabaseService) {
  }

  getBuildings(workspaceID: string): Promise<Building[]>{
    return this.db.getCollection<Building>(`workspace/${workspaceID}/realtygroup/`, 'realtygroup').then(
      (realties: RealtyGroup[]) => realties.filter(realty => realty.category === 'b')
    )
  }

  getBuilding(id: string): Promise<Building>{
    return this.db.get<Building>('realtygroup', id);
  }
}
