import { Component, OnInit } from '@angular/core';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { ActivatedRoute } from '@angular/router';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { DateUtil } from 'src/app/@core/util/date-util';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})

export class TransactionComponent implements OnInit {
  sections: FormSection[];
  options: FormOptions = {
    title: {
      default: 'Nouvelle transaction',
      prefix: 'Transaction',
      fields: 'short_description'
    },
    page: 'transaction',
    redirectTo: 'transactions'
  };
  loading: boolean;
  record: Transaction;
  error: string;
  actions: FormAction[];
  payments: RelatedList = {
    name: 'payments',
    columns: [
      { name: 'short_description', label: 'Description courte', type: 'string' },
      { name: 'amount', label: 'Montant', type: 'amount' },
    ],
    records: [],
  };

  constructor(private router: ActivatedRoute,
    private db: TransactionService,
    private tenantService: TenantService,
    private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.loading = true;
      this.loadSections(workspace.id).then(() =>
        this.router.paramMap.subscribe(params => {
          const id = params.get('id');

          if (id !== '-1') {
            this.db.getTransaction(id)
              .then(record => this.record = record)
              // .then(() => this.actions = [
              //   {
              //     label: 'Détails des transactions',
              //     name: 'split-transaction',
              //     type: 'script',
              //     onClick: (dialog, record: Record) => {
              //       dialog.open(SplitTransactionComponent, {
              //         context: {
              //           id: record.id,
              //           table: record.table
              //         }
              //       });
              //     }
              //   }
              // ])
              .catch(exception => this.error = exception.message || 'Une erreur est survenue. Merci de réessayer.')
              .finally(() => this.loading = false);
          } else {
            this.record = new Transaction(null);
            this.loading = false;
          }
        })
      )
    })
  }

  loadSections(workspaceID: string): Promise<FormSection[]> {
    return new Promise((resolve, reject) => {

      this.sections = [
        {
          displayTitle: false,
          columns: [
            {
              elements: [
                {
                  label: 'Libellé', field: 'short_description', type: 'string', isRequired: true,
                },
                { label: 'Date d\'opération', field: 'operation_date', type: 'date', isRequired: true },
                {
                  label: 'Montant', field: 'amount', type: 'amount', isRequired: true
                },
                {
                  label: 'Référence', field: 'reference', type: 'string'
                },
                {
                  label: 'Compte bancaire', field: 'bank_account', type: 'reference',
                  reference: 'bank-account',
                  page: 'compte-bancaire',
                  url: `workspace/${workspaceID}/bank-account/`,
                  displayValue: 'bank',
                },
              ]
            },
            {
              elements: [
                {
                  label: 'Catégorie', field: 'category', type: 'choice',
                  isRequired: true, choices: new ChoiceList().getChoices('transaction', 'category'),
                  onChange: () => {
                    if(this.record.category == 'r'){
                      this.record.deposit = null;
                    }

                    if(this.record.category == 'd'){
                      this.record.rent = null;
                    }
                  }
                },
                {
                  label: 'Locataire',
                  field: 'tenant',
                  type: 'reference',
                  reference: 'tenant',
                  page: 'locataire',
                  url: `workspace/${workspaceID}/tenant/`,
                  displayValue: 'first_name,last_name',
                  onChange: () => {
                    this.setDepositAndRent();
                    this.setRealty();
                  },
                  isVisible: (transaction: Transaction) => {
                    return (transaction.category === 'r' || transaction.category === 'd');
                  }
                },
                {
                  label: 'Loyer',
                  field: 'rent',
                  type: 'reference',
                  reference: 'rent',
                  url: `workspace/${workspaceID}/rent/`,
                  dependantFrom: 'tenant',
                  displayValue: 'short_description',
                  isRequired: () => {
                    return this.record.category === 'r' && this.record.tenant;
                  },
                  isVisible: () => {
                    return this.record.category === 'r' && this.record.tenant;
                  }
                },
                {
                  label: 'Bien immobilier', field: 'realty', type: 'reference',
                  reference: 'realty',
                  page: 'bien-immobilier',
                  url: `workspace/${workspaceID}/realty/`,
                  displayValue: 'short_description',
                },
                {
                  label: 'Immeuble', field: 'realty_group', type: 'reference',
                  reference: 'realtygroup',
                  page: 'immeuble',
                  url: `workspace/${workspaceID}/realtygroup/`,
                  displayValue: 'short_description'
                },
                {
                  label: 'Entreprise',
                  field: 'company',
                  type: 'reference',
                  reference: 'company',
                  page: 'entreprise',
                  url: `workspace/${workspaceID}/company/`,
                  displayValue: 'name',
                  isVisible: (transaction: Transaction) => {
                    return transaction.category === 'b';
                  }
                },
                {
                  label: 'Facture',
                  field: 'document',
                  type: 'reference',
                  reference: 'document',
                  page: 'document',
                  url: `workspace/${workspaceID}/document/`,
                  displayValue: 'name',
                  refQualifier: [{
                    field: 'category',
                    operator: '==',
                    value: 'b'
                  }],
                  isVisible: (transaction: Transaction) => {
                    return transaction.category === 'b';
                  }
                },
                {
                  label: 'Reconcilié',
                  field: 'balanced',
                  type: 'checkbox',
                },
              ]
            },
          ]
        },
        {
          columns: [
            {
              elements: [
                {
                  field: 'memo', label: 'Mémo', type: 'textarea'
                }
              ]
            }
          ]
        }
      ]

      resolve(this.sections);
    })
  }

  setRealty(): void{
    if(this.record.tenant){
      this.tenantService.getTenant(this.record.tenant.toString()).then(tenant => {
        this.record.realty = tenant.realty
      })
    }
  }

  setDepositAndRent(): void {
    if (this.record.category = 'r') {
      this.record.deposit = null;
      this.tenantService.getBalance(this.record.tenant as string).then(
        balance => {
          const rent = balance.lines.find(line => {
            const operationDate = new DateUtil().setDate(this.record.operation_date.toString());
            const dueDate = new DateUtil().setDate(this.record.operation_date.toString());

            return operationDate.getMonth() == dueDate.getMonth() &&
              operationDate.getUTCFullYear() == dueDate.getUTCFullYear() &&
              line.category == 'rent';
          });

          if (rent) {
            this.record.rent = rent.id;
          }
        })
    }
  }
}
