import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { PropertyService } from 'src/app/services/property/property.service';
import { Property } from 'src/app/models/property/property';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { RoomService } from 'src/app/services/room/room.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-property-asset',
  templateUrl: './property-asset.component.html',
  styleUrls: ['./property-asset.component.scss']
})

export class PropertyAssetComponent implements OnInit {
  loading: boolean;
  table = 'realty';
  options: FormOptions = {
    title: {
      default: 'Nouveau bien',
      prefix: 'Bien immobilier',
      fields: 'short_description'
    },
    page: '/bien-immobilier',
    redirectTo: 'patrimoine'
  }
  record: Property;
  sections: FormSection[];

  tenants: RelatedList = {
    name: 'tenants',
    columns: [
      {
        label: 'Prénom',
        name: 'first_name',
        type: 'string'
      },
      {
        label: 'Nom',
        name: 'last_name',
        type: 'string'
      },
      {
        label: 'Email',
        name: 'email',
        type: 'email'
      },
      {
        label: 'Téléphone',
        name: 'phone',
        type: 'phone'
      },
      {
        label: 'Loyer',
        name: 'monthly_rent_with_charges',
        type: 'amount'
      }
    ],
    records: null
  };

  apartments: RelatedList = {
    name: 'realties',
    columns: [
      { name: 'short_description', label: 'Description courte', type: 'string' },
      { name: 'rental_type', label: 'Type de location', type: 'choice' },
      { name: 'filling_rate', label: 'Taux de remplissage', type: 'percentage' },
    ],
    records: null
  };

  rooms: RelatedList = {
    name: 'rooms',
    columns: [
      { name: 'short_description', label: 'Description courte', type: 'string' },
      { name: 'living_area', label: 'Surface', type: 'string', suffix: 'm²' },
      { name: 'tenant', label: 'Locataire', type: 'reference', reference: 'tenant', displayValue: 'first_name,last_name' },
      { name: 'monthly_rent', label: 'Loyer mensuel HC', type: 'amount' },
      { name: 'monthly_charges', label: 'Charges mensuelles', type: 'amount' },
      { name: 'monthly_rent_with_charges', label: 'Loyer mensuel CC', type: 'amount' }
    ],
    records: null
  };

  documents: RelatedList = {
    loading: true,
    name: 'documents',
    columns: [
      { name: 'name', label: 'Nom du document', type: 'string' },
      { name: 'category', label: 'Catégorie', type: 'choice' }
    ],
    records: null
  }

  actions: FormAction[];
  hasRoom: boolean;
  error: string;

  constructor(private db: PropertyService,
    private router: ActivatedRoute,
    private auth: AuthenticationService,
    private tenant: TenantService,
    private room: RoomService,
    private document: DocumentService,
    private notification: UINotificationService,
    private workspaceService: WorkspaceService) {

  }

  ngOnInit(): void {
    this.loading = true;

    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.record = null;
        return;
      }

      this.loadSections(workspace.id).then(() => this.router.paramMap.subscribe(params => {
        const id = params.get('id');
        this.record = new Property(this.auth.user.getValue().id);

        if (id !== '-1') {
          this.db.getProperty(id).then((record: Property) => {
            this.record = record;
          }).then(() => {
            let defaultValues = `rental_type=${this.record.rental_type}^realty=${this.record.id}`;
            defaultValues += `^monthly_rent=${this.record.monthly_rent}^` +
              `monthly_charges=${this.record.monthly_charges}^` +
              `charges_details=${this.record.charges_details}`;

            if(this.record.type_of_furniture == 'furnished'){
              defaultValues += '^furnished=yes';
            }else{
              defaultValues += '^furnished=no';
            }

            this.actions = [
              {
                name: 'new_tenant',
                label: 'Nouveau locataire',
                link: '/locataire/-1',
                type: 'link',
                primary: true,
                queryParams: {
                  sysparm_default: defaultValues
                },
              },
              {
                name: 'new_room', label: 'Ajouter une chambre', type: 'link',
                link: '/appartement/chambre/-1',
                queryParams: {
                  sysparm_default: defaultValues
                },
                isVisible: (record: Property) => {
                  return (record.property_type === 'a' || record.property_type === 'h') &&
                    record.rental_type === 's';
                },
              }
            ];
          }).then(() =>
            Promise.all([
              this.tenant.getTenantsByProperty(workspace.id, id),
              this.db.getApartments(workspace.id, id),
              this.room.getRoomsByProperty(workspace.id, id),
              this.document.getDocumentsByProperty(workspace.id, id)
            ]).then(result => {
              let defaultValues = `rental_type=${this.record.rental_type}^realty=${this.record.id}`;

              defaultValues += `^monthly_rent=${this.record.monthly_rent}^` +
                `monthly_charges=${this.record.monthly_charges}^` +
                `charges_details=${this.record.charges_details}`;

              if(this.record.type_of_furniture == 'furnished'){
                defaultValues += '^furnished=yes';
              }else{
                defaultValues += '^furnished=no';
              }

              this.hasRoom = result[2]?.length > 0;

              this.tenants.records = result[0];
              this.tenants.actions = [
                {
                  name: 'new_tenant',
                  label: 'Nouveau locataire',
                  link: `/locataire/-1`,
                  primary: true,
                  queryParams: { 
                    sysparm_default: defaultValues
                  },
                  type: 'link'
                }
              ];

              this.apartments.actions = [
                {
                  name: 'new_property',
                  label: 'Nouvelle appartement',
                  type: 'link',
                  link: '/bien-immobilier/-1',
                  primary: true,
                  queryParams: {
                    sysparm_default: `property_type=a^address=${this.record?.address}^building=${this.record.id}`
                  }
                }
              ];

              this.apartments.records = result[1];

              this.rooms.records = result[2];
              this.rooms.actions = [
                {
                  name: 'new_room', label: 'Nouvelle chambre', type: 'link', primary: true,
                  link: '/appartement/chambre/-1', 
                  queryParams: {
                    sysparm_default: `realty=${this.record?.id}^charges_details=${this.record.charges_details}` }
                }
              ];

              this.documents.loading = false;
              this.documents.records = result[3];
              this.documents.actions = [
                {
                  name: 'new_document', label: 'Nouveau document', type: 'link',
                  link: '/document/-1', queryParams: { sysparm_default: `realty=${this.record?.id}` }
                }
              ];
            }).catch(exception => {
              this.notification.snack(exception, 'danger');
            }).finally(() => this.loading = false)
          ).catch(error => this.error = error
          ).finally(() => this.loading = false);
        } else {
          this.loading = false;
        }
      }));

    })
  }

  loadSections(workspaceID): Promise<void> {
    return new Promise((resolve, reject) => {
      this.sections = [
        {
          title: 'Informations générales',
          displayTitle: false,
          columns: [
            {
              elements: [
                {
                  field: 'short_description',
                  label: 'Libellé',
                  type: 'string',
                  isRequired: true,
                },
                {
                  field: 'landlord',
                  label: 'Propriétaire',
                  type: 'reference',
                  url: `workspace/${workspaceID}/users/`,
                  reference: 'user',
                  displayValue: 'name',
                  isRequired: true
                },
                {
                  field: 'address',
                  label: 'Adresse',
                  type: 'location',
                  isRequired: true,
                  rows: 3
                },
                {
                  field: 'legal_living_area',
                  label: 'Surface',
                  type: 'string',
                  isRequired: true,
                  suffix: 'm2'
                }
              ]
            },
            {
              elements: [
                {
                  field: 'property_type',
                  label: 'Type de bien',
                  type: 'choice',
                  choices: new ChoiceList().getChoices(this.table, 'property_type'),
                  isRequired: true,
                  onChange: () => {
                    if (this.record.property_type === 'c') {
                      this.record.rental_type = 'c';
                    }

                    if (this.record.property_type === 'h') {
                      this.record.type_of_habitat = null;
                    }
                  }
                },
                {
                  field: 'realtygroup',
                  label: 'Immeuble',
                  type: 'reference',
                  url: `workspace/${workspaceID}/realtygroup/`,
                  reference: 'realtygroup',
                  displayValue: 'short_description',
                  refQualifier: [
                    { field: 'category', operator: '==', value: 'b' }
                  ],
                  isVisible: (realty: Property) => {
                    return realty.property_type === 'a';
                  }
                },
                {
                  field: 'rental_type',
                  label: 'Type de location',
                  type: 'choice',
                  choices: new ChoiceList().getChoices(this.table, 'rental_type'),
                  isVisible: () => {
                    return this.record.property_type === 'h' || this.record.property_type === 'a';
                  },
                  isRequired: () => {
                    return this.record.property_type === 'h' || this.record.property_type === 'a';
                  },
                  dependantFrom: 'property_type'
                },
                {
                  field: 'type_of_furniture',
                  label: 'Meublé / Non meublé',
                  type: 'choice',
                  choices: new ChoiceList().getChoices(this.table, 'type_of_furniture'),
                  isRequired: (record: Property) => {
                    return record.property_type === 'a' || record.property_type === 'h';
                  },
                  isVisible: (record: Property) => {
                    return record.property_type === 'a' || record.property_type === 'h';
                  },
                  defaultValue: 'u'
                },
              ]
            }
          ],
        },
        {
          title: 'Informations financières',
          displayTitle: true,
          columns: [
            {
              elements: [
                {
                  field: 'price',
                  type: 'amount',
                  label: 'Prix d\'achat'
                },
                {
                  field: 'renovation_cost',
                  type: 'amount',
                  label: 'Montant des travaux de rénovation'
                },
                {
                  field: 'notary_cost',
                  type: 'amount',
                  label: 'Frais de notaire'
                },
                {
                  field: 'agency_cost',
                  type: 'amount',
                  label: 'Frais d\'agence'
                },
              ]
            },
            {
              elements: [
                {
                  field: 'monthly_rent',
                  label: 'Loyer mensuel HC',
                  type: 'amount',
                  hint: 'Loyer mensuel par défaut lors de la création d\'un locataire',
                  isRequired: () => {
                    return this.record.rental_type != 's' || !this.hasRoom;
                  },
                  isVisible: () => {
                    return this.record.rental_type != 's' || !this.hasRoom;
                  },
                },
                {
                  field: 'monthly_charges',
                  label: 'Charges mensuelles',
                  type: 'amount',
                  hint: 'Charges menusuelles par défaut lors de la création d\'un locataire',
                  isRequired: () => {
                    return this.record.rental_type != 's' || !this.hasRoom;
                  },
                  isVisible: () => {
                    return this.record.rental_type != 's' || !this.hasRoom;
                  }
                },
                {
                  field: 'monthly_rent_with_charges',
                  label: 'Loyer charges comprises',
                  type: 'amount',
                  hint: 'Loyer total des chambres',
                  isRequired: () => {
                    return this.record.rental_type == 's' && this.hasRoom;
                  },
                  isVisible: () => {
                    return this.record.rental_type == 's' && this.hasRoom;
                  },
                  isReadOnly: true
                },
                {
                  field: 'charges_details',
                  label: 'Détails des charges',
                  type: 'textarea',
                  hint: 'Détails des charges par défaut lors de la création d\'un locataire',
                  isRequired: false,
                }
              ]
            }
          ]
        },
        {
          title: 'Description du logement',
          displayTitle: true,
          isVisible: () => {
            return (this.record.property_type === 'h' ||
              this.record.property_type === 'a');
          },
          columns: [
            {
              elements: [
                // {
                //   field: 'building_year',
                //   type: 'integer',
                //   label: 'Année de construction du bâtiment'
                // },
                {
                  field: 'construction_period',
                  type: 'choice',
                  label: 'Période de construction',
                  choices: new ChoiceList().getChoices('realty', 'construction_period')
                },
                {
                  field: 'type_of_habitat',
                  type: 'choice',
                  label: 'Type d\'habitat',
                  choices: new ChoiceList().getChoices('realty', 'type_of_habitat'),
                  isVisible: () => {
                    return this.record.property_type === 'a';
                  },
                },
                {
                  field: 'use_of_premises',
                  type: 'choice',
                  label: 'Destination des locaux',
                  defaultValue: 'l',
                  choices: new ChoiceList().getChoices('realty', 'use_of_premises'),
                },
                {
                  field: 'equipment',
                  label: 'Liste d\équipements',
                  type: 'choice',
                  multiple: true,
                  choices: new ChoiceList().getChoices('realty', 'amenities')
                },
                {
                  field: 'legal_system',
                  type: 'choice',
                  label: 'Régime juridique',
                  choices: new ChoiceList().getChoices('realty', 'legal_system')
                },
                {
                  field: 'nb_main_room',
                  type: 'choice',
                  label: 'Nombre de pièce(s) principale(s)',
                  choices: new ChoiceList().getChoices('realty', 'nb_main_room')
                }
              ]
            },
            {
              elements: [
                {
                  field: 'heating_type',
                  type: 'choice',
                  label: 'Type de chauffage',
                  choices: new ChoiceList().getChoices(this.table, 'heating_type')
                },
                {
                  field: 'heating_energy',
                  type: 'choice',
                  label: 'Chauffage',
                  choices: new ChoiceList().getChoices(this.table, 'heating_energy')
                },
                {
                  field: 'electricity_provider',
                  type: 'string',
                  label: 'Fournisseur d\'électricité',
                  isVisible: (property: Property) => {
                    return property.heating_energy === 'e';
                  }
                },
                {
                  field: 'gaz_provider',
                  type: 'string',
                  label: 'Fournisseur gaz',
                  isVisible: (property: Property) => {
                    return property.heating_energy === 'g';
                  }
                },
                {
                  field: 'water_provider',
                  type: 'string',
                  label: 'Fournisseur d\'eau'
                }
              ]
            }
          ]
        }
      ];
      resolve();
    })
  }
}
