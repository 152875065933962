import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { Observable, Subscription } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Workspace } from 'src/app/@auth/models/workspace/workspace';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { IncidentFormComponent } from 'src/app/components/incident-form/incident-form.component';
import { Product } from 'src/app/@subscription/models/product';
import { StripeSubscription } from 'src/app/@subscription/models/stripe-subscription';
import { SubscribeComponent } from 'src/app/@subscription/pages/subscribe/subscribe.component';
import { SubscriptionService } from 'src/app/@subscription/services/subscription/subscription.service';
import { MenuService } from '../../services/menu/menu.service';
import { NotificationService } from '../../services/notification/notification.service';
import { Notification } from '../notification';
import { NotificationsComponent } from '../notifications/notifications.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  userSubscription: Subscription;
  workspaceSubscription: Subscription;
  isAuthenticated: boolean;
  space: string;
  subscription: StripeSubscription;
  compact: boolean
  products: Product[];
  workspaces: Workspace[];
  notifications: Notification[];

  constructor(private dialog: NbDialogService,
    private breakpointObserver: BreakpointObserver,
    private menu: MenuService,
    private subscriptionService: SubscriptionService,
    public workspace: WorkspaceService,
    public router: Router,
    private notificationService: NotificationService,
    public auth: AuthenticationService) {

  }

  ngOnInit(): void {
    this.subscriptionService.current.subscribe((subscription) => {
      this.subscription = subscription;
    })

    this.userSubscription = this.auth.user.subscribe(user => {
      this.isAuthenticated = !!user

      if(user && user.workspaces){
        this.workspaces = user.workspaces;
      }
    })

    this.workspaceSubscription = this.workspace.current.subscribe(workspace => {
      if(workspace){
        this.space = workspace.id;

        this.notificationService.getNotifications(workspace.id).then(notifications => this.notifications = notifications);
      }
    })
  }

  refresh() {
    const workspace = this.workspaces.find(workspace => workspace.id === this.space);

    if(workspace){
      localStorage.setItem('workspace.current', this.space)
      this.workspace.current.next(workspace);
      this.router.navigate(['/']);
    }
  }

  openSubscription() {
    this.dialog.open(SubscribeComponent, {
      autoFocus: false
    })
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
    .pipe(
      map(result => {
        return result.matches;
      }),
      shareReplay()
    );

  toggleMenu(): void {
    this.menu.toggle();
  }

  onLogout() {
    if(!confirm('Êtes-vous sûr de vouloir quitter Liimmo ?')){
      return;
    }
    this.auth.logout();
  }

  openIncident() {
    this.dialog.open(IncidentFormComponent);
  }

  ngOnDestroy() {
  }

  openNotifications(){
    this.dialog.open(NotificationsComponent, {
      context: {
        notifications: this.notifications
      }
    })
  }
}
