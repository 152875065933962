import { Record } from 'src/app/@core/models/record/record';

export class Property extends Record {
    short_description: string;
    address: string;
    category: string;
    landlord: string;
    property_type: string;
    rental_type: string;
    heating_energy: string;
    heating_type: string;
    building: string;
    monthly_income: string;
    monthly_rent: number;
    monthly_charges: number;
    charges_details: string;
    type_of_furniture: string;
    last_tenant_rent: number;
    room_number?: string;
    legal_system?: string;
    construction_period?: string;
    legal_living_area?: number;
    nb_main_room?: string;
    type_of_habitat: string;
    use_of_premises?: string;
    equipment?: string;
    common_area_description?: string;
    technical_equipement?: string;
    gross_efficiency?: number;
    actual_gross_efficiency?: number;
    net_efficiency?: number;
    actual_net_efficiency?: number;
    price?: number;

    constructor(landlord: string){
        super('realty', null);
        this.landlord = landlord
    }
}
