import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FinancialPerformance, WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { Property } from 'src/app/models/property/property';
import { PropertyService } from 'src/app/services/property/property.service';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CashFlowComponent implements OnInit {
  performance: FinancialPerformance;
  categories = [
    'Cash-flow',
    'Rendement brut',
    'Rendement net'
  ]
  category = 'Cash-flow';
  realties: Property[];
  realty: string = '';
  loaded: boolean;

  constructor(private workspace: WorkspaceService,
    private realtyService: PropertyService,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if(workspace){
        Promise.all([
          this.workspace.getPerformancePerWorkspace(workspace.id),
          this.realtyService.getProperties(workspace.id)
        ]).then(result => {
          this.realties = result[1];
          this.performance = result[0];
        }).catch(
          error => this.notification.snack(error, 'danger')
        ).finally(() => this.loaded = true);
      }
    })
  }

  refresh(){
    this.loaded = false;

    if(this.realty){
      this.realtyService.getFinancialPerformance(this.realty).then(
        performance => this.performance = performance
      ).catch(error => this.notification.snack('Erreur lors du calcul du rendement')
      ).finally(() => this.loaded = true)
    }else{
      this.workspace.getPerformancePerWorkspace(this.workspace.id).then(
        performance => this.performance = performance
      ).catch(error => this.notification.snack('Erreur lors du calcul du rendement')
      ).finally(() => this.loaded = true)
    }
  }
}
