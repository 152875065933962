import { Injectable } from '@angular/core';
import { User } from '../@auth/models/user/user';
import { UserService } from '../@auth/services/user/user.service';
import { ChoiceList } from '../@core/models/choice-list/choice-list';
import { DateUtil } from '../@core/util/date-util';
import { Property } from '../models/property/property';
import { Rent } from '../models/rent';
import { Tenant } from '../models/tenant/tenant';
import { DocumentService } from './document/document.service';
import { PropertyService } from './property/property.service';
import { RentService } from './rent/rent.service';
import { RoomService } from './room/room.service';
import { TenantService } from './tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})

export class DocumentGeneratorService {

  constructor(private documentService: DocumentService,
    private propertyService: PropertyService,
    private userService: UserService,
    private tenantService: TenantService,
    private roomService: RoomService,
    private rentUtil: RentService) {

  }

  createTenantReceipt(rentID: string, templateID: string) {
    return Promise.all([
      this.rentUtil.getRent(rentID),
      this.documentService.getTemplate(templateID)
    ]).then(result => {
      const rent = result[0];
      const template = result[1];

      let content = template.content;

      return this.loadTenantReceiptVariables(rent.tenant, rent.id).then((variables: any[]) => {
        variables.forEach(variable => {
          const placeholder = new RegExp(`\\[${variable.label}\\]`, 'g');
          content = content.replace(placeholder, variable.value);
        });

        return content;
      });
    }).catch(error => {
      console.warn(error)
      throw 'Erreur lors de la création du document.';
    });
  }

  createContract(tenant: Tenant, templateID: string): Promise<string> {
    return Promise.all([
      this.documentService.getTemplate(templateID),
      this.propertyService.getProperty(tenant.realty)
    ]).then(async result => {
      const template = result[0];
      const realty = result[1];
      const landlord = await this.userService.getUser(realty.landlord).catch(
        (error) => {
          console.warn(error);
          return null;
        }
      );

      let content = template.content;

      return this.loadContractVariables(tenant, realty, landlord).then(variables => {
        variables.forEach(variable => {
          const placeholder = new RegExp(`\\[${variable.label}\\]`, 'g');
          content = content.replace(placeholder, variable.value);
        })

        return content;
      });
    });
  }

  loadContractVariables(tenant: Tenant, realty: Property, landlord: User, rent?: Rent): Promise<{ label: string, value: string }[]> {
    return Promise.all([
      this.tenantService.getGuarantorsByTenant(tenant.id),
      this.tenantService.getLastTenantInfo(tenant.id),
      this.tenantService.getRentsByTenant(tenant.id)
    ]).then(async result => {
      let choices = new ChoiceList();
      let variables = [];
      const emptyValue = '<span style="color: red">Non renseigné</span>';
      const guarantors = result[0];
      const lastTenant = result[1];
      const rents = result[2];
      const dateFormat = 'DD MMMM YYYY';

      if (tenant) {
        variables = variables.concat([
          { label: 'Locataire - Prénom', value: tenant.first_name || emptyValue },
          { label: 'Locataire - Nom', value: tenant.last_name || emptyValue },
          { label: 'Locataire - Email', value: tenant.email || emptyValue },
          { label: 'Locataire - Téléphone', value: tenant.phone || emptyValue },
          {
            label: 'Locataire - Date de naissance',
            value: new DateUtil().toFormat(tenant.date_of_birth, dateFormat) || emptyValue
          },
          { label: 'Locataire - Lieu de naissance', value: tenant.place_of_birth || emptyValue },
          { label: 'Locataire - Adresse précédente', value: tenant.last_address || emptyValue },
        ]);
      }

      if (guarantors) {
        const listOfGuarantors = guarantors.reduce((list, guarantor) => {
          list += `<ul>
          <li>Prénom / Nom : ${guarantor.first_name} ${guarantor.last_name}</li>
          <li>Guarant pour le locataire : ${tenant.first_name} ${tenant.last_name}</li>
          <li>Adresse : ${guarantor.address}</li>
          <li>Email : ${guarantor.email}</li>
          <li>Téléphone : ${guarantor.phone}</li>
          </ul></br>`;

          return list;
        }, '');

        variables.push({ label: 'Locataire - Liste des garants', value: listOfGuarantors || emptyValue });
      }

      if (landlord) {
        variables = variables.concat([
          { label: 'Propriétaire - Société;', value: landlord?.name || emptyValue },
          { label: 'Propriétaire - Prénom', value: landlord?.first_name || emptyValue },
          { label: 'Propriétaire - Nom', value: landlord?.last_name || emptyValue },
          { label: 'Propriétaire - Email', value: landlord?.email || emptyValue },
          { label: 'Propriétaire - Téléphone', value: landlord?.phone || emptyValue },
          {
            label: 'Propriétaire - Date de naissance',
            value: new DateUtil().toFormat(landlord?.profile.date_of_birth, dateFormat) || emptyValue
          },
          { label: 'Propriétaire - Lieu de naissance', value: landlord?.profile.place_of_birth || emptyValue },
          { label: 'Propriétaire - Adresse', value: landlord?.address || emptyValue },
        ]);
      }

      // Logement
      if (realty) {
        variables = variables.concat([
          { label: 'Logement - Adresse', value: realty.address },
          { label: 'Logement - Type d\'habitat', value: choices.getChoice('realty', 'nb_main_room', realty.nb_main_room).label || emptyValue }, // T3 bis par exemple
          { label: 'Logement - Régime juridique', value: choices.getChoice('realty', 'legal_system', realty.legal_system).label || emptyValue }, // Mono ou copro
          { label: 'Logement - Période de construction', value: choices.getChoice('realty', 'construction_period', realty.construction_period).label || emptyValue },
          { label: 'Logement - Surface habitable', value: realty.legal_living_area.toLocaleString() || emptyValue },
          { label: 'Logement - Nombre de pièces principales', value: realty.nb_main_room || emptyValue },
          { label: 'Logement - Modalité de production de chauffage', value: choices.getChoice('realty', 'heating_type', realty.heating_type).label || emptyValue },
          { label: 'Logement - Modalité de production d\'eau chaude sanitaire', value: choices.getChoice('realty', 'heating_type', realty.heating_type).label || emptyValue },
          { label: 'Logement - Destination des locaux', value: choices.getChoice('realty', 'use_of_premises', realty.use_of_premises)?.label || emptyValue },
        ]);

        if (tenant.room) {
          let room = await this.roomService.getRoom(tenant.room).catch(error => console.log(error));
          
          if (room) {
            variables.push({
              label: 'Logement - Description des parties privatives',
              value: `<p>Le locataire dispose de la chambre ${room.short_description}.</p><p>Description de la chambre : ${room.description.toLocaleLowerCase()}</p>`
            });
          }
        }

        if (realty.equipment) {
          let equipments = realty.equipment.split(',').reduce((list, amenity) => {
            var choice = new ChoiceList().getChoice('realty', 'amenities', amenity);

            if (choice) {
              list += `<li>${choice.label}</li>`;
            }

            return list;
          }, '');

          equipments = `<ul>${equipments}</ul>`;

          variables.push({ label: 'Logement - Liste des équipements', value: equipments })
        } else {
          variables.push({ label: 'Logement - Liste des équipements', value: 'Aucun équipement signalé par les propriétaires.' })
        }
      }

      // Contrat
      if (tenant) {
        const nextReview = new DateUtil().add(tenant.contract_start_date.toString(), 1, 'y');

        variables = variables.concat([
          {
            label: 'Contrat - Date de début',
            value: new DateUtil().toFormat(tenant.contract_start_date.toLocaleString(), dateFormat)
          },
          { label: 'Contrat - Durée', value: tenant.contract_duration?.toLocaleString() + ' mois' },
          { label: 'Contrat - Justification de la durée', value: tenant.contract_duration_justification },
          { label: 'Contrat - Loyer mensuel hors-charges', value: tenant.monthly_rent?.toFixed(2) },
          { label: 'Contrat - Charges mensuelles', value: tenant.monthly_charges?.toFixed(2) },
          { label: 'Contrat - Loyer mensuel', value: tenant.monthly_rent_with_charges?.toFixed(2) },
          { label: 'Contrat - Loyer soumis au montant maximum', value: new ChoiceList().getChoice('tenant', 'max_rent_evolution', tenant.max_rent_evolution).label },
          { label: 'Contrat - Loyer soumis au loyer de référence', value: new ChoiceList().getChoice('tenant', 'max_rent_legal', tenant.max_rent_legal)?.label },
          { label: 'Contrat - Montant du loyer de référence', value: tenant.ref_rent ? tenant.ref_rent.toFixed(2) + ' €/ m2' : 'non-applicable' },
          { label: 'Contrat - Montant du loyer de référence majoré', value: tenant.ref_max_rent ? tenant.ref_max_rent.toFixed(2) + ' €/ m2' : 'non-applicable' },
          { label: 'Contrat - IRL', value: choices.getChoice('tenant', 'irl', tenant.irl).label },
          // { label: 'Contrat - Montant annuel assurance', value: tenant.yearly_insurance_amount },
          // { label: 'Contrat - Montant récupérable assurance', value: tenant.insurance_amount_left },
          {
            label: 'Contrat - Périodicité du paiement',
            value: 'mensuel'
          },
          {
            label: 'Contrat - Jour de paiement du loyer',
            value: `le ${new DateUtil().toFormat(tenant.rent_due_date.toLocaleString(), 'Do')} du mois` || emptyValue
          },
          {
            label: 'Contrat - Modalité de paiement du loyer',
            value: new ChoiceList().getChoice('tenant', 'rent_paiement_option', tenant.rent_paiement_option).label
          },
          // { label: 'Contrat - Montant hausse ou baisse du loyer', value:  },
          // { label: 'Contrat - Montant d\'application de la hausse ou baisse du loyer', value:  },
          // { label: 'Contrat - Modalité d\'application de la hausse ou baisse du loyer', value:  },
          {
            label: 'Contrat - Prochaine date de révision',
            value: new DateUtil().toFormat(nextReview, dateFormat)
          } //date du contrat + 1
        ])

        let deposit = tenant.monthly_rent;

        if (tenant.furnished) {
          deposit = deposit * 2;
        }

        variables.push({ label: 'Contrat - Dépot de garantie', value: deposit });

        if (lastTenant) {
          const tenant = lastTenant.previous_tenant_last_rent;

          if (tenant) {
            variables.push({ label: 'Contrat - Loyer du dernier locataire', value: tenant.monthly_rent + '€' });
            variables.push({
              label: 'Contrat - Date de versement',
              value: new DateUtil().toFormat(tenant.date, dateFormat)
            });
          } else {
            variables.push({ label: 'Contrat - Loyer du dernier locataire', value: 'non-applicable' });
            variables.push({ label: 'Contrat - Date de versement', value: 'non-applicable' });
          }

          if (lastTenant.last_rent) {
            variables.push({
              label: 'Contrat - Dernière révision du loyer',
              value: new DateUtil().toFormat(lastTenant.last_rent.last_update, dateFormat)
            });
          } else {
            variables.push({ label: 'Contrat - Dernière révision du loyer', value: 'non-applicable' });
          }
        }

        if (rents?.length >= 1) {
          variables.push({ label: 'Contrat - Montant du premier mois de loyer', value: rents[0].amount_due || 'Non-renseigné' });
        }
      }

      variables.push([
        { label: 'Date du jour', value: new Date().toLocaleDateString() }
      ])

      return variables;
    })
  }

  async loadTenantReceiptVariables(tenantID: string, rentID: string): Promise<{ label: string; value: string }[]> {
    const dateFormat = 'DD MMMM YYYY';
    const tenant = await this.tenantService.getTenant(tenantID);
    const balance = await this.tenantService.getBalance(tenantID);
    const rent = await this.rentUtil.getRent(rentID);
    const line = balance.lines.find(line => line.id == rentID);

    return this.documentService.getVariables(rent.tenant, tenant.realty, rent.month, rent.year).then(
      variables => {
        return [
          { label: 'Locataire - Prénom', value: tenant.first_name },
          { label: 'Locataire - Nom', value: tenant.last_name },
          { label: 'Locataire - Email', value: tenant.email },
          { label: 'Locataire - Téléphone', value: tenant.phone },
          { label: 'Locataire - Solde', value: line.balance.toFixed(2) + "€" },

          { label: 'Propriétaire - Société;', value: variables.landlord?.name },
          { label: 'Propriétaire - Prénom', value: variables.landlord?.first_name },
          { label: 'Propriétaire - Nom', value: variables.landlord?.last_name },
          { label: 'Propriétaire - Date de naissance', value: variables.landlord?.date_of_birth },
          { label: 'Propriétaire - Lieu de naissance', value: variables.landlord?.place_of_birth },
          { label: 'Propriétaire - Adresse', value: variables.landlord?.address },
          {
            label: 'Quittance - Période',
            value: `Période du ${new DateUtil().toFormat(rent.start_date, dateFormat)} au ${(new DateUtil().toFormat(rent.end_date, dateFormat))}`
          },
          { label: 'Quittance - Début', value: new DateUtil().toFormat(rent.start_date, dateFormat) },
          { label: 'Quittance - Fin', value: new DateUtil().toFormat(rent.end_date, dateFormat) },
          { label: 'Contrat - Adresse', value: variables.address },
          { label: 'Contrat - Loyer mensuel hors-charges', value: rent.monthly_rent.toFixed(2) + '€' },
          { label: 'Contrat - Charges mensuelles', value: rent.monthly_charges.toFixed(2) + '€' },
          { label: 'Contrat - Loyer charges comprises', value: rent.monthly_rent_with_charges.toFixed(2) + '€' },
          { label: 'Quittance - Date du paiement', value: (rent.due_date as Date).toLocaleDateString() },
          { label: 'Date du jour', value: new Date().toLocaleDateString() }
        ]
      }
    ).catch(error => {
      console.warn(error);
      return [];
    });
  }

  htmlEntities(input: string): string {
    return String(input).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
  }
}
