import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { Contact } from 'src/app/models/contact/contact';
import { ContactService } from 'src/app/services/contact/contact.service';
import { NbDialogService } from '@nebular/theme';
import { ContactCardComponent } from 'src/app/components/contact-card/contact-card.component';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  contacts: Contact[] = [];
  search: string = null;
  loading: boolean;

  constructor(private contactService: ContactService,
    private workspaceService: WorkspaceService,
    private dialog: NbDialogService,
    private clipboard: Clipboard,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.contacts = [];
        return;
      }

      this.loading = true;
      this.contactService.getContacts(workspace.id).then(contacts => {
        this.contacts = contacts.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          if (a.name > b.name) {
            return 1;
          }

          return 0;
        })
      }).finally(() => this.loading = false);
    })
  }

  filter(contacts: Contact[]) {
    if (!this.search) {
      return contacts;
    }

    return contacts.filter(contact =>
      contact.name.toLowerCase().indexOf(this.search) > -1 ||
      contact.short_description?.toLowerCase().indexOf(this.search) > -1)
  }

  onOpenWebsite(website) {
    window.open(website, '_blank');
  }

  onView(contact: Contact) {
    this.dialog.open(ContactCardComponent, {
      context: {
        id: contact.id,
        name: contact.name,
        short_description: contact.short_description,
        email: contact.email,
        phone: contact.phone,
        address: contact.address,
        category: contact.category,
        company: contact.company,
        website: contact.website
      },
      autoFocus: false
    });
  }

  onCopy(value) {
    this.notification.snack('Copié');
    this.clipboard.copy(value);
  }
}
