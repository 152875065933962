import { Component, OnInit } from '@angular/core';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { DocumentTemplate } from 'src/app/models/document-template';
import { DocumentService } from 'src/app/services/document/document.service';

@Component({
  selector: 'app-document-templates',
  templateUrl: './document-templates.component.html',
  styleUrls: ['./document-templates.component.scss']
})

export class DocumentTemplatesComponent implements OnInit {
  loading: boolean;
  records: DocumentTemplate[];
  columns: TableColumn[] = [
    { name: 'name', label: 'Nom du modèle', type: 'string' },
    { name: 'standard', label: 'Modèle standard', type: 'checkbox' },
    { name: 'category', label: 'Catégorie', type: 'choice', choices: new ChoiceList().getChoices('template', 'category') }
  ]

  actions: FormAction[] = [
    { name: 'new_document', label: 'Nouveau modèle', type: 'link', primary: true, link: '/administration/modele-de-document/-1' }
  ]

  constructor(private document: DocumentService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        this.loading = false;
        return;
      }

      this.loading = true;
      this.document.getTemplates(workspace.id).then(
        templates => this.records = templates.sort((templateA, templateB) => {
          if (templateA.standard < templateB.standard) {
            return -1;
          }

          if (templateA.standard > templateB.standard) {
            return 1;
          }

          return 0;
        })
      ).finally(() => this.loading = false);
    })
  }
}
