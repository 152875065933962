import { Component, OnInit } from '@angular/core';
import { RealtyGroupService } from 'src/app/services/realty-group.service';
import { Building } from 'src/app/models/building';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { NbDialogService } from '@nebular/theme';
import { RealtySelectionComponent } from 'src/app/components/realty-selection/realty-selection.component';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.scss']
})
export class BuildingsComponent implements OnInit {
  loading: boolean;
  keywords: string;
  error: string;
  columns: TableColumn[] = [
    { name: 'short_description', label: 'Description courte', type: 'string' },
    { name: 'address', label: 'Addresse', type: 'textarea' },
    { name: 'filling_rate', label: 'Taux de remplissage', type: 'percentage' },
    { name: 'monthly_income', label: 'Revenus mensuels', type: 'amount' }
  ]

  records: Building[];
  actions: FormAction[];

  constructor(private db: RealtyGroupService, private workspaceService: WorkspaceService, private modal: NbDialogService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if(!workspace){
        this.records = [];
        return;
      }

      this.loading = true;
      this.db.getBuildings(workspace.id).then((records: Building[]) => {
        this.records = records;
      }).then(() => {
        this.actions = [
          {
            name: 'new',
            label: 'Ajouter un bien',
            type: 'script',
            primary: true,
            onClick: () => {
              this.modal.open(RealtySelectionComponent);
            }
          }
        ]
      }).catch(error => this.error = error
      ).finally(() => this.loading = false);
    })
  }
}
