import { CalendarEvent } from 'src/app/models/calendar-event';
import { Record } from './record/record';

export class Email extends Record {
    sender: { name: string, email: string } | string;
    recipient: string | { name: string, email: string }[];
    cci: string[];
    bcc: string[];
    title: string;
    body: string;
    event?: CalendarEvent;

    constructor() {
        super('email');
        this.sender = 'contact@liimmo.com';
    }
}
