import { Injectable } from '@angular/core';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { Company } from 'src/app/models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private db: DatabaseService) {
  }

  getCompany(id: string): Promise<Company> {
    return this.db.get<Company>('company', id);
  }

  getCompanies(workspaceID: string): Promise<Company[]> {
    return this.db.getCollection<Company>(`workspace/${workspaceID}/company/`, 'company').catch(error => {
      console.warn(error)
      return [];
    });
  }
}
