import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-import-transactions',
  templateUrl: './import-transactions.component.html',
  styleUrls: ['./import-transactions.component.scss']
})

export class ImportTransactionsComponent implements OnInit {
  file: File;
  uploading: boolean;
  error: string;

  constructor(private transactionService: TransactionService) { }

  ngOnInit(): void {
  }

  importFile(event) {
    this.file = event.target.files[0];
    this.uploading = true;

    this.transactionService.importFromExcel(this.file).catch(
      error => this.error = error
    ).finally(() => this.uploading = false)
  }
}
