import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { Transaction } from 'src/app/models/transaction/transaction';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { NbDialogService } from '@nebular/theme';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { SyncAccountComponent } from 'src/app/@finance/components/sync-account/sync-account.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})

export class TransactionsComponent implements OnInit {
  loading: boolean = true;
  workspaceID: string;
  columns: TableColumn[] = [];

  actions: FormAction[];
  records: Transaction[] = [];

  constructor(private db: TransactionService,
    private workspace: WorkspaceService,
    private router: Router,
    private dialog: NbDialogService) { }

  ngOnInit(): void {
    this.workspace.current.subscribe(workspace => {
      if (!workspace) {
        this.records = [];
        return;
      }

      this.columns = [
        { name: 'short_description', label: 'Libellé', type: 'string' },
        { name: 'amount', label: 'Montant', type: 'amount' },
        { name: 'operation_date', label: 'Date d\'opération', type: 'date' },
        { name: 'realty', label: 'Bien immobilier', type: 'reference', reference: 'realty', displayValue: 'short_description', url: `workspace/${workspace.id}/realty/` },
        { name: 'tenant', label: 'Locataire', type: 'reference', reference: 'tenant', displayValue: 'first_name,last_name', url: `workspace/${workspace.id}/tenant/` },
        { name: 'company', label: 'Entreprise', type: 'reference', reference: 'company', displayValue: 'name', url: `workspace/${workspace.id}/company/` },
        { name: 'category', label: 'Catégorie', type: 'choice', choices: new ChoiceList().getChoices('transaction', 'category') },
        { name: 'created_on', label: 'Date de création', type: 'date' }
      ];

      this.actions = [
        { label: 'Nouvelle transaction', name: 'new', link: '/transaction/-1', type: 'link' },
        {
          label: 'Import Excel', name: 'import_excel', type: 'link', primary: true, link: '/transactions/import', queryParams: { method: 'excel' }
        },
        {
          label: 'Rafraîchir', name: 'refresh', type: 'script', primary: true, onClick: () => {
            this.loadData(this.workspaceID);
          }
        },
        {
          label: 'Synchronisation',
          name: 'sync_transactions',
          type: 'script',
          primary: true,
          onClick: () => {
            if (!workspace.plaid_access_token) {
              this.dialog.open(SyncAccountComponent).onClose.subscribe(
                (token) => {
                  if(token){
                    this.router.navigate(['/transactions/import'], { queryParams: { method: 'auto' }}); 
                  }
                });
            } else {
              this.router.navigate(
                ['/transactions/import'],
                {
                  queryParams:
                    { method: 'auto' }
                }
              )
            }
          }
        }
      ];

      this.workspaceID = workspace.id;
      this.loadData(workspace.id);
    })
  }

  loadData(workspaceID: string): void {
    this.loading = true;
    this.db.getTransactions(workspaceID).then(
      transactions => this.records = transactions
    ).finally(() => this.loading = false);
  }
}
