import { NgModule, LOCALE_ID } from '@angular/core';
import localeFr from '@angular/common/locales/fr';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { TableComponent } from './components/table/table.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { NebularModule } from '../nebular/nebular.module';
import { NbIconModule } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { FormComponent } from './components/form/form.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ListActionsComponent } from './components/list-actions/list-actions.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { UploadTaskComponent } from './components/upload-task/upload-task.component';
import { FormCardComponent } from './components/form-card/form-card.component';
import { DropzoneDirective } from './directives/dropzone/dropzone.directive';
import { FormActionsComponent } from './components/form-actions/form-actions.component';
import { ListCardComponent } from './components/list-card/list-card.component';
import { FormAttachmentsComponent } from './components/form-attachments/form-attachments.component';
import { SafeContentPipe } from './pipes/safe-content.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { DateUtil } from './util/date-util';
import { SnackbarContentComponent } from './components/snackbar-content/snackbar-content.component';
import { RelatedListsComponent } from './components/related-lists/related-lists.component';
import { RelatedList } from './models/related-list/related-list';
import { EmailMessageCardComponent } from './components/email-message-card/email-message-card.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DialogInputComponent } from './components/dialog-input/dialog-input.component';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    TableComponent,
    FormComponent,
    ListActionsComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    FormCardComponent,
    FormActionsComponent,
    ListCardComponent,
    FormAttachmentsComponent,
    SafeContentPipe,
    SnackbarContentComponent,
    RelatedListsComponent,
    EmailMessageCardComponent,
    DialogInputComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    NebularModule,
    NbIconModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MomentDateModule,
    EditorModule
  ],
  exports: [
    TableComponent,
    FormComponent,
    ListActionsComponent,
    FormCardComponent,
    ListCardComponent,
    SafeContentPipe,
    RelatedListsComponent
  ],
  providers: [
    DatePipe,
    DateUtil,
    SafeContentPipe,
    { provide: LOCALE_ID, useValue: 'fr-FR'}
  ]
})
export class CoreModule { }
