import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/document/document.service';
import { Document } from 'src/app/models/document/document';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {
  loading: boolean;
  columns: TableColumn[];
  records: Document[];
  actions: FormAction[] = [
    { name: 'new', label: 'Nouveau contrat', link: '/document/-1', type: 'link', primary: true }
  ]
  constructor(private db: DocumentService, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.columns = [
        { name: 'name', label: 'Nom du document', type: 'string' },
        { name: 'realty', label: 'Bien immobilier', type: 'reference', reference: 'realty', url: `workspace/${workspace.id}/realty/`, displayValue: 'short_description' },
        { name: 'tenant', label: 'Locataire', type: 'reference', reference: 'tenant', url: `workspace/${workspace.id}/tenant/`, displayValue: 'first_name,last_name' }
      ]

      this.loading = true;
      this.db.getDocuments(workspace.id)
        .then((records: Document[]) => this.records = records.filter(
          record => record.category === 'rc'))
        .finally(() => this.loading = false);
    })
  }

}
