import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { Notification } from '../notification';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications: Notification[];

  constructor(private location: Router, private dialog: NbDialogRef<NotificationsComponent>) { }

  ngOnInit(): void {
  }

  onClick(notification: Notification){
    this.location.navigate([notification.link]);
    this.dialog.close();
  }

  onClose(){

  }
}
