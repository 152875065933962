import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbCardModule,
  NbInputModule,
  NbTabsetModule,
  NbAccordionModule,
  NbListModule,
  NbStepperModule,
  NbDialogModule,
  NbBadgeModule,
  NbAlertModule,
  NbFormFieldModule,
  COSMIC_THEME, CORPORATE_THEME, DARK_THEME, NbSpinnerModule, NbTooltipModule, NbCheckboxModule, NbMenuItem, NbLayoutRulerService
} from '@nebular/theme';

import { NbEvaIconsModule } from '@nebular/eva-icons';
import { DEFAULT_THEME } from './styles/theme.default';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule.forRoot(),
  NbMenuModule.forRoot(),
  NbDialogModule.forRoot(),
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  NbInputModule,
  NbTabsetModule,
  NbAccordionModule,
  NbStepperModule,
  NbBadgeModule,
  NbAlertModule,
  NbFormFieldModule,
  NbInputModule,
  NbListModule,
  NbCheckboxModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...NB_MODULES
  ],
  exports: [
    NB_MODULES
  ]
})

export class NebularModule {
  static forRoot(): ModuleWithProviders<NebularModule> {
    return {
      ngModule: NebularModule,
      providers: [
        ...NbSidebarModule.forRoot().providers,
        ...NbThemeModule.forRoot(
          {
            name: 'liimmo',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
