import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { DateUtil } from 'src/app/@core/util/date-util';
import { Rent } from 'src/app/models/rent';
import { Tenant } from 'src/app/models/tenant/tenant';
import { environment } from 'src/environments/environment';
import { TenantService } from '../tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class RentService {

  constructor(private http: HttpClient, private tenant: TenantService) {

  }

  getRent(id: string): Promise<Rent> {
    return this.http.get<Rent>(`${environment.backend.url}transaction/rent/${id}/`).toPromise().then(
      (data) => {
        const rent = { ...data } as Rent;
        rent.due_date = new DateUtil().setDate(rent.date.toString());
        rent.start_date = new DateUtil().setDate(rent.start_date.toLocaleString());
        rent.end_date = new DateUtil().setDate(rent.end_date.toLocaleString());
        rent.month = rent.due_date.getMonth() + 1;
        rent.year = rent.due_date.getFullYear();
        rent.monthly_rent_with_charges = rent.monthly_rent + rent.monthly_charges;
        rent.tenant = data.tenant;

        return rent;
      });
  }

  updateRent(rent: Rent): Promise<Rent> {
    return this.http.put<Rent>(`${environment.backend.url}/transaction/rent/api/`, rent).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error['detail']){
          throw error['detail'];
        }

        throw 'Une erreur est survenue. Veuillez réessayer.'
      })
    ).toPromise();
  }

  deleteRent(id: string): Promise<Rent> {
    return this.http.delete<Rent>(`${environment.backend.url}/transaction/rent/delete/${id}/`).pipe(
      catchError((error: HttpErrorResponse) => {
        if(error['detail']){
          throw error['detail'];
        }

        throw 'Une erreur est survenue. Veuillez réessayer.'
      })
    ).toPromise();
  }
}
