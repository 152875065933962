import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkspaceMember } from 'src/app/@auth/models/workspace-member/workspace-member';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-workspace-member',
  templateUrl: './workspace-member.component.html',
  styleUrls: ['./workspace-member.component.scss']
})
export class WorkspaceMemberComponent implements OnInit {
  options: FormOptions = {
    title: {
      default: 'Nouveau membre',
      prefix: 'Membre',
      fields: 'name'
    },
    page: 'administration/workspace/membre',
    redirectTo: 'administration/workspace',
    permissions: {
      read: true,
      update: true,
      create: false,
      delete: false
    }
  }
  record: WorkspaceMember;
  sections: FormSection[];
  loading: boolean;

  constructor(private router: ActivatedRoute,
    private db: WorkspaceService,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    this.db.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.router.params.subscribe(parameter => {
        const id = parameter['id'];

        this.loading = true;
        this.sections = this._loadSections();
        this.db.getMember(workspace.id, id).then(
          member => this.record = member
        ).then(() => this.loading = false).catch(error => {
          this.notification.snack(error, 'danger');
        })
      })
    })
  }

  _loadSections(): FormSection[] {
    return [
      {
        columns: [
          {
            elements: [
              {
                field: 'name', label: 'Utilisateur', type: 'string', isReadOnly: true
              }
            ],
         },
         {
           elements: [
            {
              field: 'role',
              label: 'Rôle',
              type: 'choice',
              choices: new ChoiceList().getChoices('workspace-member', 'role'),
              isRequired: true
            }
           ]
         }
        ]
      }
    ]
  }
}
