import { Record } from 'src/app/@core/models/record/record';
import { Guarantor } from '../guarantor';
import { Transaction } from '../transaction/transaction';

export class Tenant extends Record {
    active: boolean;
    first_name: string;
    last_name: string;
    name: string;
    date_of_birth: Date;
    place_of_birth: string;
    last_address: string;
    address: string;
    email: string;
    phone: string;
    realty: string;
    room: string;
    shared_room: string;
    monthly_rent: number;
    monthly_charges: number;
    charges_details: string;
    deposit: number;
    deposit_coeff?: 1 | 2;
    contract_start_date: Date;
    contract_end_date: Date;
    contract_state: string;
    rent_due_date: number;
    rent_paiement_option: string;
    agency_fees: number;
    check_in: Date;
    checkout: Date;
    last_tenant_rent: number;
    last_tenant_paiment_date?: Date;
    private_area_description?: string;
    rental_type: string;
    balance: number;
    short_description?: string;
    rents: any[];
    receipt_model: string;
    irl: string;
    tense_area: string;
    furnished: 'yes' | 'no';
    mobility_lease: 'yes' | 'no';
    contract_duration: number;
    contract_duration_justification?: string;
    max_rent_evolution: 'yes' | 'no';
    max_rent_legal: 'yes' | 'no';
    ref_rent?: number;
    ref_max_rent?: number;
    guarantors: Guarantor[];
    transactions: Transaction[];

    constructor(id?: string) {
        super('tenant', id);
    }

    get monthly_rent_with_charges() {
        return this.monthly_rent + this.monthly_charges;
    }
}
