import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PropertyAssetComponent } from './pages/property-asset/property-asset.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PropertyAssetsComponent } from './pages/property-assets/property-assets.component';
import { TenantComponent } from './pages/tenant/tenant.component';
import { AuthGuard } from './@auth/auth-guard.guard';
import { TenantsComponent } from './pages/tenants/tenants.component';
import { TransactionsComponent } from './pages/transactions/transactions.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentComponent } from './pages/document/document.component';
import { InvoicesComponent } from './pages/documents/invoices/invoices.component';
import { BuildingsComponent } from './pages/property-assets/buildings/buildings.component';
import { ApartmentsComponent } from './pages/property-assets/apartments/apartments.component';
import { ContractsComponent } from './pages/documents/contracts/contracts.component';
import { TransactionComponent } from './pages/transaction/transaction.component';
import { RoomComponent } from './pages/room/room.component';
import { HtmlEditorComponent } from './pages/html-editor/html-editor.component';
import { GuarantorsComponent } from './pages/guarantors/guarantors.component';
import { GuarantorComponent } from './pages/guarantor/guarantor.component';
import { DocumentTemplatesComponent } from './pages/administration/document-templates/document-templates.component';
import { DocumentTemplateComponent } from './pages/administration/document-template/document-template.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { QuotationsComponent } from './pages/documents/quotations/quotations.component';
import { WorkspacesComponent } from './pages/administration/workspaces/workspaces.component';
import { WorkspaceComponent } from './pages/administration/workspace/workspace.component';
import { DocumentsImportComponent } from './pages/documents-import/documents-import.component';
import { BasementsComponent } from './pages/property-assets/basements/basements.component';
import { BuildingComponent } from './pages/building/building.component';
import { CompanyComponent } from './pages/company/company.component';
import { TenantBalanceComponent } from './pages/tenant-balance/tenant-balance.component';
import { HousesComponent } from './pages/property-assets/houses/houses.component';
import { ParkingsComponent } from './pages/property-assets/parkings/parkings.component';
import { StoreHousesComponent } from './pages/property-assets/store-houses/store-houses.component';
import { BankAccountsComponent } from './pages/bank-accounts/bank-accounts.component';
import { BankAccountComponent } from './pages/bank-account/bank-account.component';
import { ImportTransactionWizardComponent } from './pages/import-transaction-wizard/import-transaction-wizard.component';
import { TenantContractComponent } from './pages/tenant-contract/tenant-contract.component';
import { TenantContractWizardComponent } from './pages/tenant-contract-wizard/tenant-contract-wizard.component';
import { FinancialDashboardComponent } from './pages/financial-dashboard/financial-dashboard.component';
import { WorkspaceMember } from './@auth/models/workspace-member/workspace-member';
import { WorkspaceMemberComponent } from './pages/administration/workspace-member/workspace-member.component';
import { BankComponent } from './pages/bank/bank.component';
import { EmailComponent } from './pages/email/email.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'tableau-de-bord-financier', component: FinancialDashboardComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./@auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'abonnement',
    loadChildren: () => import('./@subscription/subscription.module').then(m => m.SubscriptionModule)
  },
  { path: 'biens-immobiliers', component: PropertyAssetsComponent, canActivate: [AuthGuard] },
  { path: 'immeubles', component: BuildingsComponent, canActivate: [AuthGuard] },
  { path: 'immeuble/:id', component: BuildingComponent, canActivate: [AuthGuard] },
  { path: 'appartements', component: ApartmentsComponent, canActivate: [AuthGuard] },
  { path: 'maisons', component: HousesComponent, canActivate: [AuthGuard] },
  { path: 'caves', component: BasementsComponent, canActivate: [AuthGuard] },
  { path: 'parkings', component: ParkingsComponent, canActivate: [AuthGuard] },
  { path: 'locaux-commerciaux', component: StoreHousesComponent, canActivate: [AuthGuard] },
  { path: 'appartement/chambre/:id', component: RoomComponent, canActivate: [AuthGuard] },
  { path: 'locataires', component: TenantsComponent, canActivate: [AuthGuard] },
  { path: 'locataires/:state', component: TenantsComponent, canActivate: [AuthGuard] },
  { path: 'locataire/:id', component: TenantComponent, canActivate: [AuthGuard]  },
  { path: 'locataire/garants', component: GuarantorsComponent, canActivate: [AuthGuard] },
  { path: 'locataire/garant/:id', component: GuarantorComponent, canActivate: [AuthGuard]  },
  { path: 'document-pdf', component: TenantContractComponent, canActivate: [AuthGuard]  },
  { path: 'locataires/contrat', component: TenantContractComponent, canActivate: [AuthGuard]  },
  { path: 'locataires/contrat/info', component: TenantContractWizardComponent, canActivate: [AuthGuard]  },
  { path: 'solde-locataire', component: TenantBalanceComponent, canActivate: [AuthGuard]  },
  { path: 'email/:id', component: EmailComponent, canActivate: [AuthGuard]  },
  { path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuard] },
  { path: 'transactions/import', component: ImportTransactionWizardComponent, canActivate: [AuthGuard] },
  { path: 'transaction/:id', component: TransactionComponent, canActivate: [AuthGuard] },
  { path: 'documents', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'documents/devis', component: QuotationsComponent, canActivate: [AuthGuard] },
  { path: 'documents/factures', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'documents/contrats-de-location', component: ContractsComponent, canActivate: [AuthGuard] },
  { path: 'documents/diagnostics', component: ContractsComponent, canActivate: [AuthGuard] },
  { path: 'documents/inventaires', component: ContractsComponent, canActivate: [AuthGuard] },
  { path: 'documents/attestations-assurance', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'documents/etats-des-lieux', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'documents/quittances', component: DocumentsComponent, canActivate: [AuthGuard] },
  { path: 'document-pdf/:id', component: TenantContractComponent, canActivate: [AuthGuard] },
  { path: 'document/:id', component: DocumentComponent, canActivate: [AuthGuard] },
  { path: 'import-de-documents', component: DocumentsImportComponent, canActivate: [AuthGuard] },
  { path: 'administration/modeles', component: DocumentTemplatesComponent, canActivate: [AuthGuard] },
  { path: 'administration/modele-de-document/:id', component: DocumentTemplateComponent, canActivate: [AuthGuard] },
  { path: 'administration/workspaces', component: WorkspacesComponent, canActivate: [AuthGuard] },
  { path: 'administration/workspace/:id', component: WorkspaceComponent, canActivate: [AuthGuard] },
  { path: 'administration/workspace/membre/:id', component: WorkspaceMemberComponent, canActivate: [AuthGuard] },
  { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard] },
  { path: 'entreprise/:id', component: CompanyComponent, canActivate: [AuthGuard] },
  { path: 'bien-immobilier/:id', component: PropertyAssetComponent, canActivate: [AuthGuard]  },
  { path: 'comptes-bancaires', component: BankAccountsComponent, canActivate: [AuthGuard]  },
  { path: 'banque', component: BankComponent, canActivate: [AuthGuard]  },
  { path: 'compte-bancaire/:id', component: BankAccountComponent, canActivate: [AuthGuard]  },
  { path: 'html-editor', component: HtmlEditorComponent, canActivate: [AuthGuard] },
  { path: '**', component: PageNotFoundComponent, pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [AuthGuard]
})

export class AppRoutingModule { }
