import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {

  constructor(private http: HttpClient, private workspace: WorkspaceService) {

  }

  // saveAsTemplate(template: DocumentTemplate){
  //   const workspaceID = this.workspace.current.getValue().id;
  //   const html = this._parseHtml(template.name, template.);

  //   this.http.post(`${environment.backend.url}pdf/html/api/`, {
  //     name: template.name,
  //     html_code: template.content,
  //     workspace: workspaceID
  //   }).toPromise();
  // }

  generate(title: string, html: string): void {
    const space = this.workspace.current.getValue();

    if(!space){
      throw 'Aucun workspace sélectionné';
    }
    
    const url = `${environment.backend.url}pdf/export/`;

    this.http.post(url, {
      name: title,
      content: html,
      workspace: space.id
    }, {
      responseType: 'blob'
    }).toPromise().then((blob: Blob) => {
      saveAs(blob, title);
    })
  }

  _downLoadFile(title: string, data: any, type: string) {
    let blob = new Blob([data], { type: type });
    const a = document.createElement('a')
    let objectUrl = window.URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = title;
    a.click();
    URL.revokeObjectURL(objectUrl);
    // let pwa = window.open(url);

    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //   alert('Please disable your Pop-up blocker and try again.');
    // }
  }

  _parseHtml(title: string, content: string) {
    return `<!doctype html>
              <html lang="fr">
                  <head>
                    <meta charset="utf-8">
                    <title>${title}</title>
                    <style>
                    
                </style>
                </head>
                <body id='page'>
                  ${content}
                </body>
              </html>`;
  }
}
