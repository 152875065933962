import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { SyncAccountComponent } from 'src/app/@finance/components/sync-account/sync-account.component';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { BankAccount } from 'src/app/models/bank-account';
import { BankAccountService } from 'src/app/services/bank-account/bank-account.service';
import { PlaidService } from 'src/app/@finance/services/plaid/plaid.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})

export class BankAccountComponent implements OnInit {
  loading: boolean;
  account: BankAccount;
  sections: FormSection[];
  relatedLists: RelatedList[];
  actions: FormAction[];
  options: FormOptions = {
    title: {
      default: 'Nouveau compte bancaire',
      prefix: 'Compte bancaire',
      fields: 'short_description'
    },
    page: 'compte-bancaire',
    redirectTo: 'comptes-bancaires'
  };
  isSync: boolean;

  constructor(private router: ActivatedRoute,
    private bankService: BankAccountService,
    private plaidService: PlaidService,
    private dialog: NbDialogService,
    private snackbar: UINotificationService,
    private workspace: WorkspaceService) { }

  ngOnInit(): void {
    this.loading = true;
    this._loadSections().then((sections) =>
      this.workspace.current.subscribe(workspace => {
        if (!workspace) {
          return;
        }

        this.isSync = workspace.plaid_access_token != null;

        this.sections = sections;

        this.router.paramMap.subscribe(params => {
          const id = params.get('id');

          if (id !== '-1') {
            Promise.all([this.bankService.getAccount(id), this.bankService.getTransactions(workspace.id, id)]).then(
              result => {
                this.account = result[0];
                const transactions = result[1];

                this.relatedLists = [{
                  name: 'Transactions',
                  columns: [
                    { name: 'short_description', label: 'Libellé', type: 'string' }
                  ],
                  records: transactions,
                  title: `Opération (${transactions.length})`,
                  actions: [
                    {
                      name: 'new_operation',
                      label: 'Ajouter une opération',
                      type: 'link',
                      primary: true,
                      link: '/transaction/-1', queryParams: {
                        sysparm_default: `account=${id}`
                      }
                    }
                  ]
                }]
              }
            ).then(() => {
              this.actions = [
                {
                  name: 'unsync', label: 'Désynchroniser', type: 'script', primary: true,
                  isVisible: this.isSync,
                  onClick: () => {
                    if (!confirm('Êtes-vous sûr.e de vouloir desynchroniser votre compte en banque ?')) {
                      return;
                    }

                    this.plaidService.deleteAccount(workspace.id).then(() => {
                      this.snackbar.snack('Compte désynchronisé', 'info')
                      this.isSync = false;
                    }).catch(
                      (error) => this.snackbar.snack(error, 'danger')
                    )
                  }
                },
                {
                  name: 'sync', label: 'Synchronisation', type: 'script', primary: true,
                  isVisible: !this.isSync,
                  onClick: () => {
                    this.dialog.open(SyncAccountComponent)
                  }
                }
              ]
            }).catch(error => console.warn(error)
            ).finally(
              () => this.loading = false
            );
          } else {
            this.account = new BankAccount();
            this.loading = false;
          }
        })
      })
    )
  }

  private _loadSections(): Promise<FormSection[]> {
    return this.bankService.getInstitions().then(institutions => {

      if (institutions?.length > 0) {
        return [
          {
            columns: [
              {
                elements: [
                  {
                    field: 'bank', label: 'Banque', type: 'choice', isRequired: true, choices: institutions.map(institution => {
                      return {
                        value: institution.institution_id, label: institution.name
                      }
                    })
                  },
                  {
                    field: 'short_description', label: 'Description courte', type: 'string', isRequired: true, hint: 'Ex: compte courant'
                  },
                  {
                    field: 'number', label: 'Numéro de compte', type: 'string', isRequired: true
                  },
                  {
                    field: 'iban', label: 'IBAN', type: 'string'
                  },
                  {
                    field: 'bic', label: 'BIC', type: 'string'
                  }
                ]
              },
              {
                elements: [
                  {
                    field: 'account_manager', label: 'Banquier', type: 'string'
                  },
                  {
                    field: 'account_manager_email', label: 'Email', type: 'email'
                  },
                  {
                    field: 'account_manager_phone', label: 'Téléphone', type: 'phone'
                  },
                  {
                    field: 'address', label: 'Adresse de la banque', type: 'string'
                  },
                ]
              }
            ]
          }
        ];
      }

      return [
        {
          columns: [
            {
              elements: [
                {
                  field: 'bank', label: 'Banque', type: 'string', isRequired: true
                },
                {
                  field: 'short_description', label: 'Description courte', type: 'string', isRequired: true, hint: 'Ex: compte courant'
                },
                {
                  field: 'number', label: 'Numéro de compte', type: 'string', isRequired: true
                },
                {
                  field: 'iban', label: 'IBAN', type: 'string'
                },
                {
                  field: 'bic', label: 'BIC', type: 'string'
                }
              ]
            },
            {
              elements: [
                {
                  field: 'account_manager', label: 'Banquier', type: 'string'
                },
                {
                  field: 'account_manager_email', label: 'Email', type: 'email'
                },
                {
                  field: 'account_manager_phone', label: 'Téléphone', type: 'phone'
                },
                {
                  field: 'address', label: 'Adresse de la banque', type: 'string'
                },
              ]
            }
          ]
        }
      ];
    })
  }
}
