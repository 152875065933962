import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { rotateOutUpRightOnLeaveAnimation } from 'angular-animations';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { Rent } from 'src/app/models/rent';
import { TenantBalanceLine } from 'src/app/models/tenant-balance-line';
import { RentService } from 'src/app/services/rent/rent.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';

@Component({
  selector: 'app-edit-rent',
  templateUrl: './edit-rent.component.html',
  styleUrls: ['./edit-rent.component.scss']
})
export class EditRentComponent implements OnInit {
  @Input() workspace: string;
  @Input() tenantID: string;
  @Input() id: string;

  balanceLine: TenantBalanceLine;
  saving: boolean;
  options = new ChoiceList().getChoices('balance-line', 'status');

  constructor(
    private tenantService: TenantService,
    private rentService: RentService,
    private dialog: NbDialogRef<EditRentComponent>,
    private notification: UINotificationService
  ) { }

  ngOnInit(): void {
    this.rentService.getRent(this.id).then(rent => 
      this.balanceLine = rent
    );
  }

  onClose() {
    this.dialog.close(this.balanceLine);
  }

  onDelete() {
    if (!confirm('Êtes-vous sûr.e de vouloir supprimer ce loyer ?')) {
      return;
    }

    this.saving = true;

    this.tenantService.deleteBalanceLine(this.tenantID, this.id).then(() => {
      this.dialog.close(null);
      this.notification.snack('Loyer supprimé', 'info');
    }).catch(error => {
      this.saving = false;
      this.notification.snack(error, 'danger')
    })
  }

  onSubmit() {
    this.saving = true;

    this.tenantService.updateBalanceLine(this.tenantID, this.balanceLine).then(
      (balanceLine) => {
        this.balanceLine = { ...this.balanceLine, ...balanceLine };
        this.notification.snack('Enregistré', 'info');
        this.dialog.close(this.balanceLine);
      }
    ).catch(error => {
      this.saving = false;
      this.notification.snack(error, 'danger')
    })
  }
}
