import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  search(keywords: string) {
    return this.http.get(`https://api-adresse.data.gouv.fr/search/?q=` + keywords).toPromise().then(
      (result: Object) => {
        return result['features'].map(feature => {
          return { value: feature['properties']?.id, displayValue: feature['properties']?.label };
        });
      }).catch(error => console.warn(error));
  }
}
