import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormAction } from '../../models/form-action/form-action';
import { Record } from '../../models/record/record';
import { TableColumn } from '../../models/table-column/table-column';
import { APP_DATE_FORMATS } from '../../util/datepicker-format';
import { TableCondition } from '../table/table.component';

@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class ListCardComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() columns: TableColumn[];
  @Input() records: Record[];
  @Input() conditions: TableCondition[];
  @Input() actions: FormAction[];
  @Input() page: string;
  @Input() loading: boolean;
  @Input() sort: { field: string, direction: 'asc' | 'desc' };
  @Input() redirectTo: string;

  _records: Record[];
  keywords: string;
  filter = {
    expanded: false,
    fields: [
      {
        name: 'created_on', label: 'Date de création', type: 'date', operators: [
          { value: '<', label: 'avant le' },
          { value: '>', label: 'après le' },
          { value: '=', label: 'le' },
        ]
      },
      {
        name: 'updated_on', label: 'Date de mise à jour', type: 'date', operators: [
          { value: '<', label: 'avant le' },
          { value: '>', label: 'après le' },
          { value: '=', label: 'le' },
        ]
      }
    ],
    conditions: [
      { field: null, operator: null, value: null }
    ]
  }

  _conditions: TableCondition[];

  constructor(private router: Router, private location: ActivatedRoute) { }

  ngOnInit(): void {
    this.redirectTo = this.router.url;

    this.location.queryParams.subscribe(params => {
      if (params.sysparm_title) {
        this.title = params.sysparm_title;
      }
    })
  }

  ngOnChanges(change: SimpleChanges): void {
    if (!this.sort) {
      this.sort = { field: 'udpated_on', direction: 'desc' }
    }

    if(change['records']){
      this.clearFilter();
    }
  }

  sortBy(field: TableColumn): void {
    if (this.sort?.field === field.name) {
      this.sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc';
      return;
    }

    this.sort = { field: field.name, direction: 'asc' };
  }

  applyFilters(): void {
    this._conditions = this.filter.conditions;

    if(!this._conditions){
      return;
    }

    this._records = this.records.filter(record => {
      return this._conditions.reduce((match: boolean, condition) => {
        if(!condition.field){
          return true;
        }
        
        if (record[condition.field.name] === undefined) {
          return match;
        }

        if (condition.field.type === 'date') {
          const date = new Date(condition.value);

          if (condition.operator.value === '<') {
            match = new Date(record[condition.field.name]) < date;
          }

          if (condition.operator.value === '>') {
            match = new Date(record[condition.field.name]) > date;
          }

          if (condition.operator.value === '=') {
            match = new Date(record[condition.field.name]) === date;
          }
        }

        return match
      }, true)
    })
  }

  clearFilter(){
    this.filter.conditions = [{ field: null, operator: null, value: null }];
    this.applyFilters();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.keywords = filterValue.trim().toLowerCase();
  }

  ngOnDestroy(): void {
    this._conditions = null;
    this.clearFilter();
  }
}
