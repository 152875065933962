import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { FormOptions } from 'src/app/@core/components/form-card/form-card.component';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { FormSection } from 'src/app/@core/models/form-section/form-section';
import { RelatedList } from 'src/app/@core/models/related-list/related-list';
import { WorkspaceInvitationRequestComponent } from 'src/app/@auth/components/workspace-invitation-request/workspace-invitation-request.component';
import { Workspace } from 'src/app/@auth/models/workspace/workspace';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit {
  options: FormOptions = {
    title: {
      default: 'Nouvel espace de travail',
      prefix: 'Espace de travail',
      fields: 'short_description'
    },
    page: 'administration/workspace',
    redirectTo: 'administration/workspaces'
  }
  workspace: Workspace;
  loading: boolean;
  sections: FormSection[] = [
    {
      columns: [
        {
          elements: [
            { field: 'short_description', label: 'Description courte', type: 'string' },
            { field: 'realty_limit', label: 'Nombre de bien immobilier max', type: 'string', isReadOnly: true }
          ]
        },
        {
          elements: [
            {
              field: 'plan', label: 'Abonnement', type: 'choice',
              choices: new ChoiceList().getChoices('workspace', 'plan'), isReadOnly: true
            },
            { field: 'storage_files', label: 'Nombre de fichier', type: 'string', isReadOnly: true },
            { field: 'storage_available', label: 'Espace disponible (%)', type: 'percentage', isReadOnly: true },
          ]
        }
      ]
    }
  ];

  relatedLists: RelatedList[] = [];

  constructor(private router: ActivatedRoute,
    private db: WorkspaceService,
    private dialog: NbDialogService) { }

  ngOnInit(): void {
    this.router.params.subscribe(parameter => {
      const id = parameter['id'];

      this.loading = true;

      Promise.all([this.db.getWorkspace(id), this.db.getMembers(id)]).then(
        (result) => {
          this.workspace = result[0];
          this.relatedLists.push({
            title: `Membres`,
            name: 'members',
            records: result[1],
            page: 'administration/workspace/membre',
            sortBy: { field: 'name', direction: 'asc' },
            columns: [
              { name: 'name', label: 'Utilisateur', type: 'string' },
              { name: 'email', label: 'Email', type: 'string' },
              { name: 'role', label: 'Rôle', type: 'choice', choices: new ChoiceList().getChoices('workspace-member', 'role') }
            ],
            actions: [
              {
                name: 'invite', label: 'Inviter des utilisateurs', type: 'script', primary: true, onClick: () => {
                  this.dialog.open(WorkspaceInvitationRequestComponent, {
                    context: {
                      workspaceID: id
                    }
                  });
                }
              }
            ]
          });
        }
      ).catch(
        error => console.warn(error)
      ).finally(() => this.loading = false);
    });
  }
}
