import { Component, Input, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NbDialogRef } from '@nebular/theme';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { APP_DATE_FORMATS } from 'src/app/@core/util/datepicker-format';
import { Building } from 'src/app/models/building';
import { Company } from 'src/app/models/company';
import { Document } from 'src/app/models/document/document';
import { Property } from 'src/app/models/property/property';
import { Tenant } from 'src/app/models/tenant/tenant';
import { Transaction } from 'src/app/models/transaction/transaction';
import { CompanyService } from 'src/app/services/company/company.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';

@Component({
  selector: 'app-edit-imported-transaction',
  templateUrl: './edit-imported-transaction.component.html',
  styleUrls: ['./edit-imported-transaction.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class EditImportedTransactionComponent implements OnInit {
  @Input() source;

  transaction: any;
  buildings: Building[];
  realties: Property[];
  tenants: Tenant[];
  companies: Company[];
  documents: Document[];
  categories: any[];

  constructor(private dialog: NbDialogRef<EditImportedTransactionComponent>,
    private realty: PropertyService,
    private company: CompanyService,
    private tenant: TenantService,
    private workspaceService: WorkspaceService,
    private notification: UINotificationService,
    private db: DatabaseService) {
  }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.categories = new ChoiceList().getChoices('transaction', 'category').map(
        category => {
          return { label: category.label, value: category.value }
        }
      );

      Promise.all([
        this.realty.getBuildings(workspace.id),
        this.realty.getProperties(workspace.id),
        this.tenant.getTenants(workspace.id),
        this.company.getCompanies(workspace.short_description)
      ]).then(
        result => {
          this.buildings = result[0];
          this.realties = result[1];
          this.tenants = result[2];
          this.companies = result[3];
        }
      ).then(() => {
        this.transaction = new Transaction();
        this.transaction.reference = this.source.reference,
          this.transaction.short_description = this.source.short_description,
          this.transaction.amount = this.source.amount,
          this.transaction.operation_date = this.source.operation_date,
          this.transaction.category = this.source.category?.value,
          this.transaction.realty = this.source.realty?.id,
          this.transaction.tenant = this.source.tenant?.id,
          this.transaction.company = this.source.company?.id
      })
    })
  }

  getLabel(option: any) {
    return option?.name;
  }

  getUsername(user: any) {
    return user ? `${user.first_name} ${user.last_name}` : null;
  }

  async close() {
    if (typeof this.transaction.company === 'string') {
      let company = await this.company.getCompany(this.transaction.company).catch((error) =>
        console.warn(error)
      );

      if (!company) {
        company = new Company();
        company.name = this.transaction.company;

        await this.db.save(company).then(
          company => this.transaction.company = company
        );
      }
    }

    if (typeof this.transaction.tenant === 'string') {
      const name = this.transaction.tenant.trim().split(' ');

      if (name.length > 1) {
        const tenant = new Tenant();
        tenant.first_name = name[0];
        tenant.last_name = name[1];

        await this.db.save(tenant).then(
          tenant => this.transaction.tenant = tenant
        ).catch(error => this.notification.snack(error, 'danger'));
      }
    }

    this.dialog.close(this.transaction);
  }
}
