import { Component, OnInit } from '@angular/core';
import { parseMessage } from '@angular/localize/src/utils';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { FormAction } from 'src/app/@core/models/form-action/form-action';
import { TableColumn } from 'src/app/@core/models/table-column/table-column';
import { Document } from 'src/app/models/document/document';
import { DocumentService } from 'src/app/services/document/document.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})

export class DocumentsComponent implements OnInit {
  loading: boolean;
  keywords: string;
  columns: TableColumn[];
  records: Document[] = [];
  actions: FormAction[];

  constructor(private db: DocumentService, private location: ActivatedRoute, private workspaceService: WorkspaceService) { }

  ngOnInit(): void {
    this.loading = true;
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.columns = [
        { name: 'name', label: 'Nom du document', type: 'string' },
        { name: 'category', label: 'Catégorie', type: 'choice', choices: new ChoiceList().getChoices('document', 'category') },
        { name: 'realty', label: 'Bien immobilier', type: 'reference', reference: 'realty', url: `workspace/${workspace.id}/realty/`, displayValue: 'short_description' },
        { name: 'created_on', label: 'Crée le', type: 'date' },
      ]
      
      this.location.queryParams.subscribe(params => {
        const queryParams = params.sysparm_query;
        const parameters = [];

        if (queryParams) {
          queryParams.split('^').forEach(condition => {
            const field = condition.split('=')[0];
            const value = condition.split('=')[1];

            if (value) {
              parameters.push({ field: field, value: value });
            }
          });
        }

        this.db.getDocuments(workspace.id).then((records: Document[]) => {
          this.records = records;
        }).then(() => {
          this.records = this.records.filter(record => parameters.reduce((output, condition) => {
            if (record[condition.field] !== undefined) {
              output = record[condition.field] === condition.value;
              return output;
            }

            output = true;
            return output;
          }, true));
        }).then(() =>
          this.actions = [
            {
              name: 'new', label: 'Nouveau document', link: '/document/-1', type: 'link', primary: true, queryParams: {
                sysparm_default: queryParams
              }
            }
          ]
        ).finally(() => this.loading = false);
    })
  })
}
}
