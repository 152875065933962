import { Record } from "../@core/models/record/record";

export class TenantBalanceLine extends Record{
    id?: string;
    short_description: string;
    description: string;
    amount_due: number;
    amount_paid: number;
    start_date: string | Date;
    end_date: string | Date;
    month: number;
    year: number;
    balance: number;
    due_date: Date;
    tenant: string;
    category: 'rent' | 'deposit';
    comments: string;
}