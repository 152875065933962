import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})

export class ContactCardComponent implements OnInit {
  @Input() id: string;
  @Input() name: string;
  @Input() short_description: string;
  @Input() category: string;
  @Input() email: string;
  @Input() phone: string;
  @Input() address: string;
  @Input() company: string;
  @Input() website: string;

  private link: string;

  constructor(private dialog: NbDialogRef<ContactCardComponent>,
    private router: Router,
    private clipboard: Clipboard,
    private notification: UINotificationService) { }

  ngOnInit(): void {
    if (this.category === 't') {
      this.link = `/locataire/${this.id}`;
    }

    if (this.category === 'c') {
      this.link = `/entreprise/${this.id}`;
    }
  }

  onCopy(value){
    this.notification.snack('Copié');
    this.clipboard.copy(value);
  }

  onEdit() {
    this.router.navigate([this.link]);
    this.dialog.close();
  }

  onGoTo(website: string){
    window.open(website, '_blank');
  }
}
