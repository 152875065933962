import { ChoiceOption } from 'src/app/@core/models/choice-list/choice-list';
import { Record } from 'src/app/@core/models/record/record';
import { Company } from '../company';
import { Payment } from '../payment';
import { Property } from '../property/property';
import { RealtyGroup } from '../realty-group';
import { Rent } from '../rent';
import { Tenant } from '../tenant/tenant';

export class Transaction extends Record {
    reference?: string;
    short_description: string;
    amount: number;
    operation_date: Date | string;
    category?: string | ChoiceOption;
    realty_group?: string | RealtyGroup;
    realty?: string | Property;
    tenant?: string | Tenant;
    rent?: Rent | string;
    deposit?: string;
    company?: string | Company;
    document?: string | Document;
    balanced?: boolean;
    memo?: string;
    ignored?: boolean;
    account?: string;
    parent?: Transaction;
    transaction_id: string;

    constructor(id?: string) {
        super('transaction', id);
    }
}
