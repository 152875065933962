import { Injectable } from '@angular/core';
import { NbMenuItem, NbSidebarService } from '@nebular/theme';
import { Property } from 'src/app/models/property/property';
import { PropertyService } from '../../../services/property/property.service';

@Injectable({
  providedIn: 'root'
})

export class MenuService {
  compact: boolean;
  items: NbMenuItem[] = [
    {
      title: 'Tableau de bord',
      icon: 'activity-outline',
      link: '/',
      pathMatch: 'prefix'
    },
    {
      title: 'Patrimoine',
      icon: 'home-outline',
      link: 'biens-immobiliers',
      pathMatch: 'prefix'
    },
    {
      title: 'Locataires',
      icon: 'people-outline',
      link: 'locataires',
      pathMatch: 'prefix',
      children: [
        {
          title: 'En cours',
          link: 'locataires/en-cours',
          pathMatch: 'prefix',
        },
        {
          title: 'Anciens locataires',
          link: 'locataires/anciens',
          pathMatch: 'prefix'
        }
      ]
    },
    {
      title: 'Compte en banque',
      icon: 'umbrella-outline',
      link: 'banque',
      pathMatch: 'full',
    },
    {
      title: 'Documents',
      icon: 'archive-outline',
      pathMatch: 'prefix',
      children: [
        {
          title: 'Tous les documents',
          link: 'documents',
          icon: 'list-outline',
          pathMatch: 'full'
        },
        {
          title: 'Editer un PDF',
          link: 'document-pdf',
          pathMatch: 'prefix',
          icon: 'edit-outline'
        },
        {
          title: 'Modèles de document',
          link: 'administration/modeles',
          pathMatch: 'prefix',
          icon: 'file-text-outline'
        },
        {
          title: 'Import en masse',
          link: 'import-de-documents',
          pathMatch: 'prefix',
          icon: 'cloud-upload-outline'
        }
      ]
    },
    {
      title: 'Contacts',
      icon: 'phone-outline',
      link: 'contacts',
      pathMatch: 'prefix'
    },
    {
      title: 'Administration',
      icon: 'settings-2-outline',
      children: [
        {
          title: 'Espace de travail',
          link: '/administration/workspaces',
          pathMatch: 'prefix'
        }
      ]
    }
  ]

  constructor(private property: PropertyService, private sidebarService: NbSidebarService) {
  }

  toggle() {
    if (!localStorage.getItem('liimmo.sidebar.compact')) {
      this.sidebarService.toggle(true, 'left-menu');
    } else {
      this.sidebarService.toggle(false, 'left-menu');
    }
  }

  updateMenu(menu: any[], title: string, link: string): any[] {
    if (!menu.find(item => item.link === link)) {
      menu.push({
        title: title,
        link: link
      });
    }

    return menu;
  }

  getItems(workspaceID: string): Promise<NbMenuItem[] | []> {
    return Promise.all([this.property.getProperties(workspaceID), this.property.getBuildings(workspaceID)]).then(
      result => {
        const properties = result[0];
        const buildings = result[1];
        let submenu: NbMenuItem[] = [
          { title: 'Ajouter un bien', link: 'bien-immobilier/-1', icon: 'plus-outline'}
        ];

        if (buildings.length > 0) {
          this.updateMenu(submenu, 'Immeubles', 'immeubles');
        }

        properties.forEach((property: Property) => {
          const type = property.property_type;

          switch (type) {
            case 'h':
              this.updateMenu(submenu, 'Maisons', 'maisons');
              break;
            case 'a':
              this.updateMenu(submenu, 'Appartements', 'appartements');
              break;
            case 'p':
              this.updateMenu(submenu, 'Parkings', 'parkings');
              break;
            case 'ce':
              this.updateMenu(submenu, 'Cave', 'caves');
              break;
            case 'c':
              this.updateMenu(submenu, 'Locaux commerciaux', 'locaux-commerciaux');
              break;
            default:
              break;
          }
        });

        const item = this.items.find(item => item.link === 'biens-immobiliers');

        if (submenu.length > 0) {
          item.children = submenu;
        }

        return this.items;
      }).catch(error => {
        console.warn('Menu error', error);
        return [];
      }).finally(() => this.items);
  }
}
