import { Injectable } from '@angular/core';
import { Property } from 'src/app/models/property/property';
import { DatabaseService } from 'src/app/@core/services/database/database.service';
import { FinancialPerformance } from 'src/app/@auth/services/workspace/workspace.service';
import { RealtyGroup } from 'src/app/models/realty-group';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class PropertyService {

  constructor(private db: DatabaseService, private http: HttpClient) {
  }

  getProperties(workspaceID: string): Promise<Property[]> {
    return this.db.getCollection(`workspace/${workspaceID}/realty/`, 'realty').then(
      records => records.map((record: any) => {
        return { ...record } as Property;
      })
    ).catch(error => {
      console.warn(error);
      throw error['details'] || 'Erreur lors du chargement des biens';
    })
  }

  getBuildings(workspaceID: string): Promise<RealtyGroup[]> {
    return this.db.getCollection(`workspace/${workspaceID}/realtygroup/`, 'realtygroup').then(
      records => records?.map((record: any) => {
        return { ...record } as RealtyGroup;
      })
    ).catch(error => {
      console.warn(error);
      throw error['details'] || 'Erreur lors du chargement des immeubles';
    })
  }

  getBasements(workspaceID: string): Promise<Property[]> {
    return this.getProperties(workspaceID).then(records => records.filter(record => record.property_type === 'ce'));
  }

  getParkings(workspaceID: string): Promise<Property[]> {
    return this.getProperties(workspaceID).then(records => records.filter(record => record.property_type === 'p'));
  }

  getStoreHouses(workspaceID: string): Promise<Property[]> {
    return this.getProperties(workspaceID).then(records => records.filter(record => record.property_type === 'c'));
  }

  getApartments(workspaceID: string, building?: string): Promise<Property[]> {
    return this.getProperties(workspaceID).then(properties => properties.filter(property => {
      if (building) {
        return property.building === building;
      }

      return property.property_type === 'a';
    }))
  }

  getHouses(workspaceID: string): Promise<Property[]> {
    return this.getProperties(workspaceID).then(properties => properties.filter(property => {
      return property.property_type === 'h';
    }))
  }

  getProperty(id: string): Promise<Property> {
    return this.db.get<Property>('realty', id).then(data => {
      if (!data) {
        return null;
      }

      return { id: id, ...data } as Property;
    }).catch(error => {
      console.warn(error);
      throw error
    });
  }

  getFinancialPerformance(realtyID: string): Promise<FinancialPerformance> {
    return Promise.all([this.getGrossResult(realtyID), this.getNetResult(realtyID), this.getCashFlow(realtyID)]).then(result => {
      return {
        gross: {
          expected: result[0].gross_efficiency,
          actual: result[0].actual_total_gross_efficiency
        },
        net: {
          expected: result[1].net_efficiency,
          actual: result[1].actual_total_net_efficiency
        },
        cash_flow: result[2].cash_flow
      };
    }).catch(error => {
      throw error;
    })
  }

  getGrossResult(realtyID: string): Promise<any> {
    return this.http.get<number>(`${environment.backend.url}realty/${realtyID}/gross_efficiency/`).pipe(
      catchError((error) => {
        console.warn(error);
        throw 'Erreur lors du calcul du rendement brut de l\'espace de travail';
      })
    ).toPromise();
  }

  getNetResult(realtyID: string): Promise<any> {
    return this.http.get<number>(`${environment.backend.url}realty/${realtyID}/net_efficiency/`).pipe(
      catchError((error) => {
        console.warn(error);
        throw 'Erreur lors du calcul du rendement net de l\'espace de travail';
      })
    ).toPromise();
  }

  getCashFlow(realtyID: string): Promise<any> {
    return this.http.get<number>(`${environment.backend.url}realty/${realtyID}/cash_flow/`).pipe(
      catchError((error) => {
        console.warn(error);
        throw 'Erreur lors du calcul du cach-flow de l\'espace de travail';
      })
    ).toPromise();
  }
}
