import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-template-name',
  templateUrl: './template-name.component.html',
  styleUrls: ['./template-name.component.scss']
})
export class TemplateNameComponent implements OnInit {
  @Input() name: string;

  constructor(protected ref: NbDialogRef<TemplateNameComponent>) { }

  ngOnInit(): void {
  }

  onCancel(){
    this.ref.close();
  }

  onSubmit(){
    this.ref.close(this.name);
  }
}
