import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { bounceInDownOnEnterAnimation, fadeInLeftBigOnEnterAnimation, fadeInOnEnterAnimation, fadeOutLeftOnLeaveAnimation, fadeOutOnLeaveAnimation, fadeOutRightBigOnLeaveAnimation, slideInLeftAnimation, slideInLeftOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-welcome-message',
  templateUrl: './welcome-message.component.html',
  styleUrls: ['./welcome-message.component.scss'],
  animations: [
    bounceInDownOnEnterAnimation(),
    fadeOutOnLeaveAnimation(),
    fadeOutLeftOnLeaveAnimation(),
    fadeInLeftBigOnEnterAnimation(),
    fadeOutRightBigOnLeaveAnimation()
  ]
})


export class WelcomeMessageComponent implements OnInit {
  counter = 0;
  step = 0;

  constructor(private dialog: NbDialogRef<WelcomeMessageComponent>) { }

  ngOnInit(): void {
  }

  ignore(){
    const answers = [
      'Tu es sûr.e de toi????',
      'Dommages pour toi... mais je ne peux tolérer cette attitude.',
      'Franchemnt tu as quoi de mieux à faire?',
      'C\'est le confinement, tu as du temps de libre',
      'Toujours pas',
      'Tu as la tremblotte ou quoi?'
    ]

    const answer = Math.floor(Math.random() * Math.floor(answers.length));

    this.counter = 0;
  }

  next(){
    this.step ++;
  }

  close(){
    this.dialog.close();
    localStorage.setItem('showWelcomeMessage', 'false');
  }
}
