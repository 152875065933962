import { Component, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NbDialogService } from '@nebular/theme';
import { environment } from 'src/environments/environment';
import { SnackbarContentComponent } from '../../components/snackbar-content/snackbar-content.component';

@Injectable({
  providedIn: 'root'
})

export class UINotificationService {

  constructor(private snackbar: MatSnackBar) { }

  alert(message: string, type?: 'info' | 'warning' | 'danger') {
    type = type || 'info';
    this.snackbar.open(message, 'Fermer');
  }

  snack(message: string, type?: 'info' | 'warning' | 'danger') {
    type = type || 'info';

    this.snackbar.openFromComponent(SnackbarContentComponent, {
      data: {
        message: message,
        type: type
      },
      duration: environment.snackbar.duration,
    });
  }
}