import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { User } from 'src/app/@auth/models/user/user';
import { UserService } from 'src/app/@auth/services/user/user.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { DialogInputComponent } from 'src/app/@core/components/dialog-input/dialog-input.component';
import { ChoiceList } from 'src/app/@core/models/choice-list/choice-list';
import { PdfGeneratorService } from 'src/app/@core/services/pdf-generator/pdf-generator.service';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { SendContractComponent } from 'src/app/components/send-contract/send-contract.component';
import { DocumentTemplate } from 'src/app/models/document-template';
import { Document } from 'src/app/models/document/document';
import { Property } from 'src/app/models/property/property';
import { Room } from 'src/app/models/room/room';
import { TenantBalanceLine } from 'src/app/models/tenant-balance-line';
import { Tenant } from 'src/app/models/tenant/tenant';
import { DocumentGeneratorService } from 'src/app/services/document-generator.service';
import { DocumentService } from 'src/app/services/document/document.service';
import { PropertyService } from 'src/app/services/property/property.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tenant-contract',
  templateUrl: './tenant-contract.component.html',
  styleUrls: ['./tenant-contract.component.scss'],
})

export class TenantContractComponent implements OnInit {
  filter = { tenant: null, template: null, rent: null };
  tenants: Tenant[] = [];
  tenant: Tenant;
  realties: Property[] = [];
  realty: Property;
  rooms: Room[] = [];
  rent: TenantBalanceLine;
  rents: TenantBalanceLine[];
  landlord: User;
  users: User[] = [];
  loaded: boolean = false;
  key = environment.tiny.apiKey;
  config = environment.tiny.config;
  templates = [];
  template: DocumentTemplate;
  document: Document;
  workspaceID: string;
  canEdit: boolean;

  constructor(private workspaceService: WorkspaceService,
    private tenantService: TenantService,
    private realtyService: PropertyService,
    private documentService: DocumentService,
    private pdfGenerator: PdfGeneratorService,
    private documentGenerator: DocumentGeneratorService,
    private notification: UINotificationService,
    private modal: NbDialogService,
    private location: ActivatedRoute,
    private dialog: NbDialogService,
    private router: Router) { }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.workspaceID = workspace.id;

      Promise.all([
        this.tenantService.getActiveTenants(workspace?.id),
        this.realtyService.getProperties(workspace?.id),
        this.documentService.getTemplates(workspace.id),
      ]).then(result => {
        this.tenants = result[0]
        this.realties = result[1];
        this.templates = result[2];
        this.document = new Document();
        this.loaded = true
        
        this.location.params.subscribe(params => {
          const id = params.id;
          this.canEdit = false;
          this.documentService.getDocument(id).then(
            document => this.document = document
          ).then(() => this.canEdit = true
          ).catch(error => {
            console.warn(error);
            throw error;
          }
          ).finally(() => this.canEdit = true)
        })
      }).catch(error => {
        console.warn(error);
        this.notification.snack(error, 'danger');
      }).finally(
        () => this.canEdit = true
      )
    })
  }

  onTenantChange() {
    if (!this.document?.tenant) {
      return;
    }

    this.canEdit = false;

    Promise.all([
      this.tenantService.getTenant(this.document.tenant),
      this.tenantService.getBalance(this.document.tenant)
    ]).then(
      result => {
        this.tenant = result[0]
        this.rents = result[1].lines.filter(line => line.category == 'rent');
        this.filter.rent = '';
      }
    ).then(() => this.updateDocument()
    ).catch(error => {
      const message = error['detail'] || 'Une erreur est survenue (locataire incorrect)';
      this.notification.snack(message, 'danger')
      this.canEdit = true;
    });
  }

  onTemplateChange() {
    this.template = this.templates.find(template => template.id == this.document.template);

    if (!this.template) {
      return;
    }

    this.updateDocument();
  }

  onRentChange() {
    this.updateDocument();
  }

  async updateDocument() {
    if (!this.document.template) {
      this.canEdit = true;
      return;
    }

    this.canEdit = false;

    const template = await this.documentService.getTemplate(this.document.template);

    if (!template) {
      this.canEdit = true;
      return;
    }

    if (template.category == 'r' && this.filter.rent) {
      return this.documentGenerator.createTenantReceipt(this.filter.rent, this.document.template).then(
        document => {
          if (document) {
            this._updateDocumentName();
            this.document.content = document;
            this.notification.snack('Document mis à jour');
          }
        }
      ).catch(exception => this.notification.snack(exception, 'danger')
      ).finally(() => this.canEdit = true);
    }

    if (this.tenant) {
      return this.documentGenerator.createContract(this.tenant, this.document.template).then(
        content => {
          if (content) {
            this._updateDocumentName();
            this.document.content = content;
            this.notification.snack('Document mis à jour');
          }
        }
      ).catch(exception => this.notification.snack(exception, 'danger')
      ).finally(() => this.canEdit = true);
    }

    this._updateDocumentName();
    this.document.content = template.content;
    this.canEdit = true;
  }

  async onSave() {
    if(!this.document.content){
      this.notification.alert('Le contenu du document est vide. Action annulée.');
      return;
    }

    if (!this.document.id) {
      if(!this.document.name){
        this.document.name = await this.dialog.open(DialogInputComponent, {
          context: {
            title: 'Création d\'un document',
            question: 'Entrez le nom du document',
            placeholder: 'Nom du document'
          },
          autoFocus: false
        }).onClose.toPromise();

        if(!this.document.name){
          return;
        }
      }

      this.document.category = 'o';

      if (this.template) {
        this.document.category = this.template.category;
      }
    }

    this.documentService.saveAsDocument(
      this.workspaceID,
      this.document.id,
      this.document.name,
      this.document.tenant,
      this.document.realty,
      this.document.category,
      this.document.template,
      this.document.content
    ).then((document) => {
      this.notification.snack(`Contenu sauvegardé dans le document "${this.document.name}"`, 'info')
      if(!this.document.id){
        this.router.navigate(['document-pdf', document.id]);
      }

      this.document = document
    }
    ).catch(exception => this.notification.alert(exception, 'danger'))
  }

  onDownload() {
    this.pdfGenerator.generate(this.document.name, this.document.content)
  }

  send() {
    this.modal.open(SendContractComponent, {
      autoFocus: false
    })
  }

  private _updateDocumentName(){
    if(this.document.id){
      return;
    }
    
    let name = 'Nouveau document ' + new Date().toLocaleString();

    if(this.template){
      const category = new ChoiceList().getChoice('template', 'category', this.template.category);

      if(category){
        name = category.label;
      }
    }

    if(this.tenant){
      name += ' - ' + this.tenant.first_name + ' ' + this.tenant.last_name.toUpperCase();
    }

    if(this.filter.rent){
      const rent = this.rents.find(rent => rent.id == this.filter.rent);

      if(rent){
        name += ' ' + rent.short_description;
      }
    }

    this.document.name = name;
  }
}
