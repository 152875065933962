import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { AuthenticationService } from 'src/app/@auth/services/authentication/authentication.service';
import { WorkspaceService } from 'src/app/@auth/services/workspace/workspace.service';
import { EmailMessageCardComponent } from 'src/app/@core/components/email-message-card/email-message-card.component';
import { UINotificationService } from 'src/app/@core/services/ui-notification/ui-notification.service';
import { DateUtil } from 'src/app/@core/util/date-util';
import { BankAccountTransactionsComponent } from 'src/app/components/bank-account-transactions/bank-account-transactions.component';
import { DepositReceivedComponent } from 'src/app/components/deposit-received/deposit-received.component';
import { EditRentComponent } from 'src/app/components/edit-rent/edit-rent.component';
import { ReviewTenantBalanceComponent } from 'src/app/components/review-tenant-balance/review-tenant-balance.component';
import { SelectTransactionsComponent } from 'src/app/components/select-transactions/select-transactions.component';
import { TenantBalance } from 'src/app/models/tenant-balance';
import { TenantBalanceLine } from 'src/app/models/tenant-balance-line';
import { Tenant } from 'src/app/models/tenant/tenant';
import { Transaction } from 'src/app/models/transaction/transaction';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { TransactionService } from 'src/app/services/transaction/transaction.service';

@Component({
  selector: 'app-tenant-balance',
  templateUrl: './tenant-balance.component.html',
  styleUrls: ['./tenant-balance.component.scss']
})

export class TenantBalanceComponent implements OnInit {
  loading: boolean;
  tenantID: string;
  tenant: Tenant;
  tenants: Tenant[];
  transactions: any[] = [];
  workspaceID: string;
  balance: TenantBalance;
  webhookEnabed: boolean;

  constructor(private tenantService: TenantService,
    private router: ActivatedRoute,
    private location: Router,
    private auth: AuthenticationService,
    private notification: UINotificationService,
    private workspaceService: WorkspaceService,
    private transactionService: TransactionService,
    private dialog: NbDialogService
  ) {

  }

  ngOnInit(): void {
    this.workspaceService.current.subscribe(workspace => {
      if (!workspace) {
        return;
      }

      this.webhookEnabed = workspace.webhook_enabled;
      this.workspaceID = workspace.id;

      this.router.queryParams.subscribe(parameters => {
        this.tenantID = parameters['locataire'];
        this.loading = true;

        Promise.all([
          this.tenantService.getTenants(workspace.id),
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(
          result => {
            this.tenants = result[0];
            this.tenant = result[1];
            this.balance = result[2];
          }
        ).catch(error => this.notification.snack(error, 'danger')
        ).finally(
          () => this.loading = false
        );
      });
    })
  }

  checkAll() {
    if (!confirm('Voulez-vous valider tous les loyers restants?')) {
      return;
    }

    const transactions = this.balance.lines.filter(line => line.category == 'rent')
      ?.reduce((transactions: Transaction[], rent: TenantBalanceLine) => {
        if (rent.amount_due <= rent.amount_paid) {
          return transactions;
        }

        let transaction = new Transaction();
        transaction.short_description = rent.short_description;
        transaction.category = 'r';
        transaction.amount = rent.amount_due - rent.amount_paid;
        transaction.operation_date = rent.due_date;
        transaction.realty = this.tenant.realty as string;
        transaction.tenant = (this.tenant instanceof Object) ? this.tenant.id : this.tenant;
        transaction.rent = rent.id;

        transactions.push(transaction);
        return transactions;
      }, []);

    this.transactionService.createTransactions(transactions).then(
      () => {
        this.notification.snack('Validation terminée.');
        this.tenantService.getTenant(this.tenant.id).then(tenant => this.tenant = tenant);
      }
    ).catch(error => this.notification.snack(error, 'danger'))
  }

  onSelect(line: TenantBalanceLine) {
    this.dialog.open(SelectTransactionsComponent, {
      autoFocus: false,
      context: {
        lineID: line.id,
        amount: line.amount_due,
        category: line.category,
        tenantID: this.tenantID
      }
    }).onClose.subscribe(
      output => {
        if (!output) {
          return;
        }

        Promise.all([
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(result => {
          this.tenant = result[0];
          this.balance = result[1];
        })
      }
    )
  }

  onEdit(balanceLineID: string) {
    this.dialog.open(EditRentComponent, {
      autoFocus: false,
      context: {
        workspace: this.workspaceID,
        tenantID: this.tenant?.id,
        id: balanceLineID
      }
    }).onClose.subscribe(
      output => {
        if (!output) {
          return;
        }

        Promise.all([
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(result => {
          this.tenant = result[0];
          this.balance = result[1];
        })
      }
    )
  }

  onSelectTenant(tenant: Tenant) {
    this.location.navigate(['/solde-locataire'], { queryParams: { locataire: tenant.id } });
  }

  onApproval(balanceLine: string) {
    this.dialog.open(ReviewTenantBalanceComponent, {
      context: {
        id: balanceLine
      },
      autoFocus: false,
      closeOnBackdropClick: false
    }).onClose.subscribe(
      () => {
        Promise.all([
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(result => {
          this.tenant = result[0];
          this.balance = result[1];
        })
      }
    );
  }

  onDepositReceived(id: string) {
    this.dialog.open(DepositReceivedComponent, {
      context: {
        tenantID: this.tenant.id,
        id: id
      },
      autoFocus: false,
      closeOnBackdropClick: false
    }).onClose.subscribe(
      () => {
        Promise.all([
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(result => {
          this.tenant = result[0];
          this.balance = result[1];
        })
      }
    );
  }

  onShowBankAccount(line: TenantBalanceLine) {
    this.dialog.open(BankAccountTransactionsComponent, {
      autoFocus: false,
      context: {
        lineID: line.id,
        amount: line.amount_due,
        category: line.category,
        tenantID: this.tenantID,
        selection: true
      }
    }).onClose.subscribe(
      () => {
        Promise.all([
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(result => {
          this.tenant = result[0];
          this.balance = result[1];
        })
      }
    );
  }

  onShowTransactions(line: TenantBalanceLine) {
    this.dialog.open(SelectTransactionsComponent, {
      autoFocus: false,
      context: {
        lineID: line.id,
        amount: line.amount_due,
        category: line.category,
        tenantID: this.tenantID,
        selection: true
      }
    }).onClose.subscribe(
      () => {
        Promise.all([
          this.tenantService.getTenant(this.tenantID),
          this.tenantService.getBalance(this.tenantID),
        ]).then(result => {
          this.tenant = result[0];
          this.balance = result[1];
        })
      }
    );
  }

  onChaseUp(line: TenantBalanceLine) {
    const period = new DateUtil().toFormat(line.due_date, 'MMMM YYYY');
    const user = this.auth.user.getValue();
    const message = 
`Bonjour ${this.tenant.first_name},

Sauf erreur de ma part, je constate que le paiement du loyer pour ${period} d'un montant de ${line.amount_due}€ n’a toujours pas été effectué.

Je vous rappelle que le loyer doit être payé le 1er du mois. Merci de bien vouloir procéder au paiement de votre solde sous huit jours. À défaut, j’entreprendrai d’autres démarches.

Bonne journée.
${user?.first_name}`;

    this.dialog.open(EmailMessageCardComponent, {
      context: {
        recipients: this.tenant.email,
        title: `Loyer ${period} - Relance suite impayé`,
        body: message
      },
      autoFocus: false
    })
  }
}
