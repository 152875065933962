import { Record } from 'src/app/@core/models/record/record';
import { User } from '../user/user';
import { Workspace } from '../workspace/workspace';

export class WorkspaceMember extends Record {
    id?: string;
    user?: string | User;
    name?: string;
    email: string;
    role: string;

    constructor(){
        super('workpace_member')
    }
}
